import { SettingsLayout } from "context/settings/layout/SettingsLayout";
import { Profile } from "context/settings/profile/Profile";
import React from "react";
import { CiLock } from "react-icons/ci";
import { Link } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

export const FeaturedAds = () => {
  return (
    <SettingsLayout>
      
        <div className="min-h-screen flex flex-col space-y-6 items-center justify-center bg-gray-100">
        <div className="w-full max-w-2xl">
               </div>
        
        <div className="w-full space-y-4 max-w-2xl p-6 bg-white rounded-lg shadow-md">
          <div>
          <h2 className="text-lg font-semibold ">Reach More Clients</h2>
          <p className="capitalize text-gray-400 text-base font-semibold">Once selected, Please click "Continue" </p>
          </div>
        
          <form>
          

            <div className="grid gap-4 mb-4">
            <div className="mb-4 col-span-full border p-4 rounded-lg">
              <label
                htmlFor="card-number"
                className="block text-sm font-medium text-gray-700"
              >
                <div>
                  <p>Basic Listing(free)</p>
                </div>
              </label>
              {/* <input
                type="text"
                id="card-number"
                placeholder="1234 1234 1234 1234"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
              /> */}
            </div>
             
              
            </div>

          
            <div className="flex gap-4 justify-end">
            <button
              type="submit"
              className=" bg-gray-400 capitalize font-medium py-2 px-4 bg-opacity-30 rounded-md 0"
            >
             Cancel
            </button>
            <button
              type="submit"
              className=" bg-blue-500 capitalize font-medium text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Proceed to pay
            </button>
            </div>
            
          </form>
        </div>
    </div>
    </SettingsLayout>
  );
};
