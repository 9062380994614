export const faqs = [
    {
        question: "What is Audtiklance",
        answer: "Audtiklance is an online marketplace connecting freelancers with businesses and individuals looking for services like graphic design, writing, programming, and more."
    },
    {
        question: "How do I place an order?",
        answer: "Browse services, choose a freelancer, and click Order Now to proceed. Follow the prompts to finalize your request."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept credit/debit cards, PayPal, and other popular payment gateways."
    },
    {
        question: "Can I communicate with the freelancer before placing an order?",
        answer: "Yes, you can use our built-in messaging system to discuss your requirements with the freelancer."
    },
    {
        question: "What happens if I am not satisfied with the delivered work?",
        answer: "If the work doesn't meet your expectations, you can request a revision or file a dispute. Our team will assist in resolving the issue."
    },
]