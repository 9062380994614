// import { useForm } from "react-hook-form";

import DesktopMessage from "./DesktopMessage";
import MobileMessage from "./MobileMessage";
import { useSocket } from "../../hooks/profile/socket";
import { useEffect } from "react";
import { useGetConvoList } from "hooks/services/useGetConvoList";

export const Chat = () => {
  const { allChatList } = useGetConvoList();

  return (
    <div>
      {allChatList?.results.length == 0 ? 
        <div className="container mt-[100px] lg:mt-[40px] p-4 space-y-5">
             <h1 className="font-semibold text-base lg:text-2xl">Messages</h1>
          <div className="m-auto w-1/2 shadow-xl border-[.1px] rounded-lg p-4 ">
            <p>No conversations yet!!!</p>
            <p></p>
          </div>
        </div>
      
      : 
      <div className="container mt-[100px] lg:mt-[40px] p-4 space-y-5">
      <div className="">
      <h1 className="font-semibold text-base lg:text-2xl">Messages</h1>
      {/* <h1 className="text-base font-normal text-accents-800 p-[16px_14px_12px_14px]">
        {"Messages"}
      </h1> */}
      </div>
       
       <div className="w-full shadow-xl border-[.1px] rounded-lg p-4 ">
      <div className="md:hidden ">
        <MobileMessage />
      </div>
      <div className="hidden md:block bg-accents-300 pb-10">
       
        <DesktopMessage />
      </div>
    </div>
    </div>
      }
    </div>
   
    
  );
};
