/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from "react";
import { ReactComponent as SocialIcon1 } from "assets/svg/socialIcon1.svg";
import { ReactComponent as SocialIcon2 } from "assets/svg/socialIcon2.svg";
import { ReactComponent as SocialIcon3 } from "assets/svg/socialIcon3.svg";
import { ReactComponent as SocialIcon4 } from "assets/svg/socialIcon4.svg";
import { isAuthenticated } from "utils/auth/auth";

interface FooterProps {
  prop1: `string`;
}

const data = [
  {
    title: "Contact Us",
    item1: {
      text: "support@audtiklance.com",
      url: "mailto:support@audtiklance.com"
    }
  },
  {
    title: "Quick links",
    item1: {
      text: "Home",
      url: "/"
    },
    item2: {
      text: "Find customers",
      url: isAuthenticated() ? "/profile" : "/provider"
    },
    item3: {
      text: "Hire a Pro",
      url: "/search"
    },
  },
  {
    title: "Home Services",
    item1: {
      text: "Home Cleaning",
      url: "/search?searchTerm=Home Cleaning"
    },
    item2: {
      text: "Plumbing",
      url: "/search?searchTerm=Plumbing"
    },
    item3: {
      text: "Electrical Installation",
      url: "/search?searchTerm=Electrical Installation"
    },
    item4: {
      text: "Painting",
      url: "/search?searchTerm=Painting"
    },
  },
  {
    title: "More Services",
    item1: {
      text: "Pest Control",
      url: "/search?searchTerm=Pest Control"
    },
    item2: {
      text: "HVAC Maintenance",
      url: "/search?searchTerm=HVAC Maintenance"
    },
    item3: {
      text: "Roof Repair",
      url: "/search?searchTerm=Roof Repair"
    },
    item4: {
      text: "Interior Design",
      url: "/search?searchTerm=Interior Design"
    },
  },
  {
    title: "Follow",
    item1: [
      {
        icon: <SocialIcon1 />,
        url: "https://www.linkedin.com/company/audtiklance/about/?viewAsMember=true"
      },
      {
        icon: <SocialIcon2 />,
        url: "https://x.com/AudtikLance"
      },
      {
        icon: <SocialIcon3 />,
        url: "http://instagram.com/audtiklance"
      },
      // {
      //   icon: <SocialIcon4 />,
      //   url: "http://instagram.com/audtiklance"
      // },
    ],
  },
];
export const Footer: FC<FooterProps> = () => {
  return (
    <>
      <section className="bg-[#17191A] text-white w-full flex justify-center">
        <div className="container grid w-full grid-cols-2 gap-6 px-6 py-16 lg:gap-0 xlg:px-0 lg:grid-cols-5 md:grid-cols-3 ">
          {data?.map((v) => (
            <div className="flex flex-col gap-4" key={Math.random()}>
              <span className="text-[#9E9E9F] ">{v.title as string}</span>
              <div className="flex flex-col gap-3 text-sm text-white md:text-base">
                {v?.title == "Follow" ? (
                  <div className="grid items-center w-full grid-cols-4 gap-14 md:gap-0">
                    {//@ts-expect-error
                      v?.item1?.map((v: any, idx) => (
                        <div
                          key={idx}
                          className="bg-[#27272A] isolate cursor-pointer flex aspect-square w-[clamp(45px,_8vw,_45px)]  items-center justify-center rounded-full"
                        >
                          <a href={v?.url} target="_blank" rel="noreferrer" className="">{v?.icon}</a>
                        </div>
                      ))}
                  </div>
                ) : (
                  <span className="cursor-pointer"><a href={(v.item1 as { url: string })?.url}>{(v.item1 as { text: string })?.text}</a></span>
                )}
                <span className="cursor-pointer"><a href={v.item2?.url}>{v.item2?.text}</a></span>
                <span className="cursor-pointer"><a href={v.item3?.url}>{v.item3?.text}</a></span>
                <span className="cursor-pointer"><a href={v.item4?.url}>{v.item4?.text}</a></span>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="w-full bg-white">
        <div className="container flex flex-wrap justify-center w-full gap-2 px-6 py-3 md:justify-between xlg:px-0">
          <span className="text-sm font-medium lg:text-base ">
            Copyrights 2024. All rights reserved
          </span>
          <div className="flex items-center">
            {[{ text: "Privacy policy", url: "/privacy" }, { text: "Terms & conditions", url: "/terms" }, { text: "Sitemap" }, { text: "Legal" }]?.map(
              (v, i) => (
                <div key={i} className="flex items-center ">
                  <a
                    href={v.url}
                    key={Math.random()}
                    className="text-sm font-medium cursor-pointer lg:text-base whitespace-nowrap"
                  >
                    {v.text}
                  </a>
                  {i !== 3 && <span className="text-[#B7B8B8] px-1">•</span>}
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};
