import { useQuery } from "@tanstack/react-query";
import { getFavoriteServiceFn } from "services";

export const useGetFavoriteService = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["favorite-service"],
    queryFn: getFavoriteServiceFn,
  });

  return { isLoading, userFavoriteServiceData: data };
};
