
import downIcon from "../../assets/svg/down-arrow-black.svg"
import { useGetCategory } from "hooks/services";
import { useGetServiceProfile } from "hooks/profile/useGetServiceProfile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchFilter } from "./SearchFilter";
import { ServiceCard } from "./ServiceCard";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Autocomplete, TextField } from "@mui/material";
import { otherServices } from "component/oldcomponent/OtherServices/data";
import { useEffect, useState } from "react";
import { SearchModal } from "./SearchModal";
import { getAllSkill } from "services";
import { useQuery } from "@tanstack/react-query";

export const SearchPage = () => {
  const { categoryData } = useGetCategory();
  const { serviceProfileData, serviceProfileLoading } = useGetServiceProfile();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("searchTerm") || "";
  const [num, setNum] = useState<{ skill: number, service: number }>({ skill: 10, service: 10 })
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState<number[]>([0, 400]);
  const navigate = useNavigate()

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const { data, refetch } = useQuery({
    queryKey: ["service"],
    queryFn: () => getAllSkill()
  });

  console.log(data);
  

  useEffect(()=> {
    setOpenModal(false)
  }, [searchTerm])

  const numResult =
    categoryData?.results?.length! > 0 ? categoryData?.results?.length! - 1 : 0;

  const handleServiceSelect = (_: any, value: string | null) => {
    navigate(`/search?searchTerm=${value}`)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  if (serviceProfileLoading) {
    return (
      <Box sx={{ display: 'flex', width: "100%", height: "97vh", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div className="px-6 py-24 lg:py-10 lg:px-16">
      <div className="flex flex-col items-start justify-between gap-4 lg:items-end lg:flex-row">
        <div>
          <p className="text-[#6E6E70] lg:text-[16px] text-[13px] font-semibold"><span onClick={() => navigate("/")} className="cursor-pointer">Home</span> / Search Results</p>
          <h1 className="text-[18px] lg:text-[24px] font-[700] text-[#212329] my-1">Service Provider Results ({serviceProfileData?.results?.length})</h1>
          <h6 className="text-[#6E6E70] lg:text-[16px] text-[13px] font-light">Didn't find your desired Skill Provider?</h6>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-[14px]">Sort By:</span>
          <div className="flex text-[14px] rounded-[50px] justify-center items-center gap-2 w-[170px] cursor-pointer h-[36px] bg-[#F1F5F9]">Recommended <img src={downIcon} alt="" /></div>
        </div>
      </div>
      <Autocomplete
        options={otherServices}
        getOptionLabel={(option) => option as string}
        onChange={handleServiceSelect}
        defaultValue={searchTerm}
        renderInput={(params) => (
          <TextField
            {...params}
            label="What service are you looking for today?....."
            variant="outlined"
            sx={{
              "& .MuiInputBase-input": {
                color: "grey",
              },
              "& .MuiInputLabel-root": {
                color: "grey",
              },
            }}
          />
        )}
        sx={{
          width: "100%",
          marginTop: "30px",
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "#10172A" // Keep border as default on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#10172A", // Remove blue border on focus
            },
          },
        }}
      />
      <div className="flex flex-col gap-4 mt-12 lg:flex-row">
        <SearchFilter handleChange={handleChange} setNum={setNum} num={num} value={value} searchParams={searchTerm} categories={data?.data || []} />
        {
          serviceProfileData?.results?.length! < 1 ?
            <div className="w-[100%] h-[70vh] lg:w-[75%] flex justify-center items-center">
              <p>No Result Found</p>
            </div> :
            <div className="w-[100%] lg:w-[75%]">
              {
                serviceProfileData?.results?.map((service, i) => (
                  <ServiceCard key={i} service={service} />
                ))
              }
            </div>
        }
      </div>
      <SearchModal openModal={openModal} handleChange={handleChange} handleModalClose={handleModalClose} num={num} setNum={setNum} value={value} searchParams={searchTerm} />
      <div className="fixed left-0 flex justify-center w-full lg:hidden bottom-7">
        <button onClick={() => setOpenModal(true)} className="w-32 text-white bg-blue-600 h-9 rounded-3xl">Filter</button>
      </div>
    </div>
  );
};
