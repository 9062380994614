import { useQuery } from "@tanstack/react-query";
import { getServiceProviderProfileById } from "services";

export const useGetServiceProfileById = (id: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ["service-provider-profiles"],
    queryFn: () => getServiceProviderProfileById(id),
  });

  return { serviceProfileLoading: isLoading, serviceProfileData: data };
};
