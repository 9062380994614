import React from "react";
import { ReactComponent as NotificationIcon } from "assets/svg/notification.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/setting-2.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/profile.svg";
import { LucideHelpingHand } from "lucide-react"
import { Link, useLocation } from "react-router-dom";
import { UpdateProfile } from "../profile/UpdateProfile";
import { FaUserEdit } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { MdOutlineFeaturedPlayList } from "react-icons/md";

export const SettingsLayout = ({ children }: { children: React.ReactNode }) => {
  const currentPath = useLocation();
  const NavigationTab = [
    { title: "Dashboard", icon: <ProfileIcon />, path: "/profile" },
    {
      title: "My Services",
      icon: <LucideHelpingHand />,
      path: "/myservice",
    },
    {
      title: "Update Profile",
      icon: <FaUserEdit  size={27}/>,
      path: "/update-profile",
    },
    {
      title: "Settings",
      icon: <SettingsIcon />,
      path: "/settings",
    },
    {
      title: "Featured Ads",
      icon: <MdOutlineFeaturedPlayList size={27} />,
      path: "/featured_pricing",
    },
    {
      title: "Payment Methods",
      icon: <CiCreditCard1 size={27} />,
      path: "/payment_methods",
    },
    
    {
      title: "Notification",
      icon: <NotificationIcon />,
      path: "/notification",
    },
  ];
  const isLinkActive = (href: string) => currentPath?.pathname === href;
  return (
 
      <section className="md:border h-screen md:border-[#E2E8F0] mt-4 flex rounded-xl">
      <div className="border-r border-[#E2E8F0] sticky top-[0px] h-[calc(100vh_-_80px)]  w-[305px] hidden md:block">
        <div className="border-b-[#E2E8F0] border-b py-4 flex items-center">
          <span className="px-4 text-sm font-bold">Settings</span>
        </div>
        <div className="flex flex-col h-full gap-1 py-4 mx-3 overflow-y-auto">
          {NavigationTab?.map((v) => (
            <Link
              key={Math.random()}
              // className="flex items-center gap-2 px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-50"
              className={`text-sm flex items-center cursor-pointer hover:bg-blue-50 py-2 px-4 rounded-lg gap-2 hover:text-primary-500 ${
                isLinkActive(v?.path) ? "font-semibold text-blue-500" : ""
              }`}
              to={v?.path}
            >
              <span>{v?.icon}</span>
              <span className="sm">{v.title}</span>
            </Link>
          ))}
        </div>
      </div>
      <div className="flex-1 hideScrollBar overflow-y-auto h-[calc(100vh_-_80px)]">
        <main className="w-full px-4 max-w-screen-xxlg">{children}</main>
      </div>
    </section>
   
  );
};
