import { IconCheck, IconX } from "@tabler/icons-react";
import {
  Id,
  toast,
  ToastContent,
  ToastOptions,
  UpdateOptions,
} from "react-toastify";
import { NotificationMsg } from "./NotificationMsg";

// Notifications props
interface NotifProps extends ToastOptions<object> {
  content?: ToastContent<unknown>;
  message?: string;
  subtitle?: string;
}

// Update notifications props
interface UpdateNotifProps extends UpdateOptions<object> {
  content?: ToastContent<unknown>;
  message?: string;
  subtitle?: string;
}

// Success notifications trigger
const success = ({ content, message, subtitle, ...options }: NotifProps) =>
  toast.success(
    content || <NotificationMsg message={message} subtitle={subtitle} />,
    {
      icon: (
        <div className="p-1 rounded-[50px] bg-green-500">
          <IconCheck color="white" style={{ width: "1rem", height: "1rem" }} />
        </div>
      ),
      style: { backgroundColor: "#F5F5F5" },
      ...options,
    },
  );

// Error notifications trigger
const error = ({ content, message, subtitle, ...options }: NotifProps) =>
  toast.error(
    content || (
      <NotificationMsg message={message} subtitle={subtitle} type="error" />
    ),
    {
      icon: (
        <div className="p-1 rounded-[50px] gap-3 bg-red-500">
          <IconX color="white" style={{ width: "1rem", height: "1rem" }} />
        </div>
      ),
      style: { backgroundColor: "#ffefef" },
      ...options,
    },
  );

// Update notifications trigger
const update = (
  id: Id,
  { content, message, subtitle, type, ...props }: UpdateNotifProps,
) =>
  toast.update(id, {
    render: content || (
      <NotificationMsg message={message} subtitle={subtitle} />
    ),
    icon:
      type === "error" ? (
        <IconX color="white" style={{ width: "1rem", height: "1rem" }} />
      ) : (
        <IconCheck color="white" style={{ width: "1rem", height: "1rem" }} />
      ),
    ...props,
  });

// Dismiss all notifications
const dismissAll = () => toast.dismiss();

export const notify = { success, error, update, dismissAll };
