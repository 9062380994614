import { useQuery } from "@tanstack/react-query";
import {
  getConversationList,
  getFavoriteServiceFn,
  getPreviousConversation,
} from "services";

export const useGetConvoList = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["convo"],
    queryFn: getConversationList,
  });

  return { isLoading, allChatList: data };
};
