import dayjs from "dayjs";

export const formatter = (amount: number, currency?: string) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ?? "USD",
  });
  return formatter.format(amount);
};

export const formatterByLocale = (amount?: number, locale?: string) => {
  const formatter = new Intl.NumberFormat(locale === "en" ? "en-US" : "ja-JP", {
    style: "currency",
    currency: "jpy",
  });
  return formatter.format(amount ?? 0);
};

export const formatTime = (date: string, locale?: string) => {
  return locale === "en" || locale === undefined
    ? dayjs(date).format("h:mm:ss A")
    : dayjs(date).format("HH:mm:ss A");
};
