import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "ui/button";
import CustomSelect from "ui/controlledSelectedComponent";
import FileUploader from "ui/fileUploader";
import { FormTextInput } from "ui/formTextInput";
import { profileSchema } from "./profileSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormStore } from "store";
import FormTextarea from "ui/formTextArea";

export const PersonalInfoForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const [valid, setValid] = useState(false)
  const methods = useForm({
    mode: "onChange", // Enables real-time validation
    resolver: yupResolver(profileSchema), // Custom resolver
  });

  const [formState] = useState(() => {
    const stored = JSON.parse(localStorage.getItem("form") as string);

    return stored || {};
  });

  const setSelected = useFormStore((state) => state.setSelected);

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid, }, // Access form validation state
    register,
    watch
  } = methods;

  useEffect(() => {
    setValue("city", formState?.city);
    setValue("country", formState?.country);
    setValue("email", formState?.email);
    setValue("name", formState?.name);
    setValue("firstname", formState?.firstname);
    setValue("lastname", formState?.lastname);
    setValue("password", formState?.password);
    setValue("phoneNumber", formState?.phoneNumber);
    setValue("state", formState?.state);
    setValue("zipCode", formState?.zipCode);
    setValue("description", formState?.description)
  }, [setValue]);

  useEffect(()=> {
    if (watch()?.city && watch()?.country && watch()?.email && watch()?.name && watch()?.firstname && watch()?.lastname && watch()?.password && watch()?.phoneNumber && watch()?.state && watch()?.zipCode && watch().description && watch()?.pictures.length > 0) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [watch()])

  const onFormSubmit = (data: any) => {
    setSelected({
      ...data,
    });
    localStorage.setItem("form", JSON.stringify(data));
    // If form is valid, proceed to the next step
    onSubmit();
  };

  return (
    <FormProvider {...methods}>
      <form
        className="grid grid-cols-1 px-4  md:grid-cols-[1fr_1fr_auto] gap-6 pb-10"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        {/* Personal Information Fields */}
        <div className="flex flex-col gap-8">
          <FormTextInput
            //name={"name"}
            
            labelText="First Name"
            placeholder="Enter your first name..."
            {...register("firstname")}
          />
          <FormTextInput
            //name={"name"}
            labelText="Last Name"
            placeholder="Enter your last name..."
            {...register("lastname")}
          />
          <FormTextInput
            //name={"name"}
            labelText="Business Name"
            placeholder="Enter your business name..."
            {...register("name")}
          />

          <FormTextInput
            //name={"email"}
            type="email"
            labelText="Email Address"
            placeholder="Enter your email address..."
            {...register("email")}
          />
          <FormTextInput
            //name={"email"}
            type="password"
            labelText="Password"
            placeholder="Enter your password..."
            {...register("password")}
          />

          <FormTextInput
            // name={"country"}
            type="text"
            labelText="Country"
            placeholder="Enter your country..."
            {...register("country")}
          />

          <FormTextInput
            //  name={"state"}
            labelText="State"
            placeholder="Enter your state..."
            {...register("state")}
          />
        </div>

        {/* Contact Information Fields */}
        <div className="flex flex-col gap-8">
          <FormTextInput
            //  name={"phoneNumber"}
            labelText="Phone Number"
            placeholder="Enter your phone number..."
            {...register("phoneNumber")}
          />

          {/* <CustomSelect
            // name={"city"}
            label="City"
            placeholder="Select your city..."
            options={[]} // Add your city options here
            {...register("city", { required: "City is required" })}
          /> */}

          <FormTextInput
            //  name={"state"}
            labelText="City"
            placeholder="Enter your state..."
            {...register("city")}
          />

          <FormTextInput
            // name={"zipCode"}
            labelText="Zip Code"
            placeholder="Enter your zip code..."
            {...register("zipCode")}
          />
          <FormTextarea rows={6} {...register("description")} name="description" label="Business Description" placeholder="Describe your business" />
        </div>

        {/* File Upload */}
        <div className="min-w-[18rem] h-full pb-3">
          <FileUploader
            id={`file-input-cac`}
            snapType={""}
            isMultiple={true}
            name="pictures"
            variant="type2"
         
          />
        </div>

        {/* Button Control */}
        <div className="flex fixed bottom-0 left-0 right-0 gap-4 h-[80px] bg-white justify-end border-t border-gray-200">
          <div className="container flex items-center justify-end w-full gap-4">
            <Button variant="light-blue" className="px-10 w-fit">
              Cancel
            </Button>
            <Button
              variant="default"
              size="sm"
              className="w-fit px-14"
              type="submit"
              disabled={!valid} // Disable until form is valid
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
