import { SettingsLayout } from "context/settings/layout/SettingsLayout";
// import { ToggleSwitch } from "flowbite-react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
// import SwitchContainer from "ui/switch";

export const Notification = () => {
  const methods = useForm<any>({});
  return (
    <SettingsLayout>
      <FormProvider {...methods}>
        <form className="flex flex-col w-full px-6 py-3">
          <span className="mb-6 font-bold">Notification</span>
          {/* {Array(5)
            .fill(0)
            .map((_) => (
              <div className="flex flex-col w-full">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col w-full " key={Math.random()}>
                    <span className="font-bold ">
                      Special communications & offers
                    </span>
                    <span className="text-sm  text-[#64748B]">
                      Receive updates. offers and more
                    </span>
                  </div>
                  <div>
                    <SwitchContainer name={"switch"} handleToggle={() => {}} />
                  </div>
                </div>
                <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" />
              </div>
            ))} */}
        </form>
      </FormProvider>
    </SettingsLayout>
  );
};
