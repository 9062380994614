import { SettingsLayout } from 'context/settings/layout/SettingsLayout'
import { MyServices } from 'context/settings/services/MyServices'
import React from 'react'

export const Services = () => {
  return (
    <SettingsLayout>
        <MyServices />
    </SettingsLayout>
  )
}
