import React from "react";

interface AvatarProps {
  src?: string;
  alt?: string;
  size?: number;
  fallbackText?: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  alt = "avatar",
  size = 50,
  fallbackText,
  className = "",
}) => {
  const getFallbackText = (text: string | undefined): string => {
    if (!text) return "";
    const nameParts = text.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.slice(0, 2); // Take first two initials
  };

  return (
    <div
      className={` isolate ml-1 flex aspect-square w-[clamp(50px_8vw_50px)] items-center justify-center rounded-full bg-gray-300 text-white ${className}`}
      style={{ width: size, height: size, fontSize: size * 0.4 }}
    >
      {src ? (
        <img
          src={src}
          alt={alt}
          className="rounded-full w-full h-full object-cover"
        />
      ) : (
        <span className={className}>{getFallbackText(fallbackText)}</span>
      )}
    </div>
  );
};
