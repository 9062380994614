// Define the DeviceType as a union type
type DeviceType = "desktop" | "mobile";

export interface ISelected {
  type: DeviceType; // This must match the union type
  open: string;
  data: Record<string, any>;
}

export interface IStore {
  selected: ISelected;
  setSelected: (selected: ISelected) => void;
  onClose: () => void;
}

// Initial store creation ensuring type safety
export const createCommonStore = (): IStore => ({
  selected: {
    type: "desktop", // Initialize with a value from DeviceType
    open: "",
    data: {} as Record<string, any>,
  },
  setSelected: (selected: ISelected) => {
    return { selected };
  },
  onClose: () => ({
    selected: {
      type: "desktop", // Reset to a value from DeviceType
      open: "",
      data: {},
    },
  }),
});
