import React, { useEffect, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";

interface OtpInputProps {
  name: string;
  length: number;
  className?: string;
  size?: "sm" | "md" | "lg";
  type?: "text" | "number";
  onChange: (value: string) => void;
}

export const OtpInput: React.FC<OtpInputProps> = ({
  name,
  length,
  className,
  size = "md",
  type = "text",
  onChange,
}) => {
  const { control, setValue, getValues, watch } = useFormContext();
  const otpRefs = useRef<(HTMLInputElement | null)[]>([]);

  // Initialize OTP array on mount
  useEffect(() => {
    const otpArray = Array(length).fill("");
    setValue(name, otpArray);
  }, [length, name, setValue]);

  // Watch the OTP array
  const otpValues = watch(name, Array(length).fill(""));

  const handleChange = (value: string, index: number) => {
    if (type === "number" && !/^\d*$/.test(value)) return; // Ensure only numeric input if type is number

    const otpArray = getValues(name);
    otpArray[index] = value;
    setValue(name, otpArray, { shouldValidate: true });
    onChange(otpArray.join(""));

    // Move to next input field
    if (value && index < length - 1) {
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === "Backspace" && !otpValues[index] && index > 0) {
      otpRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === length && /^\d*$/.test(pasteData)) {
      const otpArray = pasteData.split("");
      setValue(name, otpArray, { shouldValidate: true });
      onChange(otpArray.join(""));
      otpArray.forEach((value, index) => {
        if (otpRefs.current[index]) {
          otpRefs.current[index]!.value = value;
        }
      });
      otpRefs.current[length - 1]?.focus();
      e.preventDefault();
    }
  };

  const sizeClass =
    size === "sm"
      ? "w-8 h-8 text-base"
      : size === "lg"
        ? "w-16 h-16 text-xl"
        : "w-12 h-12 text-lg";

  const handleInput = (e: React.FormEvent<HTMLInputElement>, index: number) => {
    if (e.currentTarget.value === "") {
      const otpValues = getValues(name);
      otpValues[index] = "";
      setValue(name, otpValues, { shouldValidate: true });
    }
  };

  return (
    <div className={`flex space-x-2 ${className}`}>
      {otpValues.map((_: any, index: number) => (
        <Controller
          key={index}
          name={`${name}.${index}`}
          control={control}
          render={({ field }) => (
            <input
              {...field}
              ref={(el) => (otpRefs.current[index] = el)}
              type={type}
              maxLength={1}
              className={`
                ${sizeClass}
                ${type === "number" ? "appearance-none" : ""}
                border
                rounded
                text-center
                text-lg
                ${type === "number" ? "no-spinner" : ""}
              `}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              value={otpValues[index]}
              onInput={(e) => handleInput(e, index)}
              onPaste={handlePaste}
            />
          )}
        />
      ))}
    </div>
  );
};
