import React from "react";
import {
  useFormContext,
  Controller,
  Control,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  rows?: number;
  className?: string;
  control?: Control<any>;
  customStyle?: React.CSSProperties;
  labelClass?: string;
};

const FormTextarea: React.FC<Props> = ({
  name,
  label,
  placeholder = "",
  rows = 4,
  className = "",
  control,
  customStyle,
  labelClass,
}) => {
  const { formState } = useFormContext();
  const { errors } = formState;

  const getErrorMessage = (
    error:
      | string
      | FieldError
      | Merge<FieldError, FieldErrorsImpl<any>>
      | undefined,
  ): string | null => {
    if (!error) {
      return null;
    }
    if (typeof error === "string") {
      return error;
    }
    if ("message" in error && typeof error.message === "string") {
      return error.message;
    }
    return null;
  };

  const errorMessage = getErrorMessage(errors[name]);

  return (
    <div className={`flex flex-col w-full ${className}`} style={customStyle}>
      {label && (
        <label
          className={`${
            labelClass ? labelClass : "font-medium"
          } mb-2 text-sm  text-gray-900 dark:text-white`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <textarea
            {...field}
            id={name}
            rows={rows}
            placeholder={placeholder}
            className={`resize-none border ring-1 placeholder:text-xs ring-[#E2E8F0] border-[#E2E8F0] p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
              errorMessage ? "border-red-500" : ""
            }`}
          />
        )}
      />
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default FormTextarea;
