/* eslint-disable react/no-array-index-key */
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { Card } from "ui/card";
import FileUploader from "ui/fileUploader";
// import GardenImg from "assets/images/blogimg.png";
import { Button } from "ui/button";

export const ServiceProfileCreation = () => {
  const methods = useForm();
  return (
    <div className="mt-[80px] grid grid-cols-12 gap-6 container mb-20">
      <div className="col-span-3 h-[100vh] overflow-y-scroll hideScrollBar">
        {/* <Card>
          {Array(4)
            .fill(0)
            .map(() => (
              <div className="bg-[#F6F6F6] rounded-lg p-3">
                <div className=" bg-white  grid grid-cols-2 gap-3 p-3">
                  {Array(4)
                    .fill(0)
                    .map(() => (
                      <div className="w-[clamp(78px_6vw_78px)] border border-gray-300 aspect-square rounded-lg  ">
                        <img src={GardenImg} alt="garden img" />
                      </div>
                    ))}
                </div>
                <span className="font-semibold">Service 1</span>
              </div>
            ))}
        </Card> */}
      </div>
      <div className="col-span-9 w-full">
        <FormProvider {...methods}>
          <div className="w-full">
            <FileUploader
              name={`pictures`}
              id={`file-input-cac`}
              snapType={""}
              isMultiple={true}
              variant="type2"
            />
            <div className=" bg-white  grid grid-cols-8 gap-3 mt-6">
              {Array(20)
                .fill(0)
                .map((_, idx) => (
                  <div
                    key={idx}
                    className="w-[clamp(78px_6vw_78px)]  aspect-square rounded-lg  bg-[#F1F5F9]"
                  />
                ))}
            </div>
          </div>
        </FormProvider>
      </div>
      <div className="flex  fixed bottom-0 left-0 right-0 gap-4 h-[80px]  bg-white justify-end border-t border-gray-200">
        <div className="flex items-center w-full justify-end gap-4 container">
          <Button variant="light-blue" className="w-fit px-10">
            Cancel
          </Button>
          <Button variant="default" size="sm" className="w-fit px-14">
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
