/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PostCodeIcon, PadLockIcon } from "assets/svg";
import { ReactComponent as EmailIcon } from "assets/svg/emailIcon.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/username.svg";
import { FormProvider, useForm } from "react-hook-form";
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from "react-phone-input-2";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { passResetReqSchema, signupSchema } from "../authWrapper/schema";
import { ConfirmPassword } from "../confirmPassword";
import { notify } from "shared/notifications/notify";
import { isAuthenticated, setRefreshToken, setToken } from "utils/auth/auth";
import { FormTextInput } from "ui/formTextInput";
import { Checkbox } from "ui/checkBox";
import { Button } from "ui/button";
import { RegistrationCard } from "@component";
import { ICreateEmailType, ISignUpType } from "types";
import { createAnAccount, createAnEmail } from "services";

export interface IFormType {
  email: string;
  firstname: string;
  lastname: string;
  postCode: string;
  phone: string;
  password: string;
  countryCode: string;
  terms: boolean;
  otp?: string;
  captchaToken: string;
}

const defaultValues: IFormType = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  countryCode: "234",
  password: "",
  postCode: "",
  terms: false,
  otp: "",
  captchaToken: ""
};

export const SignUp = ({ openSignupForm, setOpenSignUp }: any) => {
  const queryClient = useQueryClient();
  const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const handleVerify = (token: string | null) => {
    setCaptchaToken(token);
    methods.setValue("captchaToken", token)
  };
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(signupSchema),
    defaultValues,
  });

  const otpRequestMethod = useForm({
    mode: "onChange",
    resolver: yupResolver(passResetReqSchema),
  });

  const { phone, terms } = methods.watch();
  const { errors } = methods.formState;

  const { mutate, isPending: isFetching } = useMutation({
    mutationFn: async (payload: any) => await createAnAccount({ ...payload }),
    onSuccess: async ({ data }) => {
      // window.location.reload();
      setOpenConfirmPassword(false);
      setOpenSignUp(false);
      setToken(data?.access);
      setRefreshToken(data?.refresh);
      queryClient.refetchQueries({
        queryKey: ["get-user-profile"],
      });
      isAuthenticated();
      notify.success({
        subtitle: "Successfully created",
        message: "Welcome back champ",
      });
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error || "",
      });
    },
  });

  const { mutate: emailMutate, isPending: isLoading } = useMutation({
    mutationFn: async (payload: ICreateEmailType) =>
      await createAnEmail({ ...payload }),
    onSuccess: async (response) => {
      setOpenSignUp(false);
      setSecretKey(response?.data);
      setOpenConfirmPassword(true);
      notify.success({
        message: "Success",
        subtitle: "sent successfully",
      });
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error || "",
      });
    },
  });

  // const { mutate: otpMutate } = useMutation({
  //   mutationFn: async (payload: IOtpType) => await confirmOTP({ ...payload }),
  //   onSuccess: async (response) => {
  //     setOpenConfirmPassword(false);
  //     handleSignUp(false);
  //     handleOpenLogin(true);
  //   },
  //   onError: async (response) => {
  //     notifications.show({
  //       message: response?.message,
  //     });
  //     // setOpenConfirmPassword(false);
  //   },
  // });

  const onSubmitEmail = (payload: any) => {
    // emailMutate({
    //   email: payload?.email,
    // });

    mutate({
      country_code: payload?.countryCode,
      email: payload?.email,
      lastname: payload?.lastname,
      firstname: payload?.firstname,
      password: payload?.password,
      phone: payload?.phone,
      post_code: payload?.postCode,
      otp: payload?.otp!,
      secret_key: secretKey,
    });
  };

  const onSubmit = (payload: IFormType) => {
    mutate({
      country_code: payload?.countryCode,
      email: payload?.email,
      firstname: payload?.firstname,
      lastname: payload?.lastname,
      password: payload?.password,
      phone: payload?.phone,
      post_code: payload?.postCode,
      otp: payload?.otp!,
      secret_key: secretKey,
    });
  };

  return (
    <>
      <RegistrationCard
        show={openSignupForm === "signup"}
        onClose={() => setOpenSignUp("")}
      >
        <div className="flex justify-center w-full">
          <span className="text-xl font-bold">Sign up to Audtiklance</span>
        </div>
        <FormProvider {...methods}>
          <form
            className="flex flex-col w-full gap-5 mt-6"
            onSubmit={methods.handleSubmit(onSubmitEmail)}
          >
            <FormTextInput
              placeholder="First name"
              icon={<ProfileIcon />}
              className="text-xs "
              {...methods.register("firstname")}
            />
            <FormTextInput
              placeholder="Last name"
              icon={<ProfileIcon />}
              className="text-xs "
              {...methods.register("lastname")}
            />
            <FormTextInput
              placeholder="Email address"
              icon={<EmailIcon />}
              {...methods.register("email")}
            />

            <div className="flex items-start w-full gap-3">
              <div className="w-auto flex-col mt-[7px] flex items-center border rounded-[9px] ">
                <PhoneInput
                  country={"ng"}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                    borderRadius: "30px",
                  }}
                  inputStyle={{
                    border: "none",
                    width: "100px",
                    borderRadius: "8px",
                    height: "42px",
                  }}
                  containerStyle={{
                    display: "flex",
                    alignItems: "center",
                    border: "none",
                    borderRadius: "30px",
                  }}
                  onChange={(value) => methods.setValue("countryCode", value)}
                />
                {errors?.countryCode?.message && (
                  <div className="mt-1 text-xs text-red-600">
                    {/* {errors.countryCode.message} */}
                  </div>
                )}
              </div>
              <div className="w-full">
                <FormTextInput
                  placeholder="Phone number"
                  type="number"
                  {...methods.register("phone")}
                  style={{ padding: 10 }}
                />
              </div>
            </div>

            <FormTextInput
              placeholder="Password"
              className="w-full h-full"
              icon={<img src={PadLockIcon} alt="padlock-icon" />}
              type="password"
              {...methods.register("password")}
            />
            <FormTextInput
              placeholder="Postcode"
              icon={<img src={PostCodeIcon} alt="postcode-icon" />}
              className="text-xs font-normal "
              {...methods.register("postCode")}
            />
            <div>
              <Checkbox
                labelText="I accept the terms and agreements"
                {...methods.register("terms")}
              />
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
              onChange={handleVerify}
            />
            <Button className="mt-4" type="submit" loading={isFetching}>
              Sign up
            </Button>
          </form>
        </FormProvider>
        <div className="flex justify-center w-full mt-3">
          <span className="text-sm ">
            Already have an account?{" "}
            <span
              onClick={() => setOpenSignUp("login")}
              className=" text-[#4188F9] cursor-pointer"
            >
              Login
            </span>
          </span>
        </div>
      </RegistrationCard>
      <div>
        <ConfirmPassword
          openConfirmPassword={openSignupForm}
          setOpenConfirmPassword={setOpenSignUp}
          otpMethod={methods}
          onSubmit={onSubmit}
          phone={phone}
          handleSignUp={setOpenSignUp}
          resendOTP={emailMutate}
          isLoading={isFetching || isLoading}
          requestOTPMethod={otpRequestMethod}
        />
      </div>
    </>
  );
};
