import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PadLockIcon, EmailIcon, GoogleIcon } from "assets/svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "routes";
import "react-phone-input-2/lib/style.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signinSchema } from "../authWrapper/schema";
import { notify } from "shared/notifications/notify";
import {
  getDecodedJwt,
  isAuthenticated,
  setRefreshToken,
  setToken,
} from "utils/auth/auth";
import ReCAPTCHA from 'react-google-recaptcha';
import { FormTextInput } from "ui/formTextInput";
import { Button } from "ui/button";
import { Checkbox } from "ui/checkBox";
import { RegistrationCard } from "@component";
import { ILoginUserType } from "types";
import { loginUser } from "services";
// import { isPending } from "@reduxjs/toolkit";

export const LoginForm = ({
  // handleOpenForgetPassword,
  // handleOpenSignUp,
  setOpenLogin,
  loginPrompt,
  openLogin,
}: {
  // handleOpenSignUp: Dispatch<SetStateAction<boolean>>;
  // handleOpenForgetPassword: () => void;
  openLogin: string;
  loginPrompt?: string;
  setOpenLogin: Dispatch<SetStateAction<string>>;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const errorParams = searchParams.get("error");
  const accessToken = searchParams.get("access_token");
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);


  const handleVerify = (token: string | null) => {
    setCaptchaToken(token);
    methods.setValue("captchaToken", token!)
  };
  
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload: ILoginUserType) =>
      await loginUser({ ...payload }),
    onSuccess: async ({ data }) => {
      setToken(data?.access);
      const decoded = getDecodedJwt(data?.access);

      setRefreshToken(data?.refresh);
      setOpenLogin("");
      queryClient.refetchQueries({
        queryKey: ["get-user-profile"],
      });

      isAuthenticated();

      if (data?.service_profile) {
        navigate("/profile");
      } else {
        navigate("/");
      }
      return notify.success({
        message: "Login successful",
        subtitle: `Welcome back champ`,
      });
    },
    onError: async ({ response }: any) => {
      return notify.error({
        message: "Bad request",
        subtitle: response?.data?.error,
      });
    },
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(signinSchema),
  });

  const onSubmit = (payload: ILoginUserType) => {
    mutate({
      email: payload?.email,
      password: payload?.password,
    });
  };

  // Token data is invalid
  useEffect(() => {
    if (errorParams) {
      navigate(routes.HOME.path);
      notify.error({
        message: "Error",
        subtitle: decodeURIComponent(errorParams),
      });
    } else if (accessToken) {
      setToken(accessToken);
      if (isAuthenticated()) {
        navigate(routes.HOME.path);
      }
    }
  }, [errorParams, accessToken]);

  return (
    <RegistrationCard
      show={openLogin === "login"}
      onClose={() => setOpenLogin("")}
    >
      <div className="flex justify-center w-full">
        <span className="text-xl font-bold">Login {loginPrompt ? loginPrompt : 'with your email'} </span>
      </div>
      <FormProvider {...methods}>
        <form
          className="flex flex-col w-full gap-5 mt-6"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <FormTextInput
            labelText="Email address"
            {...methods.register("email")}
            placeholder="email"
            type="email"
            icon={<img src={EmailIcon} alt="email icon" />}
          />

          <FormTextInput
            labelText="Password"
            {...methods.register("password")}
            placeholder="Password"
            type="password"
            icon={<img src={PadLockIcon} alt="padlock icon" />}
          />

          <div className="flex justify-between w-full">
            {" "}
            <Checkbox
              defaultChecked
              labelText="Stay login"
              name={"stayLogin"}
            />
            <div
              onClick={() => setOpenLogin("forgot-password")}
              className="text-xs underline cursor-pointer"
            >
              Forgot Password?
            </div>
          </div>
          {/* <Button size="md" type="submit" loading={isPending}>
            Login
          </Button> */}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
            onChange={handleVerify}
          />
          <Button
            loading={isPending}
            // style={{ padding: "15px" }}
            variant="default"
            disabled={isPending}
          >
            Login
          </Button>
        </form>
      </FormProvider>
      <div>
        <div className="flex flex-col items-center w-full my-3">
          <span className="text-sm text-[#7A7B7F]">Or Login with</span>
          <div className="flex flex-col w-full gap-3 my-4">
            <Button
              style={{
                fontWeight: 400,
                color: "#212329",
                backgroundColor: "#F6F6F6",
              }}
              size="sm"
              onClick={() => {
                const url = ` https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=openid%20email%20profile&access_type=online&response_type=token`;
                window.open(url, "_self");
              }}
            >
              <img src={GoogleIcon} alt="google icon" />{" "}
              <span> Continue with Google</span>
            </Button>
            {/* <Button
              style={{
                backgroundColor: "#F6F6F6",
                color: "#212329",
                fontWeight: "normal",
              }}
            >
              <img src={AppleIcon} className="mx-3" alt="apple icon" /> Continue
              with Apple ID
            </Button>
            <Button
              style={{
                backgroundColor: "#F6F6F6",
                color: "#212329",
                fontWeight: "normal",
              }}
            >
              <img src={FacebookIcon} className="mx-3" /> Continue with Facebook
            </Button> */}
          </div>
        </div>
        <div className="flex justify-center w-full mt-3">
          <span className="text-sm cursor-pointer ">
            Don't you have an account?{" "}
            <span
              onClick={() => {
                setOpenLogin("signup");
                // handleOpenSignUp(true);
              }}
              className=" text-[#4188F9] underline"
            >
              Register
            </span>
          </span>
        </div>
      </div>
    </RegistrationCard>
  );
};
