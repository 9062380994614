import { createContext, useState, useContext } from "react";
import { getDecodedJwt, getToken } from "utils/auth/auth";
import { IUserInContext } from "utils/auth/auth.type";
// import { IUserCTXType, ProviderProps } from 'types/contextProvider.type';

export type ContextType = {
  authUser: IUserInContext | null;
  setAuthUser: React.Dispatch<React.SetStateAction<IUserInContext | null>>;
};

const AuthContext = createContext({} as ContextType);

const getUser = () => {
  const userToken = getToken() as string;
  if (userToken) {
    try {
      const decodedUser = getDecodedJwt(userToken);
      return decodedUser;
    } catch (error) {
      return null;
    }
  } else {
    return null; // Token doesn't exist in localStorage
  }
};

const decodedUser = getUser();

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authUser, setAuthUser] = useState<IUserInContext | null>(decodedUser);

  // useEffect(() => {
  //   const user = getUser();
  //   setAuthUser(user);
  // }, []);

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;

// export const useAuthContext = () => useContext(AuthContext);
export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
