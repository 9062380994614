import classNames from "classnames";
import React from "react";

export const Card = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      // shadow-sm p-5 hover:shadow-xl
      className={classNames(
        `${
          className
            ? `${className} flex h-full min-h-[200px] w-full bg-white shadow-sm`
            : "flex h-full min-h-[200px] bg-white shadow-sm px-4 border border-[#E2E8F0] w-full rounded-[16px] flex-col my-2 lg:min-w-[240px] gap-3 pb-5 pt-[12px]"
        } `,
      )}
    >
      {children}
    </div>
  );
};
