import classNames from "classnames";
import React from "react";
import { Button } from "./button";

interface IAdzCard {
  img?: string;
  list?: any[];
  showButton?: React.ReactNode;
  name?: string;
  description?: string;
  date?: string;
}
export const AdzCard = ({
  img,
  list,
  name,
  showButton,
  description,
  date,
}: IAdzCard) => {
  return (
    <div
      // shadow-sm p-5 hover:shadow-xl
      className={classNames(
        "h-full w-full flex flex-col gap-6 xlg:min-w-[260px] px-4  p-3 hover:shadow-xl hover:cursor-pointer overflow-x-auto rounded-[10px]",
      )}
      // hover:shadow-[0px_20px_24px_0px_#1111110F]
      // className={classNames(
      //   `flex h-full min-h-[219px] flex-col my-2 lg:min-w-[260px] gap-3 rounded-[5px] pb-5 pt-[12px]`
      //   // "bg-white shadow-[0px_20px_24px_0px_#1111110F]"
      // )}
      // relative h-1/2 min-h-[112px] w-full overflow-hidden rounded-[5px]
    >
      <img src={img} alt="adz card" className="object-cover " />

      {name && <span className="text-xs font-bold md:text-base">{name}</span>}
      {date && <span className="text-xs text-[#3F6C71]">{date}</span>}
      {description && (
        <span className="text-base font-bold">{description}</span>
      )}
      <ul className="flex flex-col gap-1">
        {list?.map((v: any, i: number) => (
          <li
            key={Math.random()}
            className="text-[#475569] text-xs md:text-sm  "
          >
            {v}
            {list.length === i + 1 && (
              <li className="text-sm text-[#475569] mt-1 font-medium line-clamp-1">
                more...
              </li>
            )}
          </li>
        ))}{" "}
      </ul>
      {/* {showButton ? (
        showButton
      ) : (
        <Button
          variant="plain"
          className="bg-[#030817] hover:bg-[#222326] text-white font-bold"
        >
          View Page
        </Button>
      )} */}
      {/* bg-[#030817] hover:bg-[#222326] */}
      {/* className="bg-[#030817] hover:bg-[#222326]" */}
      {/* bg-[#E6E9ED] mr-[-1px] text-black font-bold */}
    </div>
  );
};
