import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { HydrationWrapper } from "shared";
import { App } from "App";
// import reportWebVitals from "./reportWebVitals";
// import NotificationContainer from "shared/provider/notification/notificationProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    {/* <HydrationWrapper> */}

    <App />

    {/* </HydrationWrapper> */}
  </React.StrictMode>,
);

// reportWebVitals();
