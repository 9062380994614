/* eslint-disable prettier/prettier */
import React from "react";
import DesktopSIdebar from "./DesktopSIdebar";
import ChatContent from "./ChatContent";
import { useGetPreviousConvo } from "hooks/services/useGetPreviousConvo";
import { useParams } from "react-router-dom";
import { useMakeConversation } from "hooks/services/useMakeConversation";

const ChatDetailPage = () => {
  const { id } = useParams();
  const { isLoading, prevConvo } = useGetPreviousConvo(id as string);

  const { makeConvo } = useMakeConversation();

  return (
    <div className=" container mt-[40px] space-y-6 p-4 ">
       <div>
      <h1 className="text-2xl">Inbox</h1>
      </div>
      <main className="w-full shadow-xl border-[.1px] rounded-lg p-4">
      <div className="md:hidden">
        <ChatContent />
      </div>
      <section className="hidden md:block px-4 bg-accents-300">
        {/* <div className="hidden md:block py-4 px-4 ">
          <CustomBreadcrumb
            breadcrumbs={[
              { label: "home", href: `/home` },
              {
                // label: `${t("order")}`,
                label: t("message"),
                href: `/messages`,
              },
            ]}
          />
        </div> */}

        <div className=" bg-accents-300 ">
          <div className="grid grid-cols-[260px_1fr]">
            <div>
              <DesktopSIdebar />
            </div>
            <div>
              
              <ChatContent />
            </div>
          </div>
        </div>
      </section>
    </main>
    </div>
   
  );
};

export default ChatDetailPage;
