import { IconProps } from "assets/Icons/IconProps";

export default function SendIcon({ color = "#4188F9", ...props }: IconProps) {
  return (
    <div style={{
      background: color
    }} className={`bg-[${color}] size-10 rounded-full flex items-center justify-center`} >
    <svg {...props}  width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.69817 1.034L18.0002 9L1.69817 16.966C1.60512 17.0018 1.50354 17.0091 1.40633 16.987C1.30911 16.9649 1.22065 16.9144 1.15217 16.842C1.08169 16.7676 1.03317 16.6752 1.01199 16.5749C0.990806 16.4747 0.997793 16.3705 1.03217 16.274L3.50017 9L1.03217 1.726C0.997793 1.62947 0.990806 1.52531 1.01199 1.42506C1.03317 1.32481 1.08169 1.23237 1.15217 1.158C1.22065 1.08555 1.30911 1.03509 1.40633 1.01301C1.50354 0.990932 1.60512 0.998238 1.69817 1.034Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    


    </div>
  );
}
