import dayjs from "dayjs";

export const formatDate = (date: string, withTime?: boolean) => {
  if (withTime) {
    return dayjs(date).format("MMMM D, YYYY h:mm:ss A");
  } else {
    return dayjs(date).format("DD MMM YYYY");
  }
};
export const formatTime = (date: string) => {
  return dayjs(date).format("h:mm:ss A");
};
