import React from 'react'

export const PrivacyMain: React.FC = () => {
    return (
        <div className="px-6 py-8 mt-8 bg-gray-50">
            <div className="max-w-4xl p-8 mx-auto bg-white rounded-lg shadow-md">
                <h1 className='mb-4 text-2xl font-semibold' id="privacy-policy">Privacy Policy</h1>
                <p className='mb-4 leading-loose'>
                    <em>Last Updated: November 16, 2024</em>
                </p>

                <p className='leading-loose'>
                    Audtiklance, Inc. and its affiliates (collectively "Audtiklance," "we,"
                    "our," or "us") value your privacy. In this Privacy Policy
                    ("Policy"), we describe the information we collect, how we use it, and when
                    and to whom we disclose it. This Policy applies to all of our websites, mobile
                    applications, and other online services (collectively, the "Platform") made
                    available by or through Audtiklance and on which we post this Policy, including through
                    our URL
                    <a href="/">www.audtiklance.com</a> and <a href="https://setter.com/">www.setter.com</a>,
                    and to information we collect offline or through other means. Undefined capitalized
                    terms used herein shall have the definitions as set forth in our
                    <a href="/terms/">Terms of Use</a>. By accessing or using the Platform, you agree to
                    this Policy. IF YOU DO NOT AGREE TO THIS POLICY, PLEASE DO NOT ACCESS OR USE THE
                    PLATFORM.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>KEY TERMS</h2>
                <p className='leading-loose'>
                    "Content" means text, graphics, images, music, software, audio, video,
                    information or other materials, including but not limited to profile information, Pro
                    Services requests, quotes, message threads, reviews, scheduling and calendar
                    information, and other information or materials available on or through the Platform.
                </p>
                <p className='leading-loose'>
                    "Consumer" means a User who is registered to search for Service Professionals
                    on the Platform, seeks Pro Services from Service Professionals on the Platform, makes a
                    booking with a Service Professional to purchase a Pro Service (or receive a quote in
                    contemplation thereof), is registered for or undergoes a home specialist consultation or
                    similar service with respect to their home, utilizes a Audtiklance plan to track
                    maintenance on a home, is a Audtiklance Plus member, or otherwise uses the Platform to
                    receive, pay for, review, or facilitate the receipt of Pro Services. Because a Consumer
                    may be, or may become, a customer of a Service Professional, a Consumer is often
                    referred to as a “customer” or a “Customer” on the Platform and
                    in marketing materials related to the Platform.
                </p>
                <p className='leading-loose'>"Platform" has the meaning set forth in the opening paragraph above.</p>
                <p className='leading-loose'>
                    "Pro Services" means the services listed, quoted, scheduled, fulfilled or
                    provided by Service Professionals, or sought, scheduled, paid for or received by
                    Consumers, through the Platform.
                </p>
                <p className='leading-loose'>
                    "Service Professional" means a User who uses, or is registered to use, the
                    Platform to offer, provide, receive payment for, or facilitate the provision of Pro
                    Services. Service Professionals are often referred to as “pros” on the
                    Platform.
                </p>
                <p className='leading-loose'>
                    "Audtiklance Content" means all Content Audtiklance makes available on or through
                    the Platform, including any Content licensed from a third-party, but excluding User
                    Content.
                </p>
                <p className='leading-loose'>
                    "User" means a person or entity who uses the Platform, including, for example,
                    a person who completes Audtiklance's account registration process, agrees to the Terms
                    of Use, utilizes any services offered by or through the Platform, or submits or receives
                    a request through Audtiklance, including but not limited to Service Professionals and
                    Consumers.
                </p>
                <p className='leading-loose'>
                    "User Content" means all Content submitted, posted, uploaded, published, or
                    transmitted on or through the Platform by any User of the Platform, including but not
                    limited to photographs, profile information, descriptions, postings, reviews, requests,
                    messages, and payments made through the Platform, but excluding Audtiklance Content and
                    <a href="/terms/#feedback">Feedback</a>.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium '>INFORMATION WE COLLECT</h2>
                <p className='leading-loose'>
                    How we collect and store information depends on how you access and use the Platform. We
                    collect information in multiple ways including when you provide information directly to
                    us, when we collect information from affiliates and non-affiliated sources, and when we
                    passively collect information from you, such as information collected from your browser
                    or device.
                </p>
                <p className='leading-loose'><em>Information You Provide Directly to Us</em></p>
                <p className='leading-loose'>
                    You may provide us with information about you when you use or access the Platform, such
                    as when you register for an account; participate in polls or surveys; enroll for
                    electronic newsletters; request a quote or other information; submit or respond to a
                    quote; make a purchase; fill out any forms; enter a sweepstakes or contest, or register
                    for a promotion; transmit User Content; download or use one of our mobile applications;
                    you consent to our collection of information, such as when you grant us permission to
                    collect your device’s contact list; or otherwise communicate with us or other
                    Users through the Platform. This list is illustrative, not exhaustive; this Policy
                    applies to all your uses of the Platform.
                </p>
                <p className='leading-loose'>
                    The personal information you provide directly to us may include, but is not limited to:
                    (a) name; (b) zip p; (c) email address; (d) home or business telephone number; (e)
                    home, business or mailing address; (f) demographic information (e.g., gender, age, and
                    other information relevant to User surveys and/or offers); (g) date of birth and/or age
                    verification; (h) insurance information (if you are a Service Professional); (i)
                    photographs and/or video files or audio files (e.g., customer service call recordings);
                    (j) information about your project, home, request or need; (k) in certain circumstances,
                    payment information; (l) if you are a Service Professional, in certain circumstances,
                    identity verification information (such as Social Security number or driver’s
                    license information); and/or (m) any other information you include in communications
                    with other Users through the Platform or communications with us. It may also include
                    your information specific to the services you are requesting or offering through the
                    Platform, such as a business name, service description, qualifications and credentials.
                    You are not required to provide us with such information, but certain features of the
                    Platform may not be accessible or available, absent the provision of the requested
                    information.
                </p>
                <p className='leading-loose'>
                    We utilize a third-party payment provider to process payment information (e.g., credit
                    card information or bank account information).
                </p>
                <p className='leading-loose'>
                    <em>Information from Affiliates, Social Networking Sites, and Other Non-Affiliated
                        Sources</em>
                </p>
                <p className='leading-loose'>
                    We may collect information about you through Audtiklance affiliates or through
                    non-affiliated sources, including public information sources. For example:
                </p>
                <ul>
                    <li className='mt-4'>
                        <p>You may be able to access the Platform through a social networking account, such as
                            Facebook. If you access the Platform through your Facebook account, you may allow us
                            to have access to certain information in your Facebook profile. This may include
                            your name, profile picture, gender, networks, user IDs, list of friends, location,
                            date of birth, email address, photos, videos, people you follow and/or who follow
                            you, and/or your posts or "likes." Social networking sites, such as
                            Facebook, have their own policies for handling your information. For a description
                            of how these sites may use and disclose your information, including any information
                            you make public, please consult those sites' privacy policies.</p>
                    </li>
                </ul>
                <ul>
                    <li className='mt-4'>
                        <p>To the extent permitted by law, we may, in our sole discretion, ask for and collect
                            supplemental information about you from non-affiliated sources, such as information
                            about your credit from a credit bureau, or information to verify your identity or
                            trustworthiness, or for other fraud or safety protection purposes. We may combine
                            information that we collect from you through the Platform with information that we
                            obtain from such sources, including public information sources and information
                            derived from any other products or services we provide.</p>
                    </li>
                </ul>
                <ul>
                    <li className='mt-4'>
                        <p>We may collect information that demonstrates the occurrence of an off-Platform
                            communication between a Consumer and a Service Professional in order to ensure
                            accurate charging of fees imposed upon Service Professionals and to enforce the
                            Terms of Use. Except as explicitly stated herein, we will not collect or store the
                            contents of any off-Platform communication between a Consumer and a Service
                            Professional.</p>
                    </li>
                </ul>
                <ul>
                    <li className='mt-4 mb-6'>
                        <p> If you send text messages with a Consumer or Service Professional using the
                            telephone number for that User available on the Platform, we may use a third-party
                            vendor to track these text messages. We track these text messages for fraud
                            prevention, to ensure appropriate charging of fees, to enforce our Terms of Use, and
                            for quality and training purposes. As part of this process, Audtiklance and the
                            third-party vendor will receive in real time and store data about your text message,
                            including the date and time of the text message, your phone number, and the content
                            of the text message.</p>
                    </li>
                </ul>
                <hr />
                <br />
                <p className='leading-loose'>
                    Please note that we have no control over how any third-party site uses or discloses the
                    information it collects about you.
                </p>
                <p className='leading-loose'><em>Information That Is Passively or Automatically Collected</em></p>
                <p className='leading-loose'>
                    <em>Device/Usage Information.</em> We and certain third-party vendors, including ad
                    networks and analytics providers, may use cookies, web beacons, and other tracking
                    technologies to collect information about the computers or devices (including mobile
                    devices) you use to access the Platform. As described further below, we and these
                    third-party vendors may collect and analyze information including but not limited to (a)
                    browser type; (b) ISP or operating system; (c) domain name; (d) access time; (e)
                    referring or exit pages; (f) page views; (g) IP address; (h) unique device identifiers
                    (e.g. IDFA or Android ID); and (i) the type of device that you use. We may also track
                    and record when and how frequently you access or use the Platform, or how you browse the
                    Platform. We do not, however, collect keystroke information. We use this information
                    (including the information collected by relevant third-party vendors) for Platform
                    analytics (including to determine which portions of the Platform are used most
                    frequently and what our Users like/do not like), to assist in determining relevant
                    advertising (both on and off the Platform), to evaluate the success of our advertising
                    campaigns, and as otherwise described in this Policy.
                </p>
                <p className='leading-loose'>
                    <em>Location Information.</em> When you use the Platform, we may collect general
                    location information (such as your location as derived from your IP address). If you
                    install our mobile app, depending on your device settings and any permissions you have
                    granted, we may collect information about your precise geolocation, and we may use that
                    information to improve the Platform, including providing you with location-based
                    features (e.g., for identification of Pro Services available near you). To deliver
                    customized content and advertising, we may disclose your location information to our
                    agents, vendors, or advertisers. If you access the Platform through a mobile device and
                    you do not want your device to provide us with location-tracking information, you may be
                    able to disable the GPS or other location-tracking functions on your device. See your
                    device manufacturer's instructions for further details. If you disable certain
                    functions, you may be unable to use certain parts of the Platform.
                </p>
                <p className='leading-loose'>
                    <em>Cookies and Other Electronic Technologies.</em> We and our third-party vendors may
                    use cookies, clear GIFs, pixel tags, and other technologies that help us better
                    understand User behavior, personalize preferences, perform research and analytics, and
                    improve the Platform. These technologies, for example, may allow us to tailor the
                    Platform to your needs, save your password in password-protected areas, track the pages
                    you visit, help us manage content, and compile statistics about Platform usage. We also
                    use certain of these technologies to deliver advertisements through the Platform that
                    may interest you or to advertise ourselves and the Platform to you on other sites. We or
                    our third-party vendors also may use certain of these technologies in emails to our
                    Users to help us track email response rates, identify when our emails are viewed, and
                    track whether our emails are forwarded.
                </p>
                <p className='leading-loose'>
                    You can choose to accept or decline cookies. Most web browsers automatically accept
                    cookies, but your browser may allow you to modify your browser settings to decline
                    cookies if you prefer. If you disable cookies, you may be prevented from taking full
                    advantage of the Platform, because it may not function properly. As we adopt additional
                    technologies, we may also gather additional information through other methods.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>HOW WE USE YOUR INFORMATION</h2>
                <p className='leading-loose'>We may use your information for any of the following reasons:</p>
                <ul className='mb-8'>
                    <li className='mt-4'><p>For the purposes for which you provided it;</p></li>
                    <li className='mt-4'>
                        <p>To enable you to use the services available through the Platform, including
                            registering you for our services and verifying your identity and authority to use
                            our services;</p>
                    </li>
                    <li className='mt-4'><p>For customer support and to respond to your inquiries;</p></li>
                    <li className='mt-4'><p>For internal record-keeping purposes;</p></li>
                    <li className='mt-4'><p>To administer surveys, sweepstakes, promotions, or contests;</p></li>
                    <li className='mt-4'>
                        <p>To track fees and process billing and payment including sharing with third-party
                            payment gateways and payment vendors in connection with the Platform;</p>
                    </li>
                    <li className='mt-4'><p>To improve and maintain the Platform and for product development;</p></li>
                    <li className='mt-4'>
                        <p>To address fraud or safety concerns, or to investigate complaints or suspected fraud
                            or wrongdoing;</p>
                    </li>
                    <li className='mt-4'>
                        <p>To market and advertise our services and the Platform, including to send promotional
                            emails regarding new products from Audtiklance, special offers from Audtiklance, or
                            other information that may interest you;</p>
                    </li>
                    <li className='mt-4'>
                        <p>As permitted under applicable law, to contact you by text message regarding certain
                            services or information you have requested;</p>
                    </li>
                    <li className='mt-4'>
                        <p>As permitted under applicable law, to contact you by telephone or text message
                            regarding Platform features, improvements, or other products and services that may
                            interest you</p>;
                    </li>
                    <li className='mt-4'>
                        <p>For Audtiklance's market research purposes, including, but not limited to, the
                            customization of the Platform according to your interests;</p>
                    </li>
                    <li className='mt-4'><p>To contact you with information about your use of the Platform;</p></li>
                    <li className='mt-4'><p>For other research and analytical purposes; and</p></li>
                    <li className='mt-4'>
                        <p>To resolve disputes, to protect ourselves and other Users of the Platform, or third
                            parties, including to protect property or safety; and to enforce any legal terms
                            that govern your use of the Platform.</p>
                    </li>
                </ul>
                <hr />
                <br />
                <p className='leading-loose'>
                    For Service Professionals, we may display information related to your business,
                    including your name or your business’s name, publicly on your profile, except to
                    the extent we tell you we will not display such information. If the information you
                    provide us upon signing up for an account differs from the information you provide us as
                    part of the verification data we collect, we have sole discretion in determining which
                    information will be displayed publicly on your profile.
                </p>
                <p className='leading-loose'>
                    We may combine information that we collect from you through the Platform with
                    information that we obtain from affiliated and non-affiliated sources, and information
                    derived from any other products or services we provide. We may aggregate and/or
                    de-identify information we collect. We may use de-identified or aggregated information
                    for any purpose, including without limitation for research and marketing purposes and
                    may also disclose such information to any third parties, including without limitation,
                    advertisers, promotional partners, sponsors, event promoters, and/or others.
                </p>
                <p className='leading-loose'>
                    We may, either directly or through third-party providers we engage to provide services
                    to us, review, scan, or analyze your communications with other Users exchanged via the
                    Platform or as otherwise described in this Policy for fraud prevention, risk assessment,
                    regulatory compliance, investigation, product development, research and User support
                    purposes. For example, as part of our fraud prevention efforts, we may scan and analyze
                    messages to prevent fraud or improper actions. We may also scan, review or analyze
                    messages for research and product development purposes, as well as to debug, improve and
                    expand product offerings. By using the Platform or engaging in off-Platform
                    communications tracked by Audtiklance, you consent that Audtiklance, in its sole discretion,
                    may, either directly or through third-parties we engage to provide services to us,
                    review, scan, analyze, and store your communications, whether done manually or through
                    automated means.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>HOW WE DISCLOSE YOUR INFORMATION</h2>
                <p className='leading-loose'>
                    Unless otherwise described in this Policy, we may also disclose the information that we
                    collect as follows:
                </p>
                <ul className='mb-8'>
                    <li className='mt-4'><p>Affiliates. We may disclose your information to any Audtiklance affiliates.</p></li>
                    <li className='mt-4'><p>Consent. We may disclose your information based on your consent to do so.</p></li>
                    <li className='mt-4'>
                        <p>Vendors. We may provide your information to select third parties who perform
                            services on our behalf. These third parties provide a variety of services to us
                            including without limitation billing, sales, marketing, advertising, market
                            research, fulfillment, data storage, analysis and processing, identity verification,
                            fraud and safety protection and legal services.</p>
                    </li>
                    <li className='mt-4'>
                        <p> Legal Requirements. We may disclose your information when required by law or when we
                            believe in good faith that such disclosure is necessary to: (a) comply with
                            subpoenas, court orders, or other legal process we receive; (b) establish or
                            exercise our legal rights including enforcing and administering agreements with
                            Users; or (c) defend Audtiklance against legal claims. While you may challenge the
                            disclosure request, please be advised we may still be legally required to turn over
                            your information.</p>
                    </li>
                    <li className='mt-4'>
                        <p>Protection of Audtiklance and Others. We may disclose your information when we believe
                            it appropriate to investigate, prevent, or take action regarding possible illegal
                            activities, suspected fraud, situations involving potential threats to the physical
                            safety of any person, violations of the Terms of Use or any other legal terms
                            governing use of the Platform, and/or to protect our rights and property and the
                            rights and property of others.</p>
                    </li>
                    <li className='mt-4'>
                        <p>Business Transfers. As we continue to develop our business, we may sell, buy, merge
                            or partner with other companies or businesses, or sell some or all our assets. In
                            such contemplated or actual transactions or where there is any change of control of
                            Audtiklance, your information may be among the disclosed and/or transferred assets.</p>
                    </li>
                    <li className='mt-4'>
                        <p>App Store Providers. We may provide your identity and mobile device identifier to
                            third-party app store providers (for example, the Apple App Store) to allow you to
                            download our mobile apps.</p>
                    </li>
                    <li className='mt-4'>
                        <p>Academics and Research. We may provide information about Users to third-parties for
                            academic and research purposes, in anonymized or aggregated form.</p>
                    </li>
                </ul>
                <hr />
                <br />
                <p className='leading-loose'>
                    You may post Content on or through our Platform, including, but not limited to, your
                    comments, pictures, Service Professional profile, and other information. However, please
                    be careful and responsible whenever you are online. Some information you provide and/or
                    that we collect may be publicly accessible. For instance, registration for an account
                    requires that you provide us with your name. If you register through Facebook, the
                    Platform will use the name associated with your Facebook account. Your name (full name,
                    or in some instances, your first name and last initial) may be visible to other Users.
                    Depending on the circumstances, your name may be attached to your Content or
                    information, such as scheduling of Pro Services, service requests, reviews,
                    participating in discussions or forums, messaging, and profile information. Certain
                    other people, including other Users with whom you have interacted via the Platform, may
                    see information about you that is attached to your name. For example (but without
                    limitation), if you are a Consumer seeking Pro Services, the description you provide of
                    your desired services, along with your name, will be shown to some Service Professionals
                    registered in the relevant category along with your name. Thus, other Users may be able
                    to personally identify you based on Content you provide. Similarly, certain information
                    we collect, including but not limited to when you last accessed the Platform, may be
                    disclosed to other Users with whom you are interacting or otherwise made public.
                </p>
                <p className='leading-loose'>
                    If you choose to post User Content on or through the Platform, such as through
                    User-to-User messaging or through our review boards, forums, blogs, or other postings,
                    that information: (a) may be or may become publicly available; (b) may be collected and
                    used by third-parties with or without our knowledge; and (c) may be used by third
                    parties (including other Users) in a manner that may violate this Policy or applicable
                    law.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>ONLINE ANALYTICS AND TAILORED ADVERTISING</h2>
                <p className='leading-loose'><em>Analytics</em></p>
                <p className='leading-loose'>
                    We may use third-party web analytics services on the Platform, such as those of Google
                    Analytics. Providers of these services use cookies and similar technologies (as
                    described in the Information That Is Passively or Automatically Collected section above)
                    to help us analyze how Users use the Platform, including by noting the third-party
                    website from which you arrive. The information collected by the technology may be
                    disclosed to or collected directly by these providers. We may also use Google Analytics;
                    to prevent Google Analytics from using your information for analytics, you may install
                    the
                    <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out Browser Add-on</a>.
                </p>
                <p className='leading-loose'><em>Tailored Advertising</em></p>
                <p className='leading-loose'>
                    We may use advertising services provided by third parties on the Platform, which provide
                    their services using cookies and similar technologies (as described in the Information
                    That Is Passively or Automatically Collected section above). Third parties whose
                    products or services are accessible or marketed via the Platform may also place such
                    technologies on your computer, mobile phone, or other device to collect information
                    about your use of the Platform. We, our providers, and third parties collect information
                    in this way for advertising and marketing purposes.
                </p>
                <p className='leading-loose'>
                    Certain third-party providers and certain cookies or similar technologies on the
                    Platform enable us and third parties to serve you “tailored” advertising
                    online that is more relevant to you and your interests. If you are interested in more
                    information about tailored browser advertising and how you can generally control cookies
                    from being put on your computer to deliver tailored advertising, you may visit the
                    <a href="http://www.networkadvertising.org/choices">Network Advertising Initiative's Consumer Opt-Out Link </a>and/or the
                    <a href="http://www.aboutads.info/choices">Digital Advertising Alliance's Consumer Opt-Out Link </a>to opt out of receiving tailored advertising from companies that participate in those
                    programs. To opt out of Google Analytics for Display Advertising or customize Google
                    Display Network ads, you can visit the
                    <a href="https://www.google.com/settings/ads">Google Ads Settings page</a>. We do not
                    control any of these opt-out mechanisms and are not responsible for any choices you make
                    using these mechanisms or the continued availability or accuracy of these mechanisms. If
                    your browsers are configured to reject cookies when you visit this opt-out page, or you
                    subsequently erase your cookies, use a different computer or change web browsers, your
                    NAI or DAA opt-out may no longer be effective. Additional information is available on
                    NAI's and DAA's websites.
                </p>
                <p className='leading-loose'>
                    Please note that if you opt out of tailored advertising, you may still receive online
                    advertisements about us, the Platform, or third parties on the Platform – however,
                    such advertising is not tailored.
                </p>
                <p className='leading-loose'>
                    When using a mobile application, you may also receive tailored in-application
                    advertising. Each operating system (e.g., iOS for Apple devices, Android for Android
                    devices, and Windows for Microsoft devices) provides its own instructions on how to
                    prevent use of your mobile advertising identifier to deliver tailored in-application
                    advertising. For more information, you may review the support materials and/or the
                    privacy settings for the operating systems and devices you use, or contact the
                    applicable provider/operator.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>PRIVACY OF MINORS</h2>
                <p className='leading-loose'>
                    Our services are not provided to minors (i.e., persons under the age of 18). We elect to
                    provide services only to persons 18 years of age or older, including any use of the
                    Platform. If we discover, or if you inform us, that an individual under 18 has provided
                    us with personal information, we will close the account and delete that
                    individual’s personal information as required by law, including the Children's
                    Online Privacy Protection Act. We may, where permitted by law, retain certain
                    information internally for purposes described in this Policy.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>SECURITY</h2>
                <p className='leading-loose'>
                    We employ physical, procedural, and technological security measures to help protect your
                    information from unauthorized access or disclosure. Audtiklance may use encryption,
                    passwords, and physical security measures to help protect your information against
                    unauthorized access and disclosure. No security measures, however, are 100% failsafe.
                    Therefore, we do not promise and cannot guarantee, and thus you should not expect, that
                    any information provided or transmitted online is completely secure. You should take
                    steps to protect against unauthorized access to your password, phone, and computer by,
                    among other things, signing off after using a shared computer, choosing a robust
                    password that nobody else knows or can easily guess, keeping your log-in and password
                    private, and not recycling passwords from other websites or accounts. Audtiklance is not
                    responsible for the unauthorized use of your information nor for any lost, stolen, or
                    compromised passwords, or for any activity on your account via unauthorized password
                    activity.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>LINKS TO EXTERNAL PLATFORMS</h2>
                <p className='leading-loose'>
                    The Platform may contain links to third-party websites or resources over which Audtiklance
                    does not have any control; please review such other websites’ privacy policies as
                    to their use of personal information. Such links do not constitute an endorsement by
                    Audtiklance of those third-party websites. You acknowledge that Audtiklance is providing
                    these links to you only as a convenience, and further agree that Audtiklance is not
                    responsible in any way for such third-party websites, including without limitation for
                    their content or how they treat information you provide to them.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>YOUR CHOICES</h2>
                <p className='leading-loose'>
                    You may review, correct, and delete certain information about you through your account,
                    such as by logging in to the Platform and navigating to your preferences page in
                    "Your Dashboard." We expect you to promptly update your account information if
                    it changes or is inaccurate. Upon your request, we will close your account and remove
                    your profile information from view as soon as reasonably possible. We may retain
                    information from closed accounts in order to comply with the law, prevent fraud, collect
                    any fees owed, resolve disputes, troubleshoot problems, assist with any investigations
                    of any User, enforce our Terms of Use, and/or for any other purposes otherwise permitted
                    by law that we deem necessary in our sole discretion. You should understand, however,
                    that once you transmit User Content through the Platform, it may be acquired by others
                    who may not be required to delete or modify such User Content. Once we have deactivated
                    or removed your account, you agree that Audtiklance will not be responsible for retaining
                    information related to your account.
                </p>
                <p className='leading-loose'>
                    We may send periodic emails to you. You may opt out of promotional emails by following
                    the opt-out instructions contained in the email. Please note that it may take up to 10
                    business days for us to process opt-out requests. If you opt out of receiving
                    promotional emails, we may still send you emails about your account or any services you
                    have requested or received from us, transactions, or changes to our Terms of Use, this
                    Policy, or other relevant terms.
                </p>
                <p className='leading-loose'>
                    We may monitor and record our telephone conversations with you for training and quality
                    assurance purposes. You will be provided with a notice at the beginning of any call that
                    is being recorded.
                </p>
                <p className='leading-loose'>
                    If you are a resident of certain states, you may have certain rights regarding your
                    information.
                </p>
                <ul>
                    <li className='mt-4'>
                        <p>Residents of the state of California: See our
                            <a href="#supplemental-privacy-notice-for-california-residents">Supplemental Privacy Notice for California Residents </a>
                            below.</p>
                    </li>
                </ul>
                <ul>
                    <li className='mt-4'>
                        <p> Residents of Virginia, Colorado, Connecticut, and certain other states: See our
                            <a href="#supplemental-privacy-notice-for-residents-of-other-u.s.-states">Supplemental Privacy Notice for Other U.S. States </a>
                            below.</p>
                    </li>
                </ul>
                <h2 className='mt-10 mb-4 text-xl font-medium'>CHANGES TO THIS POLICY</h2>
                <p className='leading-loose'>
                    THIS POLICY IS CURRENT AS OF THE EFFECTIVE DATE SET FORTH ABOVE. Audtiklance MAY, IN ITS
                    SOLE AND ABSOLUTE DISCRETION, CHANGE THIS POLICY AT ANY TIME. Audtiklance WILL POST ITS
                    UPDATED POLICY ON THE PLATFORM, SEND YOU A MESSAGE OR OTHERWISE NOTIFY YOU WHEN YOU ARE
                    LOGGED INTO YOUR ACCOUNT. Audtiklance ENCOURAGES YOU TO REVIEW THIS POLICY REGULARLY FOR
                    ANY CHANGES. YOUR CONTINUED ACCESS TO OR USE OF THE PLATFORM WILL BE SUBJECT TO THE
                    TERMS OF THE THEN-CURRENT POLICY.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>CONSENT TO TRANSFER</h2>
                <p className='leading-loose'>
                    Our computer systems are currently based in the United States and Canada, so your
                    information will be processed by us in the United States and Canada, where data
                    protection and privacy regulations may not offer the same level of protection as in your
                    country of residence and data may be accessible to law enforcement and national security
                    authorities under certain circumstances. If you create an account with the Platform as a
                    visitor from outside the United States, by using the Platform, you agree to this Policy
                    and you consent to the processing of all such information in the United States and
                    Canada.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>CONTACT US</h2>
                <p className='leading-loose'>
                    If you have any questions about this Policy or the Platform, please contact us by
                    sending an email to <a href="mailto:support@audtiklance.com">support@audtiklance.com</a>, or
                    by writing to: Audtiklance, Inc., 415 Natoma Street, Suite 1300, San Francisco, California
                    94103.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium' id="supplemental-privacy-notice-for-california-residents">
                    SUPPLEMENTAL PRIVACY NOTICE FOR CALIFORNIA RESIDENTS
                </h2>
                <p className='leading-loose'>
                    This Supplemental Privacy Notice for California Residents supplements the information in
                    the Policy above, and except as provided herein, applies solely to California residents
                    (as defined by the California Consumer Privacy Act (as amended, the
                    “CCPA”)). It applies to “personal information” (as defined by
                    the CCPA) that we collect on or through the Platform and through other means (such as
                    information collected offline, in person, and over the telephone). It does not apply to
                    business information that does not constitute personal information we collect from
                    Service Professionals in their capacity as Service Professionals. This Supplemental
                    Privacy Notice for California Residents also does not apply to current or former
                    employees of Audtiklance, Inc., independent contractors, or job applicants to jobs at
                    Audtiklance, Inc.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>How We Collect, Use, and Disclose Personal Information</h2>
                <p className='leading-loose'>
                    The CCPA requires us to disclose information regarding the categories of personal
                    information and sensitive personal information that we have collected about California
                    consumers, the categories of sources from which we collect personal information, the
                    business or commercial purposes (as each of those terms is defined by the CCPA) for
                    which we collect personal information, and the categories of parties to whom we disclose
                    personal information.
                </p>
                <p className='leading-loose'>
                    Throughout this Policy, we describe the specific pieces of personal information and
                    sensitive personal information we collect, the sources of that information, and how we
                    disclose it. Under the CCPA, we also have to provide you with the "categories"
                    of personal information and sensitive personal information we collect and disclose for
                    “business or commercial purposes” (as those terms are defined by the CCPA).
                </p>
                <p className='leading-loose'>
                    As described further in the Policy above, the following table provides information about
                    our practices in the 12 months leading up to the effective date of this Policy in
                    relation to the categories of personal information that we generally collect from
                    California and Colorado residents, the sources of the information, the purposes for
                    which we use the information, and the categories of third parties to whom we disclose
                    personal information for a business purpose.
                </p>
                <table id="privacy-policy-table" className="hidden w-full my-8 text-left border border-gray-300 md:block">
                    <thead>
                        <tr className="bg-gray-200 border-b border-gray-300">
                            <th className="px-4 py-2 border-r border-gray-300">
                                Category of Personal Information Collected
                            </th>
                            <th className="px-4 py-2 border-r border-gray-300">
                                Categories of Sources of Personal Information
                            </th>
                            <th className="px-4 py-2 border-r border-gray-300">Purposes of Use</th>
                            <th className="px-4 py-2">
                                Categories of Other Parties to Whom We Disclose this Personal Information
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Identifiers (such as account information, name, email address, address,
                                    phone number, or social network account and profile data)
                                </p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>
                                    Your use of our services and the automatic collection from you that your
                                    use prompts
                                </p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>
                                    Unaffiliated Parties (such as agents/service providers, or other users)
                                </p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to enable you to find a Service
                                    Professional to provide services to you
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Personalizing content</p>
                                <p className='leading-loose'>Marketing and advertising</p>
                                <p className='leading-loose'>Administering surveys, sweepstakes, promotions, or contests</p>
                                <p className='leading-loose'>Communicating with you</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, breaches or potential breaches
                                    of terms and policies
                                </p>
                                <p className='leading-loose'>Running internal trainings of Audtiklance personnel</p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>
                                    Service Professionals (pursuant to a request by you for professional
                                    services)
                                </p>
                                <p className='leading-loose'>Consumers</p>
                                <p className='leading-loose'>Other parties, including potential users</p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>Commercial information (such as transaction data)</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>
                                    Your use of our services and the automatic collection from you that your
                                    use prompts
                                </p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>
                                    Unaffiliated Parties (such as agents/service providers, Service
                                    Professionals, or other users)
                                </p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to enable you to find a Service
                                    Professional to provide services to you
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Personalizing content</p>
                                <p className='leading-loose'>Marketing and advertising</p>
                                <p className='leading-loose'>Administering surveys, sweepstakes, promotions, or contests</p>
                                <p className='leading-loose'>Communicating with you</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, or breaches or potential
                                    breaches of terms and policies
                                </p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>
                                    Service Professionals (pursuant to a request by you for professional
                                    services)
                                </p>
                                <p className='leading-loose'>Consumers</p>
                                <p className='leading-loose'>Other parties, including potential users</p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>Financial data (such as payment information)</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>Affiliates</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to facilitate communications between
                                    you and Service Professionals
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, or breaches or potential
                                    breaches of terms and policies
                                </p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers; payment processors</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>
                                    Service Professionals (e.g. where limited information is conveyed when
                                    you make a payment on the Platform)
                                </p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Internet or other network or device activity (such as IP address, unique
                                    device, advertising, and app identifiers, browsing history or other
                                    usage data)
                                </p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>
                                    Your use of our services and the automatic collection from you that your
                                    use prompts
                                </p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Unaffiliated Parties (such as agents/service providers)</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to enable you to find a Service
                                    Professional to provide services to you
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Personalizing content</p>
                                <p className='leading-loose'>Marketing and advertising</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, or breaches or potential
                                    breaches of terms and policies
                                </p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Other parties</p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Location information (general location, and, depending on your device
                                    settings and permissions, precise geolocation information)
                                </p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>
                                    Your use of our services and the automatic collection from you that your
                                    use prompts
                                </p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Unaffiliated Parties (such as agents/service providers)</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to enable you to find a Service
                                    Professional to provide services to you
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Personalizing content</p>
                                <p className='leading-loose'>Marketing and advertising</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, or breaches or potential
                                    breaches of terms and policies
                                </p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Service Professionals (general location only)</p>
                                <p className='leading-loose'>Consumers (general location only)</p>
                                <p className='leading-loose'>Other parties, including potential users (general location only)</p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Sensory information (such as audio recordings if you call our customer
                                    service)
                                </p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Unaffiliated Parties (such as agents/service providers)</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to enable you to find a Service
                                    Professional to provide services to you
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, or breaches or potential
                                    breaches of terms and policies
                                </p>
                                <p className='leading-loose'>Running internal trainings of Audtiklance personnel</p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Other parties</p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>Inferences about your project preferences and traits</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>Your use of our services/automatically collected from you</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Unaffiliated Parties (such as agents/service providers)</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to enable you to find a Service
                                    Professional to provide services to you
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Personalizing content</p>
                                <p className='leading-loose'>Marketing and advertising</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, or breaches or potential
                                    breaches of terms and policies
                                </p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Other parties</p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Other information that identifies or can be reasonably associated with
                                    you (such as user generated content, e.g., reviews)
                                </p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                                <p className='leading-loose'>Your use of our services/automatically collected from you</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>Unaffiliated Parties (such as agents/service providers)</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>
                                    Providing our services, including to enable you to find a Service
                                    Professional to provide services to you
                                </p>
                                <p className='leading-loose'>Fixing and improving our services</p>
                                <p className='leading-loose'>Personalizing content</p>
                                <p className='leading-loose'>Marketing and advertising</p>
                                <p className='leading-loose'>Administering surveys, sweepstakes, promotions, or contests</p>
                                <p className='leading-loose'>Communicating with you</p>
                                <p className='leading-loose'>Analyzing use of our services</p>
                                <p className='leading-loose'>
                                    Preventing, detecting, investigating, and responding to fraud,
                                    unauthorized access/use of our services, or breaches or potential
                                    breaches of terms and policies
                                </p>
                                <p className='leading-loose'>Running internal trainings of Audtiklance personnel</p>
                                <p className='leading-loose'>With your consent</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Agents/service providers</p>
                                <p className='leading-loose'>Affiliates</p>
                                <p className='leading-loose'>
                                    Service Professionals (e.g. where you provide the information to Service
                                    Professionals through the Platform)
                                </p>
                                <p className='leading-loose'>Consumers</p>
                                <p className='leading-loose'>Other parties, including potential users</p>
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>Social Security number and/or driver’s license information</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>You</p>
                            </td>
                            <td className="px-4 py-2 border-r border-gray-300">
                                <p className='leading-loose'>Verifying Service Professionals’ identity</p>
                                <p className='leading-loose'>Legal compliance</p>
                            </td>
                            <td className="px-4 py-2">
                                <p className='leading-loose'>Background check/identity verification service provider</p>
                                <p className='leading-loose'>For legal/safety purposes</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br className='block md:hidden' />
                <br className='block md:hidden' />
                <p className='leading-loose'>
                    Some of the categories of personal information described above are also “sensitive
                    personal information,” as that term is defined in the CCPA. The sensitive personal
                    information we may have collected in the preceding twelve months are: (1) account log-in
                    and password or other credentials allowing access to your account; (2) certain
                    user-to-user communications made through our Platform, including Member-to-Member text
                    messages; (3) precise geolocation; (4) Social Security number; and (5) driver’s
                    license number. The CCPA provides you with the right to limit certain uses and
                    disclosures of your sensitive personal information. You may limit the use of precise
                    geolocation for advertising purposes by opting out of the “sale” or
                    “sharing” of such information, as discussed below. We do not use or disclose
                    any other sensitive personal information for purposes that are subject to the right to
                    limit.
                </p>
                <p className='leading-loose'>
                    We may combine the information we collect (“aggregate”) or remove pieces of
                    information (“de-identify”) to limit or prevent identification of any
                    particular User or device. We may use or disclose this aggregated or de-identified
                    information in our discretion.
                </p>
                <h3 className='mt-10 mb-4 text-xl font-medium'>Your CCPA Rights</h3>
                <p className='leading-loose'>
                    If you are a California resident (as defined by the CCPA), you may have the right to
                    request that we:
                </p>
                <ul className='mb-6'>
                    <li className='mt-4'>
                        <p>Provide you the categories of personal information we have collected or disclosed
                            about you; the categories of sources of such information; the business or commercial
                            purpose for collecting, “sharing” or “selling” your personal
                            information as those terms are defined by the CCPA; the categories of third parties
                            to whom we disclose or “sell” or with whom we “share”
                            personal information; and the categories of personal information we
                            “sell” or “share.”</p>
                    </li>
                    <li className='mt-4'>
                        <p>Provide access to and/or a copy of certain personal information we have about you.</p>
                    </li>
                    <li className='mt-4'><p>Delete certain personal information we have about you.</p></li>
                    <li className='mt-4'><p>Correct inaccurate personal information we have about you.</p></li>
                </ul>
                <hr />
                <br />
                <p className='leading-loose'>
                    You may have the right to receive information about the financial incentives that we
                    offer to you, if any. You also have the right to not be discriminated against (as
                    provided for in California law) for exercising certain of your rights referenced herein.
                    Certain information may be exempt from such requests under applicable law. In addition,
                    we need certain types of information so that we can provide our services to you. If you
                    ask us to delete it, you may no longer be able to access or use our services.
                </p>
                <p className='leading-loose'>
                    To exercise any of these rights, you may submit a request at
                    <a href="mailto:support@audtiklance.com">support@audtiklance.com</a> or visit
                    <a href="https://help.audtiklance.com/data-privacy">https://help.audtiklance.com/data-privacy</a>. You will be required to verify your identity before we are able to fulfill your
                    request, for instance by logging into the Audtiklance platform. You can also designate an
                    authorized agent to make a request on your behalf. To do so, you must provide us with
                    written authorization or a power of attorney, signed by you, for the agent to act on
                    your behalf. You may still need to verify your identity directly with us.
                </p>
                <p id="right-to-opt-out">
                    You also have the right to opt out of “sales” and “sharing” of
                    your personal information as described below.
                </p>
                <p className='leading-loose'>
                    <em>“Sales” of personal information.</em> Audtiklance may disclose certain
                    identifiers (like IP address and email address), internet or other electronic network
                    activity information (like information regarding an individual’s browsing
                    interactions on the Platform), and commercial information (like the fact that a browser
                    visited a page with home improvement tips) to certain analytics, advertising, and
                    marketing providers in ways that may be considered “sales” under the CCPA.
                    Audtiklance also discloses Consumers’ identifiers (like name) and general location
                    information (like zip p) to select Service Professionals to better enable those
                    Consumers to find a Service Professional who can provide a service the Consumer has
                    expressed interest in. Under the CCPA, these may be considered “sales” of
                    your personal information.
                </p>
                <p className='leading-loose'>
                    <em>“Sharing” personal information.</em> Under the CCPA,
                    “sharing” is defined as the targeting of advertising to a consumer based on
                    that consumer’s personal information obtained from the consumer’s activity
                    across websites. We “share” information for these purposes to provide more
                    relevant and tailored advertising to you regarding our Platform. As part of this
                    advertising, we may “share” certain identifiers (like IP address and email
                    address), internet or other electronic network activity information (like information
                    regarding an individual’s browsing interactions on the Platform), and commercial
                    information (like the fact that a browser visited a page with home improvement tips)
                    with advertising platforms and advertising networks.
                </p>
                <p className='leading-loose'>
                    To opt out of our “sale” and “sharing” of your personal
                    information as described above, you may follow the instructions on our
                    “sale” and “sharing” opt-out page
                    <a href="/syndication-opt-out">here</a>.
                </p>
                <p className='leading-loose'>
                    Please note that we also respond to and abide by opt-out preference signals sent through
                    the Global Privacy Control. Any opt-out preferences you have exercised through these
                    methods will only apply to the specific device/browser on which you made them. For more
                    information on how to use the Global Privacy Control, see
                    <a href="https://globalprivacycontrol.org">https://globalprivacycontrol.org</a>.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>Retention of Your Personal Information</h2>
                <p className='leading-loose'>
                    We will retain your personal information for as long as reasonably necessary to provide
                    the Platform and our services or until you delete or modify your information. We also
                    reserve the right to retain and use your information as necessary to comply with our
                    legal obligations, resolve disputes, and enforce our agreements.
                </p>
                <h3 className='mt-10 mb-4 text-xl font-medium'>California Shine the Light Disclosure</h3>
                <p className='leading-loose'>
                    If you are a California resident, you may ask for a list of third parties that have
                    received your information for direct marketing purposes during the previous calendar
                    year. If we have disclosed your information, this list will contain the types of
                    information disclosed, and we will provide this list at no cost. To make such a request,
                    contact us at
                    <a href="mailto:support@audtiklance.com">support@audtiklance.com</a>.
                </p>
                <h3>California Do-Not-Track Disclosure</h3>
                <p className='leading-loose'>
                    Audtiklance is committed to providing you with meaningful choices about the information
                    collected on our Platform for third-party purposes. That is why we have provided links
                    (above) to the NAI "Consumer Opt-Out" link, the DAA opt-out link, and a Google
                    opt-out link. However, Audtiklance does not currently recognize or respond to
                    browser-initiated Do-Not-Track signals, as the Internet industry is currently still
                    working on Do-Not-Track standards, implementations, and solutions. Please note that
                    Do-Not-Track is a different privacy mechanism than the Global Privacy Control browser
                    choice referenced above.
                </p>
                <h2 id="supplemental-privacy-notice-for-residents-of-other-u.s.-states">
                    SUPPLEMENTAL PRIVACY NOTICE FOR RESIDENTS OF OTHER U.S. STATES
                </h2>
                <p className='leading-loose'>
                    Residents of certain other states, such as Colorado, Virginia, and Connecticut, may have
                    certain rights regarding their “personal data,” as defined in these
                    states’ laws (“personal information”), that we process.
                </p>
                <p className='leading-loose'>
                    Residents of Colorado may click <a href="#privacy-policy-table">here</a> to view more
                    information regarding each category of personal information we process (including
                    sensitive data (called “sensitive personal information” above)), the sources
                    of such information, the purposes for which we process each category of personal
                    information, and the categories of entities to whom the personal information is
                    disclosed. Residents of Colorado may click <a href="#right-to-opt-out">here</a> to view
                    more information about the categories of personal information that are
                    “sold” or used for “targeted advertising” (as defined in
                    Colorado law). Please note that we do not engage in profiling that produces legal or
                    similarly significant effects.
                </p>
                <p className='leading-loose'>Your state law may permit you to request that we:</p>
                <ul className='mb-6'>
                    <li className='mt-4'>
                        <p>Confirm whether or not we are processing your personal information and provide you
                            with access to such personal information;</p>
                    </li>
                    <li className='mt-4'>
                        <p>Correct inaccuracies in your personal information, taking into account the nature of
                            the personal information and the purposes of the processing of the personal
                            information;</p>
                    </li>
                    <li className='mt-4'><p>Delete your personal information;</p></li>
                    <li className='mt-4'>
                        <p> Provide you a copy of personal information that you previously provided to us in a
                            portable and, to the extent technically feasible, readily usable format that allows
                            you to transmit the data to another business, where our processing is carried out by
                            automated means; and</p>
                    </li>
                    <li className='mt-4'>
                        <p>Opt you out of processing of personal information for purposes of profiling in
                            furtherance of decisions that produce legal or similarly significant effects
                            concerning you (please note that we do not engage in this kind of processing).</p>
                    </li>
                </ul>
                <hr />
                <br />
                <p className='leading-loose'>
                    Certain information may be exempt from such requests under applicable law. For example,
                    we need certain types of information so that we can provide the Platform to you. If you
                    ask us to delete it, you may no longer be able to access or use the Platform.
                </p>
                <p className='leading-loose'>
                    To exercise any of these rights, you may submit a request at
                    <a href="mailto:support@audtiklance.com">support@audtiklance.com</a> or visit
                    <a href="https://help.audtiklance.com/data-privacy">https://help.audtiklance.com/data-privacy</a>
                    You will be required to verify your identity before we fulfill your request. To do so,
                    you will need to provide the information identified in the webform or as otherwise
                    directed by us.
                </p>
                <p className='leading-loose'>
                    You also have the right to opt out of the processing of the “sale” of your
                    personal information and the processing of your personal information for purposes of
                    “targeted advertising,” as defined in applicable law. Click
                    <a href="#right-to-opt-out">here</a> for more information about the categories of
                    personal information that are “sold” or used for “targeted
                    advertising” (also called “sharing” in some states).
                </p>
                <p className='leading-loose'>
                    Applicable law may also permit you to appeal any denials of your rights requests; to do
                    so, contact us at
                    <a href="mailto:support@audtiklance.com">support@audtiklance.com</a> and describe in
                    detail why you believe the denial was in error. Your description must include your full
                    name and the email address used for your account with us, along with a copy of the
                    denial notice you received from us.
                </p>
                <h2 className='mt-10 mb-4 text-xl font-medium'>NOTICE FOR NEVADA RESIDENTS</h2>
                <p className='leading-loose'>
                    Under Nevada law, certain Nevada consumers may opt out of the sale of “personally
                    identifiable information” for monetary consideration. “Personally
                    identifiable information” includes first and last name, address, email address,
                    phone number, Social Security Number, or an identifier that allows a person to be
                    contacted either physically or online.
                </p>
                <p className='leading-loose'>
                    We do not engage in such activity; however, if you are a Nevada resident who has
                    purchased or leased goods or services from us, you may submit a request to opt out of
                    any potential future sales under Nevada law by
                    <a href="mailto:support@audtiklance.com">support@audtiklance.com</a>. Please note
                    we will take reasonable steps to verify your identity and the authenticity of the
                    request. Once verified, we will maintain your request in the event our practices change.
                </p>
            </div>
        </div>
    )
}
