import { create } from "zustand";
import { IStore, createCommonStore } from "./commonStore";

// Define the Zustand store using the common store and adding state modifiers
export const useFormStore = create<IStore>((set) => ({
  ...createCommonStore(),

  // Method to set selected data
  setSelected: (selected) =>
    set((state) => ({
      ...state,
      selected: {
        ...selected,
        type: selected.type, // Ensure type safety (DeviceType)
      },
    })),

  // Method to reset selected data (onClose)
  onClose: () =>
    set((state) => ({
      ...state,
      selected: {
        type: "desktop", // Reset to default value
        open: "",
        data: {},
      },
    })),
}));
