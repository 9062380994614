import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getServiceProviderProfile } from "services";

export const useGetServiceProfile = () => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("searchTerm") || "";
  const category = searchParams.get("category") || "";

  const { isLoading, data } = useQuery({
    queryKey: [
      "service-provider-profiles",
      {
        search: searchTerm,
        category,
      },
    ],
    queryFn: getServiceProviderProfile,
  });

  return { serviceProfileLoading: isLoading, serviceProfileData: data };
};
