import React, { FC } from "react";

export interface ProfileCreationProgressBarProps {
  stage: number;
}

export const ProfileCreationProgressBar: FC<
  ProfileCreationProgressBarProps
> = ({ stage = 1 }) => {
  return (
    <div className="flex items-center content-end gap-2">
      <div className="flex items-center gap-1">
        <div
          className={`size-[35px] text-center justify-center rounded-full flex font-bold items-center ${
            stage >= 1 ? "bg-[#007BD4]" : "bg-[#EDEDEE]"
          }`}
        >
          <span className="text-white">1</span>
        </div>
        <p className="text-[14px]">Personal Information</p>
      </div>
      <div
        style={{
          height: "4px",
          width: "31px",
          background: stage >= 2 ? "#007BD4" : "#EDEDEE",
          borderRadius: "30px",
        }}
      />
      <div className="flex items-center gap-1">
        <div
          className={`size-[35px] text-center justify-center rounded-full flex font-bold items-center ${
            stage >= 2 ? "bg-[#007BD4]" : "bg-[#EDEDEE]"
          }`}
        >
          <span className="text-white">2</span>
        </div>
        <span className="text-[14px]">My Services</span>
      </div>
      <div
        style={{
          height: "4px",
          width: "31px",
          background: stage === 3 ? "#007BD4" : "#EDEDEE",
          borderRadius: "30px",
        }}
      />
      <div className="flex items-center gap-1">
        <div
          className={`size-[35px] text-center justify-center rounded-full flex font-bold items-center ${
            stage === 3 ? "bg-[#007BD4]" : "bg-[#EDEDEE]"
          }`}
        >
          <span className="text-white">3</span>
        </div>
        <span className="text-[14px]">Upload Media</span>
      </div>
    </div>
  );
};
