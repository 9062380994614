export type IQueryParams = {
	[key: string]: any;
};
export const queryParamsHelper = (queryParams: IQueryParams): string => {
	let queries = '?';

	for (let [key, value] of Object.entries(queryParams || {})) {
		if (!!key && value !== '' && value !== undefined) {
			if (key === 'page' && value === 0 && value !== undefined) {
				value = 1;
			}
			queries += `${key}=${value}&`;
		}
	}
	// eslint-disable-next-line no-unsafe-optional-chaining
	return queries?.substring(0, queries?.length - 1);
};
