import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { SetStateAction } from "react";
import { Button } from "ui/button";
import { ReactComponent as InfoIcon } from "assets/svg/info.svg";

export function ProductDetailsModal({
  img,
  open,
  setDrawer,
}: {
  img: string;
  open: boolean;
  setDrawer: React.Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Sheet open={open} onOpenChange={setDrawer}>
      <SheetContent>
        <SheetHeader className="flex flex-col items-center justify-center pt-10">
          <SheetTitle className="flex flex-col gap-2 items-center ">
            <img src={img} alt="" className="w-20 h-20 rounded-full" />
            <h2 className="text-slate-800">Sunil M</h2>
          </SheetTitle>
          <SheetDescription>
            <p>Brand Experience | Product Strategy for B2B</p>
            <p className="">4:00 am GMT+5:30 (30m ahead)</p>
          </SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4"></div>
        <SheetDescription>
          <div className="bg-slate-50 px-5 text-slate-800 flex flex-col gap-5">
            <div className="flex items-center gap-1">
              <InfoIcon />
              <p className="text-base">About the client</p>
            </div>

            <div>
              <p className="text-slate-900 text-lg font-normal">Balgian</p>
              <p className="text-gray-400 text-base">Indian</p>
            </div>
          </div>
        </SheetDescription>
      </SheetContent>
    </Sheet>
  );
}
