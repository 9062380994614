import { MessageSquare, Users } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { useAuthenticatedUser } from 'hooks';
import { useGetServiceProfileById } from 'hooks/profile/useGetServiceProfileById';
import { useGetConvoList } from 'hooks/services/useGetConvoList';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export const Profile = () => {
  const navigate = useNavigate()
  const { userDetails, isLoading } = useAuthenticatedUser();
  const { serviceProfileData, serviceProfileLoading } =
  useGetServiceProfileById(userDetails?.data?.service_profile as string);
  const {allChatList, isLoading: isConvoLoading} = useGetConvoList()
  
  if (serviceProfileLoading || isLoading || isConvoLoading) {
    return (
      <div className='w-full h-[90vh] flex justify-center items-center'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="p-8">
      <header className="flex items-center justify-between mb-8">
        <div>
          <h1 className="text-3xl font-bold">Welcome back, {userDetails?.data?.firstname}!</h1>
          <p className="text-muted-foreground">Here's what's happening with your business today.</p>
        </div>
      </header>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
            <CardTitle className="text-sm font-medium">Total Customers</CardTitle>
            <Users className="w-4 h-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="mt-2 text-2xl font-bold">1,234</div>
            {/* <p className="mt-3 text-xs text-muted-foreground">+10% from last month</p> */}
          </CardContent>
        </Card>
        <Card onClick={()=> navigate("/chat")} className='cursor-pointer'>
          <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
            <CardTitle className="text-sm font-medium">Messages</CardTitle>
            <MessageSquare className="w-4 h-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="mt-2 text-2xl font-bold">{allChatList?.results?.length}</div>
            {/* <p className="mt-3 text-xs text-muted-foreground">4 new since last login</p> */}
          </CardContent>
        </Card>
      </div>

      <div className="grid gap-4 mt-4 md:grid-cols-2 lg:grid-cols-7">
        <Card className="col-span-4">
          <CardHeader>
            <CardTitle>Recent Activities</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-8">
              {[
                { name: "Olivia Martin", action: "Completed appointment" },
                { name: "Jackson Lee", action: "Booked new appointment" },
                { name: "Isabella Nguyen", action: "Left a review" },
              ].map((activity, i) => (
                <div className="flex items-center" key={i}>
                  <Avatar className="h-9 w-9">
                    <AvatarImage src={`https://api.dicebear.com/6.x/initials/svg?seed=${activity.name}`} alt="Avatar" />
                    <AvatarFallback>{activity.name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
                  </Avatar>
                  <div className="ml-4 space-y-1">
                    <p className="text-sm font-medium leading-none">{activity.name}</p>
                  </div>
                  <div className="ml-auto text-[12px] font-normal">{activity.action}</div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
        <Card className="col-span-4 lg:col-span-3">
          <CardHeader>
            <CardTitle onClick={()=> navigate("/myservice")} className='cursor-pointer'>Current Services</CardTitle>
            <CardDescription>Your offered services and their details</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {serviceProfileData?.sub_category?.map((service) => (
                <div key={service.id} className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                  </div>
                  <div className="flex-grow">
                    <h3 className="text-sm font-medium">{service?.sub_category}</h3>
                    <p className="text-sm text-muted-foreground">{service?.cost}</p>
                  </div>
                  <div className="flex-shrink-0 text-right">
                    {/* <Badge variant="secondary">${service.price}</Badge> */}
                    <p className="mt-1 text-xs text-muted-foreground">{service?.time_frame}</p>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

