/* eslint-disable import/named */
import React from "react";
import { Control, Controller } from "react-hook-form";
import Select, { MultiValue, SingleValue } from "react-select";

export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  label?: string;
  options: Option[];
  placeholder?: string;
  className?: string;
  control?: Control<any>; // made control non-optional
  name: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  labelClass?: string;
}

const customStyles = {
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    cursor: "pointer",
    border: state.isFocused ? "1px solid #4188F9" : "none",
    boxShadow: state.isFocused ? "0 0 0 1px #4188F9" : "0 0 0 1px #E2E8F0",
    borderRadius: "10px",
    backgroundColor: "#F9FAFB",
    "&:hover": {
      border: state.isFocused ? "1px solid #4188F9" : "none",
      cursor: "pointer",
    },
    "&:placeholder": {
      fontSize: "10px",
    },
  }),

  input: (provided: any) => ({
    ...provided,
    cursor: "pointer",
    padding: "10px 0",
  }),

  placeholder: (provided: any) => ({
    ...provided,
    fontSize: "14px", // Adjust this value as needed
    color: "#aaaaaa",
    fontWeight: 300,
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    cursor: "pointer",
    "--tw-ring-color": "transparent",
    "--tw-ring-shadow": "none",
  }),
};

const CustomSelect: React.FC<SelectProps> = ({
  label,
  options,
  placeholder = "Select an option",
  className = "",
  control,
  isMulti,
  name,
  isSearchable,
  labelClass,
}) => (
  <div className={`flex flex-col w-full custom-select-override ${className}`}>
    {label && (
      <label
        className={`${
          labelClass ? labelClass : "font-bold text-gray-900 "
        } dark:text-white mb-2 text-sm `}
      >
        {label}
      </label>
    )}
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Select
            {...field}
            options={options}
            placeholder={placeholder}
            isSearchable={isSearchable}
            classNamePrefix="react-select"
            isMulti={isMulti}
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              border: "1px",
              padding: 6,
              colors: {
                ...theme.colors,
                primary25: "#E2E8F0",
                primary: "grey",
                padding: 6,
              },
            })}
            styles={customStyles}
            onChange={(selectedOption) => {
              if (isMulti) {
                const selectedValues =
                  (selectedOption as MultiValue<Option>)?.map(
                    (option) => option.value
                  ) ?? [];
                field.onChange(selectedValues);
              } else {
                field.onChange(
                  (selectedOption as SingleValue<Option>)?.value ?? ""
                );
              }
            }}
            value={
              isMulti
                ? options.filter((option) =>
                    (field.value || []).includes(option.value)
                  )
                : options.find((option) => option.value === field.value)
            }
          />
          {error && (
            <p className="mt-2 text-sm text-red-600">{error.message}</p>
          )}
        </>
      )}
    />
  </div>
);

export default CustomSelect;
