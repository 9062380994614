import React from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import downIcon from "../../assets/svg/down-arrow-black.svg"
import { otherServices, skills } from 'component/oldcomponent/OtherServices/data';
import { useNavigate } from 'react-router-dom';

function valuetext(value: number) {
    return `$${value}`;
  }

export const SearchFilter: React.FC<{num: any, setNum: any, value: any, handleChange: any, searchParams: string, categories: any}> = ({num, setNum, value, handleChange, searchParams, categories}) => {
    const navigate = useNavigate()
  
    const getSkill = (name: string) => {
        return categories?.find((category: any)=> category?.name === name)?.sub_categories || []
    }
    
    return (
        <div className={`lg:w-[27%] w-[100%] h-fit hidden lg:block lg:h-fit overflow-hidden rounded-2xl`} style={{ border: "1px solid #E2E8F0" }}>
            <div className='flex items-center justify-between px-6 py-4' style={{ borderBottom: "1px solid #E2E8F0" }}>Filter <button onClick={()=> navigate("/search")}>Reset</button></div>
            <div className='px-6 py-4' style={{ borderBottom: "1px solid #E2E8F0" }}>
                <p>Category</p>
                <div className='flex flex-wrap gap-3 mt-3'>
                    {
                        categories?.slice(0, num?.service)?.map((service: any, i: any) => (<span onClick={()=> navigate(`/search?searchTerm=${service?.name}`)} style={{ border: `1px solid ${searchParams === service?.name ? "blue" : "#E2E8F0"}` }} className='cursor-pointer py-[6px] px-[14px] text-[12px] rounded-[31px]' key={i}>{service?.name}</span>))
                    }
                </div>
                {num?.service < otherServices?.length && <h6 onClick={() => setNum((prev: { service: number; }) => ({ ...prev, service: prev.service + 10 }))} className='mt-4 text-[14px] underline cursor-pointer'>See More</h6>}
            </div>
            <div className='px-6 py-4' style={{ borderBottom: "1px solid #E2E8F0" }}>
                <p>Availability</p>
                <div className='mt-3'>
                    <div className='flex items-center gap-2'><input className='cursor-pointer' type="checkbox" name="avalaibilt1" id="avalaibilty1" /> <label className='text-[14px]' htmlFor="avalaibilty1">Morning (8am - 12pm)</label></div>
                    <div className='flex items-center gap-2 mt-2'><input className='cursor-pointer' type="checkbox" name="avalaibilt2" id="avalaibilty2" /> <label className='text-[14px]' htmlFor="avalaibilty2">Afternoon (12pm - 5pm)</label></div>
                    <div className='flex items-center gap-2 mt-2'><input className='cursor-pointer' type="checkbox" name="avalaibilt3" id="avalaibilty3" /> <label className='text-[14px]' htmlFor="avalaibilty3">Evening (5pm - 9:30pm)</label></div>
                </div>
                <div className='flex items-center text-[12px] gap-1 text-[#7A7B7F] mt-4'>
                    <div className='bg-[#EDEDEE] w-[20%] flex-grow h-[2px]'></div>
                    or choose a specific time
                    <div className='bg-[#EDEDEE] w-[20%] flex-grow h-[2px]'></div>
                </div>
                <div style={{ border: "1px solid #94A3B8" }} className="flex text-[12px] rounded-[50px] justify-between px-4 mt-4 items-center gap-2 w-full cursor-pointer h-[36px]">Within A Week <img src={downIcon} alt="" /></div>
            </div>
            <div className='px-6 py-4' style={{ borderBottom: "1px solid #E2E8F0" }}>
                <p>Skills</p>
                <div className='flex flex-wrap gap-3 mt-3'>
                    {
                        getSkill(searchParams || categories[0]?.name)?.slice(0, num?.skill)?.map((skill: any, i: any) => (<span onClick={()=> navigate(`/search?searchTerm=${skill?.name}`)} style={{ border: `1px solid ${searchParams === skill?.name ? "blue" : "#E2E8F0"}` }} className='cursor-pointer py-[6px] px-[14px] text-[12px] rounded-[31px]' key={i}>{skill?.name}</span>))
                    }
                </div>
                {num?.skill < skills?.length && <h6 onClick={() => setNum((prev: { skill: number; }) => ({ ...prev, skill: prev.skill + 10 }))} className='mt-4 text-[14px] underline cursor-pointer'>See More</h6>}
            </div>
            <div className='px-6 py-4' style={{ borderBottom: "1px solid #E2E8F0" }}>
                <p>Price Range</p>
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                    <Slider
                        getAriaLabel={() => 'Price range'}
                        value={value}
                        min={0}
                        max={1000}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </Box>
                <div className='flex justify-between mt-2'>
                    <p>${value[0]}</p>
                    <p>${value[1]}</p>
                </div>
            </div>
        </div>
    )
}