import { SettingsLayout } from "context/settings/layout/SettingsLayout";
import { Profile } from "context/settings/profile/Profile";
import React from "react";
export const ProfilePage = () => {
  return (
    <SettingsLayout>
      <Profile />
    </SettingsLayout>
  );
};
