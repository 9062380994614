import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getToken } from "utils/auth/auth";
import { IUserProfile } from "utils/auth/auth.type";
// import { getToken } from "utils/auth/auth";

export const useSocket = (id?: string | undefined) => {
  const queryClient = useQueryClient();

  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { userDetails: currentUser } = useAuthenticatedUser();
  const location = useLocation(); // Access current route information

  // Use a ref to store the current user
  
  const emitEvent = (eventName: string, data?: any) => {
    if (!socket || socket.readyState !== WebSocket.OPEN) return;
    const message = JSON.stringify({ event: eventName, data });
    socket.send(message);
  };
  // ${process.env.REACT_APP_API_BASE_URL}
  useEffect(() => {
    const createSocket = () => {
      const newSocket = new WebSocket(
        `${process.env.REACT_APP_API_BASE_SOCKET}/ws/chat/?token=${getToken()}`
      );

      // newSocket.onopen = () => {
      //   console.log("WebSocket connection established hook");
      // };

      newSocket.onclose = () => {
        console.log("WebSocket connection closed");
      };

      newSocket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      newSocket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data); 
        const { type: eventName, content, sender } = JSON.parse(event.data);
        handleSocketMessage(eventName, content, sender);
      };

      setSocket(newSocket);
    };

    if (currentUser?.data?.service_profile) {
      createSocket();
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [ currentUser ]);

  const handleSocketMessage = (eventName: string, data: any, sender: any) => {
    if (!currentUser?.data?.service_profile) return;
    const unreadMessage = `unread_messages_${currentUser.data.id}`;
    const newMessages = `new_message_${currentUser.data.id}`;
    const newNotification = `new_notifications_${currentUser.data.id}`;
    const unreadNotification = `unread_notifications_${currentUser.data.id}`;
    const unreadMessageFrom = `unread_messages_from_${id && (id as string)}`;
    const newMessageFrom = `new_message_from_${id && (id as string)}`;

    switch (eventName) {
      case unreadMessage:
        // Handle unread messages
        break;
      case 'chat':
        queryClient.invalidateQueries({
          queryKey: ["conversation", currentUser.data.service_profile],
        });
        queryClient.invalidateQueries({
          queryKey: ["convo"],
        });
        queryClient.invalidateQueries({
          queryKey: ["messages"],
        });
        toast.info(`New Message from ${sender}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
          });

        break;
      case newNotification: 
      case unreadNotification:
        queryClient.invalidateQueries({
          queryKey: ["notification", currentUser.data.id],
        });
        break;
      case newMessageFrom:
        queryClient.invalidateQueries({
          queryKey: ["conversation", id],
        });
        break;
      case unreadMessageFrom:
        console.log(data, "unread msg");
        break;
      default:
        break;
    }
  };

  return { socket, emitEvent };
};