import { getJobsFn } from "services/job.service";
import { useQuery } from "@tanstack/react-query";

export const useGetJobs = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["jobs"],
    queryFn: getJobsFn,
  });

  return { isLoading, jobsData: data };
};
