import React, { useContext, useState } from 'react'
import plumbingImage from "../../assets/images/plumbing.jpg";
import { ReactComponent as HeartIcon } from "assets/svg/heart.svg";
import locationIcon from "../../assets/svg/location.svg"
import starIcon from "../../assets/svg/Star.svg"
import { Avatar } from "ui/avatar";
import { Button } from "ui/button";
import { useNavigate } from 'react-router-dom';
import { ErrorType, IFavoriteServiceData, SUB_CATEGORY } from 'types';
import { LayoutContext } from 'context';
import { LoginForm } from 'pages/auth';
import { useAuthenticatedUser } from 'hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notify } from "shared";
import { addFavouriteProviderFn, removeFavouriteProviderFn } from 'services';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

export const ServiceCard: React.FC<{service: IFavoriteServiceData}> = ({service}) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient();

    const { modalOpen, openModal } = useContext(LayoutContext);

    const getCost = (subCategory: SUB_CATEGORY[]) => {
        return subCategory?.map((sub)=> sub?.cost)
    }

    const { mutate: likeMutate, isPending: isFetching } = useMutation({
        mutationFn: addFavouriteProviderFn,
        onSuccess: async (data) => {
          if (data?.success === true) {
            queryClient.invalidateQueries({
              queryKey: ["service-provider-profiles"],
            });
            notify.success({ message: data.message });
          }
        },
        onError: async (response: ErrorType) =>{
          if(response?.response?.status === 401){
            notify.error({
              message: "Error",
              subtitle: 'Unauthenticated, Please login!',
            })
            return
          }
            notify.error({
              message: "Error",
              subtitle: response?.response?.data?.error,
            })
        },   
      });

      const favouriteProvider = (id: string) => {
        likeMutate(id)
      }
      const { mutate: removeLikeMutate, isPending: isRemoveFetching } = useMutation({
        mutationFn: removeFavouriteProviderFn,
        onSuccess: async (data) => {
          if (data?.success === true) {
            queryClient.invalidateQueries({
              queryKey: ["service-provider-profiles"],
            });
            notify.success({ message: data.message });
          }
        },
        onError: async (response: ErrorType) =>{
            notify.error({
              message: "Error ",
              subtitle: response?.data?.error,
            })
        },   
      });
    
    
      const removeFavouriteProvider = (id: string) => {
        removeLikeMutate(id)
      }
    
    return (
        <div style={{width: "100%"}} className='flex flex-col gap-4 mb-6 lg:flex-row'>
            <div className='lg:w-[377px] w-[100%] h-[214] relative'>
                <img className='rounded-[14px] lg:h-[214px] h-[250px] lg:w-[377px] w-[100%]' src={service.profile_picture || plumbingImage} alt="" />
                <div className='w-[36px] h-[36px] flex justify-center items-center bg-white absolute top-2 right-2 rounded-[10px] cursor-pointer'>
                    {/* <HeartIcon/> */}
                    {service?.is_favorite ? <FaHeart onClick={() => removeFavouriteProvider(service?.id)} className="text-red-600" /> :  <FaRegHeart  onClick={() => favouriteProvider(service?.id)}/>}
                </div>
            </div>
            <div style={{width: "100%"}}>
                <div style={{width: "100%"}} className='flex justify-between'>
                    <div className='flex gap-2'>
                        <Avatar className="bg-[#D9D9D9] w-[clamp(50px,_8vw,_50px)]" />
                        <div>
                            <h6 className='font-semibold'>{service?.business_name}</h6>
                            <p className='flex gap-2 text-[#64748B] text-[12px] lg:text-[16px]'><img src={locationIcon} alt="" /> {service?.state}, {service?.country} - 2.3 Km away</p>
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <img src={starIcon} alt="" />
                        <p><span className='font-semibold text-[13px] lg:text-[16px]'>5.0</span> (144)</p>
                    </div>
                </div>
                
                <p className='mt-4 text-[#334155]'>{service?.about_me}</p>
                <p className='mt-2'>From <span className='font-semibold'>${Math.min(...getCost(service?.sub_category))}/hr</span></p>
                <Button
                    size="sm"
                    className="w-[160px] mt-4"
                    onClick={() => {
                        // if(!userDetails?.data?.fullname){
                        //     setOpenFom("login")
                        //     return
                        // }
                        navigate(`/product-details/${service?.id}`)
                    }
                }
                >
                    Hire me
                </Button>
            </div>

        </div>
    )
}
