import React, { useState } from "react";
import { Card } from "ui/card";
import ProductImage from "assets/images/productImage.png";
import { CategoriesSort } from "ui/categoriesSort";
import { Avatar } from "ui/avatar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "ui/button";
import StarRating from "ui/starRating";
import { useGetServiceProfileById } from "hooks/profile/useGetServiceProfileById";
import { ProductDetailsModal } from "./productDetailsModal";
import { useGetConversation } from "hooks/services/useGetConversation";
import { LoginForm } from "pages";
import { useAuthenticatedUser } from "hooks";

export const ProductDetails = () => {
  const { id } = useParams();
  const [openForm, setOpenFom] = useState("");
  const [drawer, setDrawer] = useState(false);
  const navigate = useNavigate();
  const { userDetails } = useAuthenticatedUser();

  const [chatId, setChatId] = useState("");

  const { initiateConvo } = useGetConversation();

  const handleConvoInitiation = async () => {
    const res = await initiateConvo({ user: serviceProfileData?.owner_id });

    if (res?.success) {
      navigate(`/chat/${res?.data?.id}`);
    }
  };

  const { serviceProfileData, serviceProfileLoading } =
    useGetServiceProfileById(id as string);

  
  const handleRatingChange = (rating: number) => {
    console.log(`New rating: ${rating}`);
  };


  return (
    <>
      <div className="container my-[80px] w-full px-4 ">
        <div className="flex flex-col md:flex-row w-full h-[calc(100vh-160px)] ">
          <div className=" col-span-8 w-full md:w-8/12 hideScrollBar flex flex-col overflow-y-auto pr-4">
            <div className="w-[70%]">
              <span className="font-bold ">
                Let's Build Your Dream Website - User-Friendly, Engaging, and
                Optimized for Success!
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <Card className="border-0 mt-6">
                <img
                  src={serviceProfileData?.profile_picture}
                  alt="product_image"
                />
              </Card>
              <CategoriesSort
                items={Array(20).fill("Application Design")}
                filters={[]}
                setFilters={() => {}}
                textColor="text-black border border-[#CBD5E1] "
              />
            </div>
            <span className="font-bold mt-6 mb-3">About Me</span>
            <span className="text-sm">
              With over half decade of experience in building multi-dimensional
              platform for private companys, health sectors and individuals
            </span>
            <div className="flex flex-col gap-3 mt-8">
              <span className="font-bold">Overview</span>
              <div className="w-[396px] flex justify-between">
                <span className="text-sm font-medium">Professional Expert</span>
                <span className="text-sm font-medium">
                  4 hires on this website
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-6 w-full">
              <span className="font-bold">Reviews</span>
              <span>
                <span className="text-sm font-bold">
                  4.9/5 <span className="font-medium mx-2">Very Satisfied</span>
                </span>{" "}
                <div className="flex items-center">
                  <StarRating
                    totalStars={5}
                    initialRatings={{ 5: 10, 4: 7, 3: 3, 2: 2, 1: 1 }}
                    onRatingChange={handleRatingChange}
                  />
                </div>
              </span>
              <div className="hideScrollBar mx-[-25px] mt-1 lg:mx-0 h-full flex items-center overflow-x-auto w-full  max-w-lg  ">
                <div>
                  <CategoriesSort
                    items={[
                      "5 Star (11)",
                      "4 Star (11)",
                      "3 Star (11)",
                      "1 Star (11)",
                      "5 Star (11)",
                      "4 Star (11)",
                      "3 Star (11)",
                      "1 Star (11)",
                      "5 Star (11)",
                      "4 Star (11)",
                      "3 Star (11)",
                      "1 Star (11)",
                    ]}
                    textColor="text-black border-[#E2E8F0]"
                    filters={[]}
                    setFilters={() => {}}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-12 mt-6">
              {Array(20)
                .fill(0)
                .map((_, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <Avatar fallbackText="T" className="bg-[#F85D5D]" />
                      <div className="flex flex-col">
                        <span className="font-bold text-sm">theo_cote</span>
                        <span className=" text-sm">@theo_cote</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <span>rating</span>
                      <span className="text-xs">2 months ago</span>
                    </div>
                    <span className="text-sm mt-2">
                      Good work with Shaheer. Communication was complicated at
                      first but after a few review it finally met my
                      expectations. I would have preferred a more proactive
                      approach and more attention to details from them. The end
                      result still satisfies me and is of good quality.
                    </span>
                    <div className="flex items-center gap-3 mt-3">
                      <span className="font-bold">Helpful?</span>
                      <span className="font-bold">Yes</span>
                      <span className="font-bold">No</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-span-4 w-full md:w-4/12 h-fit sticky top-20">
            <Card>
              <span>
                From <span className="font-bold mx-1"> $25/hr</span>
              </span>
              <span className="text-xs text-[#64748B]">
                Get a{" "}
                <Link to="" className="underline font-bold text-[#2563EB]">
                  free quote
                </Link>{" "}
                from this professional
              </span>
              <span className="text-xs text-[#64748B]">
                Save up to 10% with for new users
              </span>
              <div>
                <div className="flex justify-between mt-2">
                  <div className="flex items-center gap-3">
                    <Avatar />
                    <div className="flex flex-col ">
                      <span className="font-bold ">
                        {serviceProfileData?.business_name}
                      </span>
                      <span>
                        {serviceProfileData?.state},{" "}
                        {serviceProfileData?.country}
                      </span>
                      <span
                        className="text-sm font-bold text-[#4188F9]  mt-1 cursor-pointer"
                        onClick={() => setDrawer(true)}
                      >
                        See profile
                      </span>
                    </div>
                  </div>
                  <span className="text-sm">
                    <span className="font-bold">5.0</span>
                    <span className=" text-[#64748B]">{`(144)`}</span>
                  </span>
                </div>
                <Button
                  size="sm"
                  className="w-full mt-4"
                  onClick={() => {
                     if(!userDetails?.data?.firstname){
                            setOpenFom("login")
                            return
                        }
                    handleConvoInitiation();
                    // navigate("/chat");
                  }}
                >
                  Chat with provider
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <LoginForm loginPrompt='to hire this provider!'  openLogin={openForm} setOpenLogin={setOpenFom} />

      <ProductDetailsModal
        img={serviceProfileData?.profile_picture as string}
        open={drawer}
        setDrawer={setDrawer}
      />
    </>
  );
};
