import React, { useState } from "react";
import {
  GetQuoteButton,
  HeroCallStyledText,
  HeroCallText,
  HeroContainer,
  HeroLeft,
  HeroMainWorker,
  HeroMan,
  HeroPopularSearch,
  HeroPopularSearchItem,
  HeroQuoteBtnContainer,
  HeroRight,
  HeroServiceSearch,
  HeroServiceSearchBtn,
  HeroServiceSearchInput,
  HeroServiceSearchLocation,
  HeroTrustPilot,
  HeroWorkerBg,
  HeroWorkerBtn,
  HeroWorkerInfo,
  HeroWorkerInfoContainer,
  HeroWorkerRating,
  HeroWorkerTop,
  HeroWorkersBox,
  ScrollLeft,
  ScrollRight,
} from "./HeroStyles";
import {
  Div,
  Image,
  Paragraph,
  SearchIcon,
  heroMan,
  heroTextUnderline,
  heroWorkerBg,
  trustPilot,
  worker,
} from "assets";
import {
  MdArrowRightAlt,
  MdLocationOn,
  MdSearch,
  MdChevronRight,
  MdChevronLeft,
} from "react-icons/md";
import { ReactComponent as TrustPilotIcon } from "assets/svg/trustPilotIcon.svg";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import classNames from "classnames";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button } from "ui/button";
import styles from "./hero.module.css";
import { Input } from "ui/input";
import { CategoriesSort } from "ui/categoriesSort";
import { Autocomplete, TextField } from "@mui/material";
import { otherServices } from "../OtherServices/data";

export const HeroSection = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("hire-a-pro");
  const [searchParams] = useSearchParams();
  const [term, setTerm] = useState("");
  const [location, setLocation] = useState("")

  const handleSearch = () => {
    const params = new URLSearchParams(searchParams);

    if (term) {
      params.set("searchTerm", term);
    } else {
      params.delete("searchTerm");
    }
    if (location) {
      params.set("location", location);
    } else {
      params.delete("location");
    }

    navigate(`${"/search"}?${params.toString()}`, {
      replace: true,
    });
  };

  const handleChange = (term: string) => {
    setTerm(term);
  };

  const handleLocationChange = (locat: string) => {
    setLocation(locat);
  };

  const handleServiceSelect = (_: any, value: string | null) => {
    setTerm(value!)
  }

  return (
    <section
      className={`w-full h-fit bg-gradient-to-r from-[#030817] to-[#312E81] via-[#030817]`}
    >
      <div className="container w-full grid mt-[30px]  grid-cols-5 lg:mt-[60px]">
        <div className=" bg-[#030817]  pt-16 pb-8 xlg:px-0 px-6  justify-end text-white  col-span-5 lg:col-span-3 flex ">
          {/* <div className="w-full flex max-w-[715px] "> */}
          <div className="flex flex-col w-full gap-8 ">
            <HeroQuoteBtnContainer>
              <GetQuoteButton>
                <span className="text-sm lg:text-base">
                  {" "}
                  Get Free Quotes Within Minutes
                </span>
                &nbsp; <MdArrowRightAlt />{" "}
              </GetQuoteButton>
            </HeroQuoteBtnContainer>
            <div className="lg:leading-[60px]">
              <span className="md:text-[57px]  text-[24px] font-extrabold">
                We Take Care of Your Home & Your{" "}
              </span>
              <span className="relative ">
                <span className=" inline-flex md:text-[57px] text-[24px] font-extrabold">
                  Freelance
                </span>
                <img src={heroTextUnderline} className="absolute left-0" />
              </span>
              <span className=" inline-flex md:text-[57px] text-[24px] font-extrabold">
                {" "}
                Projects
              </span>
            </div>
            <div className="flex gap-4">
              <p
                className={`border-b cursor-pointer uppercase ${tab === "hire-a-pro" ? "border-blue-50 text-[#4188F9]" : "border-transparent"}  p-2`}
                onClick={() => setTab("hire-a-pro")}
              >
                Hire a pro
              </p>
              <p
                className={`border-b cursor-pointer uppercase ${tab === "find-customer" ? "border-blue-50 text-[#4188F9]" : "border-transparent"}  p-2`}
                onClick={() => setTab("find-customer")}
              >
                Find customers
              </p>
            </div>
            {tab === "hire-a-pro" ? (
              <div className="flex flex-col gap-3 lg:w-fit ">
                <div className="group mt-2 flex h-[53px]  items-center gap-2 rounded-[5px] border border-[#1E293B] bg-[#0F172A]  pl-[10px] lg:w-full xlg:w-[600px]">
                  <div className="flex items-center h-full gap-2">
                    <img className="w-6 h-6" src="/location.png" alt="" />

                    <Input
                      // onChange={handleSearch}
                      // onBlur={() => navigate("/search")}
                      placeholder="Location"
                      className="h-full w-[160px] text-xs font-normal bg-transparent border-none outline-none"
                      onChange={(e) => handleLocationChange(e.target.value)}
                    />
                  </div>
                  {/* <Input
                    // onChange={handleSearch}
                    // onBlur={() => navigate("/search")}
                    placeholder="What service are you looking for today?....."
                    className="h-full text-xs font-normal bg-transparent border-none outline-none"
                    onChange={(e) => handleChange(e.target.value)}
                  /> */}
                  <Autocomplete
                    options={otherServices}
                    getOptionLabel={(option) => option as string}
                    onChange={handleServiceSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="What service are you looking for today?....."
                        variant="outlined"
                        sx={{
                          "& .MuiInputBase-input": {
                            color: "grey",
                          },
                          "& .MuiInputLabel-root": {
                            color: "grey",
                          },
                        }}
                      />
                    )}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#10172A" // Keep border as default on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#10172A", // Remove blue border on focus
                        },
                      },
                    }}
                  />
                  <span
                    onClick={handleSearch}
                    className="flex items-center p-4 bg-blue-600 rounded-lg cursor-pointer justify-self-end"
                  >
                    <img src="/search-normal.png" alt="" />
                  </span>
                </div>
                <div className="flex flex-wrap items-center ">
                  <span className="mr-3 text-xs flex-nowrap whitespace-nowrap lg:mb-1">
                    Popular searches:
                  </span>
                  <div className="hideScrollBar mx-[-25px] mt-1 lg:mx-0 h-full flex items-center overflow-x-auto  max-w-lg  ">
                    <div>
                      <CategoriesSort
                        items={[
                          {
                            name: "Plumbing",
                          },
                          {
                            name: "Domestic Service",
                          },
                          {
                            name: "Electrical Work",
                          },
                          // "Domestic Service",
                          // "Web Design",
                          // "Product Design",
                          // "Software Engineering",
                          // "Cleaning Service",
                          // "Domestic Service",
                        ]}
                        filters={[]}
                        setFilters={() => ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3 mt-8">
                  <div className="flex items-center gap-2">
                    <TrustPilotIcon />
                    <span>Trustpilot</span>
                  </div>
                  <span className="sm">Verify by trust pilot</span>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-start gap-4 ">
                <p>Meet new customers</p>
                <p>Sign up to start growing your business</p>
                <Button onClick={() => navigate("/provider")}>
                  Get Started
                </Button>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
        <div className=" text-white bg-[#312E81]  py-8 lg:bg-inherit lg:pl-10 w-full col-span-5 lg:col-span-2">
          <div className="flex items-center justify-center w-full gap-0 px-6 h-fit lg:pt-10 lg:px-30 md:gap-10 lg:gap-6">
            <div className=" w-[clamp(30px_8vw_30px)] bg-white text-black rounded-full">
              <MdChevronLeft />
            </div>
            <div>
              <div className="relative m-auto lg:w-[380px] lg:h-[400px] w-[330px] h-[380px]">
                <img
                  className="absolute lg:w-[380px] lg:h-[400px] w-[330px] h-[380px]"
                  src={heroWorkerBg}
                  alt=""
                />
                <HeroWorkerTop>Bring me your challenges</HeroWorkerTop>
                <HeroMan src={heroMan} />
                <HeroWorkerInfoContainer>
                  <Div>
                    <HeroWorkerInfo>Usman Zafar</HeroWorkerInfo>
                    <HeroWorkerRating>
                      <RiVerifiedBadgeFill />
                      Top rated Carpenter
                    </HeroWorkerRating>
                  </Div>
                  <HeroWorkerBtn>Hire me</HeroWorkerBtn>
                </HeroWorkerInfoContainer>
              </div>
            </div>
            <div className=" w-[clamp(30px_8vw_30px)] bg-white text-black rounded-full">
              <MdChevronRight />
            </div>
          </div>
          <div>
            {/* <HeroWorkersBox>
              {Array.from({ length: 5 }, (_, index) => (
                <Image src={worker} />
              ))}
            </HeroWorkersBox> */}
          </div>
        </div>
      </div>
    </section>
  );
};
