import Api from "utils/auth/api";
import {
  ISignUpType,
  ICreateEmailType,
  IOtpType,
  ILoginUserType,
  IResetPasswordType,
  ICreatePasswordType,
} from "types";

export const createAnAccount = async (payload: ISignUpType) => {
  return Api.post("/user/", payload).then((res) => res.data);
};

export const createAnEmail = async (payload: ICreateEmailType) => {
  return Api.post("/user/send-creation-email/", payload).then(
    (res) => res.data,
  );
};

export const confirmOTP = async (payload: IOtpType) => {
  return Api.post("/user/validate-otp/", payload).then((res) => res.data);
};

export const loginUser = async (payload: ILoginUserType) => {
  return Api.post("/user/login/", payload).then((res) => res.data);
};

export const resetPassword = async (payload: IResetPasswordType) => {
  return Api.post("/user/reset-password/", payload).then((res) => res.data);
};

export const createPassword = async (payload: ICreatePasswordType) => {
  return Api.post("/user/create-password/", payload).then((res) => res.data);
};
