import { create } from "zustand";
import { IStore, createCommonStore, ISelected } from "./commonStore";

// Create Zustand store with proper typing
export const useDialogStore = create<IStore>((set) => ({
  ...createCommonStore(),

  setSelected: (selected: ISelected) =>
    set((state) => ({
      ...state,
      selected: {
        ...selected,
        type: selected.type, // Ensure type is "desktop" or "mobile"
      },
    })),

  onClose: () =>
    set((state) => ({
      ...state,
      selected: {
        type: "desktop", // Ensure reset to valid DeviceType
        open: "",
        data: {},
      },
    })),
}));
