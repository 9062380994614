import { themeColors } from "Themes/ThemeColors";
import styled, { keyframes } from "styled-components";

const slide = keyframes`
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
`;

export const LogosCarouselDiv = styled.div`
  p {
    margin: 3rem 0;
    text-align: center;
    font-weight: 700;
  }

  &:hover div {
    animation-play-state: paused;
  }
`;

export const CarouselContainer = styled.div`
  background-color: ${themeColors.white};
  display: flex;
  overflow: hidden;

  margin-bottom: 3rem;
`;

export const LogosContainer = styled.div`
  white-space: nowrap;
  min-width: fit-content;
  animation: 35s ${slide} infinite linear;
`;

export const Logo = styled.img`
  display: inline-block;
  margin: 0 3rem;
`;
