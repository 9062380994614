/* eslint-disable react/prop-types */
import { Autocomplete, TextField, Box, Modal } from '@mui/material';
import React from 'react';
import CurrencyInput from 'react-currency-input-field'
import { CheckCircle2, MessageCircleQuestion } from "lucide-react"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0.3px solid #24242480',
    boxShadow: "0px 4px 4px 0px #00000040",
    pb: 3,
    borderRadius: 2
};

export const NewServiceModal: React.FC<{ newModal: boolean, handleNewModalClose: any, handleAddNewSkill: any, setPrice: any, newSkill: any }> = ({ newModal, newSkill, handleAddNewSkill, handleNewModalClose, setPrice }) => {
    return (
        <Modal
            open={newModal}
            onClose={handleNewModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
                <div className="px-5 pt-4 pb-3 text-[20px] font-semibold" style={{ borderBottom: "0.3px solid #24242480" }} id="child-modal-title">{newSkill?.name}</div>
                <div className="px-5 pt-6 pb-3">
                    <p className="text-center">Pros with upfront pricing get hired more on Audtiklance.</p>
                    <p className="my-2 text-center">Add a base price to help you get contacted and hired more, The price will include: Labor (excludes cost of parts).</p>
                    <br />
                    <label htmlFor="price">Enter your base price</label>
                    <br />
                    <CurrencyInput
                        name='price'
                        id='price'
                        placeholder='$0.00'
                        prefix="$"
                        required
                        className="w-full mt-1 rounded-sm"
                        onValueChange={(value) => setPrice(value!)}
                    />
                    <div className="flex gap-2 mt-4">
                        <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] h-[40px] rounded-sm text-gray-700" onClick={handleNewModalClose}>Cancel</button>
                        <button onClick={handleAddNewSkill} className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Submit</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export const SearchServiceModal: React.FC<{ searchModal: any, handleSearchModalClose: any, data: any, handleServiceSelect: any, setTerm: any, setNewModal: any }> = ({ searchModal, setNewModal, setTerm, handleSearchModalClose, handleServiceSelect, data }) => {
    return (
        <Modal
            open={searchModal}
            onClose={handleSearchModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
                <div className="px-5 pt-4 pb-3 text-[20px] font-semibold" style={{ borderBottom: "0.3px solid #24242480" }} id="child-modal-title">Select A New Skill To Add</div>
                <div className="px-5 pt-6 pb-3">
                    <Autocomplete
                        options={data?.results || []}
                        // @ts-ignore
                        getOptionLabel={(option) => option?.name as string}
                        onChange={handleServiceSelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search to add a skill..."
                                variant="outlined"
                                onChange={(e) => setTerm(e.target.value)}
                            />
                        )}
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                    borderColor: "inherit"
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "inherit",
                                },
                            },
                        }}
                    />
                    <div className="flex gap-2 mt-4">
                        <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700" onClick={handleSearchModalClose}>Cancel</button>
                        <button onClick={() => { setNewModal(true) }} className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Continue</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export const ListServiceModal: React.FC<{ listModal: any, handleListModalClose: any, setListPayModal: any; setBoostModal: any; price: any, setPrice: any, activeSkill: any }> = ({ listModal, handleListModalClose, setBoostModal, setListPayModal, activeSkill, price, setPrice }) => {
    return (
        <Modal
            open={listModal}
            onClose={handleListModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
                <div className="px-5 pt-4 pb-3 text-[20px] font-semibold" style={{ borderBottom: "0.3px solid #24242480" }} id="child-modal-title">{activeSkill?.sub_category}</div>
                <div className="px-5 pt-6 pb-3">
                    <p className="text-center">Pros with upfront pricing get hired more on Audtiklance.</p>
                    <p className="my-2 text-center">Add a base price to help you get contacted and hired more, The price will include: Labor (excludes cost of parts).</p>
                    <br />
                    <label htmlFor="price">Enter your base price</label>
                    <br />
                    <CurrencyInput
                        name='price'
                        id='price'
                        placeholder='$0.00'
                        prefix="$"
                        required
                        value={price}
                        className="w-full mt-1 rounded-sm"
                        onValueChange={(value) => setPrice(value!)}
                    />
                    <div className="flex gap-2 mt-4">
                        <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700" onClick={handleListModalClose}>Cancel</button>
                        <button onClick={() => { setListPayModal(true) }} className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">List</button>
                        <button onClick={()=> setBoostModal(true)} className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">List & Boost</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export const ListPayModal: React.FC<{ listPayModal: any, handleListPayModalClose: any, handleListPaySubmit: any }> = ({ listPayModal, handleListPayModalClose, handleListPaySubmit }) => {
    return (
        <Modal
            open={listPayModal}
            onClose={handleListPayModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[500px]" sx={{ ...style }}>
                <div className="px-5 pt-4 pb-3 text-[20px] font-semibold" style={{ borderBottom: "0.3px solid #24242480" }} id="child-modal-title">List Your Service</div>
                <div className="px-3 pt-3 pb-0">
                    <div className='mb-2'>
                        <h4 className='text-[18px] font-bold'>Reach More Clients</h4>
                        <p>Once Selected, Please Click Continue</p>
                    </div>
                    <div style={{border: "0.6px solid grey", borderRadius: "5px"}} className='flex items-start justify-between p-3'>
                        <div className='flex items-center gap-2'>
                            <input type="radio" name="type" id="type1" />
                            <label htmlFor='type1' className='cursor-pointer'>
                                <h3>Basic Listing (Free)</h3>
                                <ul className='text-[13px]'>
                                    <li>Profile displayed in search result</li>
                                    <li>Access to monthly credits for basic leads</li>
                                    <li>Limited visibility: ranked lower in search results</li>
                                </ul>
                            </label>
                        </div>
                        <h1>$0.00</h1>
                    </div>
                    <div style={{border: "0.6px solid grey", borderRadius: "5px"}} className='flex items-start justify-between p-3 my-3'>
                        <div className='flex items-center gap-2'>
                            <input type="radio" name="type" id="type2" />
                            <label htmlFor='type2' className='cursor-pointer'>
                                <h3>Featured Listing</h3>
                                <ul className='text-[13px]'>
                                    <li>Highlighted profile in search results (priority ranking)</li>
                                    <li>Tag as "Featured" for more credibility</li>
                                    <li>Additional exposure on the homepage or relevant categories</li>
                                </ul>
                            </label>
                        </div>
                        <h1>$5.00</h1>
                    </div>
                    <div style={{border: "0.6px solid grey", borderRadius: "5px"}} className='flex items-start justify-between p-3'>
                        <div className='flex items-center gap-2'>
                            <input type="radio" name="type" id="type3" />
                            <label htmlFor='type3' className='cursor-pointer'>
                                <h3>Premium Plus Listing</h3>
                                <ul className='text-[13px]'>
                                    <li>Top ranking in search result</li>
                                    <li>Access to insights on profile views and lead generation analytics</li>
                                    <li>Free trial for additional credit to connect with clients</li>
                                </ul>
                            </label>
                        </div>
                        <h1>$15.00</h1>
                    </div>
                    <hr />
                    <h2 className='flex gap-3 my-4'>What is a featured listing? <MessageCircleQuestion className='cursor-pointer' /></h2>
                    {/* <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Priority Ranking:</span> Your service profile appear higher in search results within your category, increasing visibility to potential clients</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Highlighted Appearance:</span> Your profile is tagged as "Featured" making it stanf out from other listings and boosting credibility</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Exclusive Exposure:</span> Additional placement on the homepage or prominent areas, attracting more attention from users browsing the platform</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Boost your service:</span> to the top of the search results to increase visibility and attract more client actively searching for your expertise</p>
                    </div> */}
                    <div className="flex gap-2 mt-4">
                        <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700" onClick={handleListPayModalClose}>Cancel</button>
                        <button className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Proceed to pay</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export const BoostModal: React.FC<{ boostModal: any, handleBoostModalClose: any, handleBoostSubmit: any }> = ({ boostModal, handleBoostModalClose, handleBoostSubmit }) => {
    return (
        <Modal
            open={boostModal}
            onClose={handleBoostModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[500px]" sx={{ ...style }}>
                <div className="px-5 pt-4 pb-3 text-[20px] font-semibold" style={{ borderBottom: "0.3px solid #24242480" }} id="child-modal-title">List And Boost Your Service</div>
                <div className="px-3 pt-3 pb-0">
                    <div className='mb-2'>
                        <h4 className='text-[18px] font-bold'>Reach More Clients</h4>
                        <p>Once Selected, Please Click Continue</p>
                    </div>
                    <div style={{border: "0.6px solid grey", borderRadius: "5px"}} className='flex items-start justify-between p-3'>
                        <div className='flex items-center gap-2'>
                            <input type="radio" name="type" id="type1" />
                            <label htmlFor='type1' className='cursor-pointer'>
                                <h3>Bronze Tier: Basic Boost</h3>
                                <ul className='text-[12px]'>
                                    <li>Displayed at the top of search results for relevant categories once per day</li>
                                    <li>Highlighted with a subtle badge indicating "Featured"</li>
                                </ul>
                            </label>
                        </div>
                        <h1>$10/month</h1>
                    </div>
                    <div style={{border: "0.6px solid grey", borderRadius: "5px"}} className='flex items-start justify-between p-3 my-3'>
                        <div className='flex items-center gap-2'>
                            <input type="radio" name="type" id="type2" />
                            <label htmlFor='type2' className='cursor-pointer'>
                                <h3>Silver Tier: Premium Exposure</h3>
                                <ul className='text-[12px]'>
                                    <li>Displayed at the top of search results multiple times daily</li>
                                    <li>Enhanced listing with a bolded border to stand out</li>
                                    <li>Includes basic analytics (views and clicks)</li>
                                </ul>
                            </label>
                        </div>
                        <h1>$20/month</h1>
                    </div>
                    <div style={{border: "0.6px solid grey", borderRadius: "5px"}} className='flex items-start justify-between p-3'>
                        <div className='flex items-center gap-2'>
                            <input type="radio" name="type" id="type3" />
                            <label htmlFor='type3' className='cursor-pointer'>
                                <h3>Gold Tier: Maximum Visibility</h3>
                                <ul className='text-[12px]'>
                                    <li>Always displayed in the top 3 results for relevant categories</li>
                                    <li>Featured in a rotating banner on the homepage</li>
                                    <li>Includes detailed analytics (views, clicks, and conversion insights).</li>
                                    <li>Exclusive "Top Service Provider" badge for credibility.</li>
                                </ul>
                            </label>
                        </div>
                        <h1>$50/month</h1>
                    </div>
                    <hr />
                    <h2 className='flex gap-3 my-4'>What is a featured ad? <MessageCircleQuestion className='cursor-pointer' /></h2>
                    {/* <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Prominent Display:</span> Your service or profile is displayed at the top of website and specific search results or category pages for maximum exposure.</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Custom Visibility Duration:</span> Choose to run the ad for 1–7 days to target short-term or seasonal campaigns.</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Geo-Targeting:</span> Tailor visibility to specific locations or audience demographics for better reach to relevant clients.</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Showcase your service</span> prominently in highlighted sections across the platform, drawing more attention and enhancing credibility to stand out from competitors.</p>
                    </div> */}
                    <div className="flex gap-2 mt-4">
                        <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700" onClick={handleBoostModalClose}>Cancel</button>
                        <button className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Proceed to pay</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}