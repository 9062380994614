import { cva, type VariantProps } from "class-variance-authority";

import React from "react";
import classNames from "classnames";
import { Slot } from "@radix-ui/react-slot";
import Spinner from "component/oldcomponent/spinner/spinner";
// import Spinner from "../loaders/buttonSpinner";

const buttonVariants = cva(
  // [&>span]:font-normal

  "inline-flex items-center justify-center  whitespace-nowrap rounded-lg text-sm   transition-colors focus-visible:outline-none focus-visible:ring-none focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ",
  {
    variants: {
      variant: {
        default:
          "text-white bg-[#4188F9] hover:bg-blue-600 font-bold rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none ",
        destructive:
          "bg-[#FEE2E2] text-[#EF4444] py-2.5 font-bold px-8 hover:bg-red-200",
        outline:
          "border-tertiary-white-100 text-tertiary-white-100 border-2 border-solid bg-transparent  hover:bg-tertiary-white-100  hover:text-blue-600",
        "deep-green":
          "bg-tertiary-deep-green-950 text-tertiary-white-100 hover:bg-primary-100 hover:text-tertiary-deep-green-950 ",
        "light-blue":
          "bg-[#F1F5F9] text-sm text-[#4188F9] hover:bg-blue-100 font-bold hover:text-tertiary-white-100",
        white:
          "bg-tertiary-white-100 text-tertiary-white-900 hover:bg-tertiary-white-200",
        plain: "",
      },
      size: {
        default: " px-7 py-3.5",
        sm: "rounded-md py-2 px-5  ",
        plain: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = "default",
      size,
      asChild = false,
      children,
      loading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={classNames(
          buttonVariants({ variant, size, className }),
          "gap-4",
        )}
        ref={ref}
        {...props}
      >
        <span className="w-full flex items-center justify-center gap-2">
          {children} {loading && <Spinner />}
        </span>
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
