import { QueryClient, useMutation } from "@tanstack/react-query";
import { createServiceProviderProfile } from "services";
//import { createServiceProviderProfile } from "services/authServices/services";
import { notify } from "shared";

export const useCreateServiceProviderProfile = () => {
  const queryClient = new QueryClient();
  const {
    mutateAsync: createServiceProviderMutation,
    isPending: isCreatingServiceProvider,
  } = useMutation({
    mutationFn: createServiceProviderProfile,
    onSuccess: async (data) => {
      if (data?.success) {
        queryClient.invalidateQueries({
          queryKey: ["service-provider-profile"],
        });
        // notify.success({ message: "user updated successfully" });
      }
      notify.success({ message: "user updated successfully" });
    },
    onError: async (err) =>
      notify.error({
        message: "Error while updating user information",
        subtitle: err?.message,
      }),
  });

  return {
    createServiceProviderMutation,
    isCreatingServiceProvider,
  };
};
