import { useAuthenticatedUser } from "hooks";
import { X } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "ui/avatar";
import { Button } from "ui/button";
import { Card } from "ui/card";

export const ServiceRequest = () => {
  const [showDetailModal, setShowDetailModal] = useState(false);

  const { userDetails } = useAuthenticatedUser();
  return (
    <>
      <div className="container my-[80px] w-full flex-flex-col px-4">
        <span className=" text-[24px] font-bold">Service requests</span>

        <div className="flex flex-col md:flex-row gap-6 w-full h-full mt-4 relative">
          <div className=" w-full flex flex-col gap-4 mt-2 overflow-y-auto h-screen pb-20 hideScrollBar">
            <div className=" border border-[#E2E8F0] rounded-[16px] ">
              <div className="flex justify-between p-4">
                <span className="font-bold">House Cleaning</span>
                <span>06:00:00 hours to accept</span>
              </div>
              <div className=" border-b border-[#E2E8F0]" />

              <div className="w-full flex justify-between gap-3 flex-col lg:flex-row lg:items-center p-4">
                <div className=" flex items-center gap-3">
                  <Avatar />
                  <span className="text-sm font-bold">Anastasia Cooper</span>
                </div>
                <div className="flex ">
                  <Button size="sm">Boost</Button>
                  <Button size="sm">Listed</Button>
                </div>
              </div>
              <div className="flex flex-col gap-2 p-4">
                <span className="font-semibold">Task description</span>
                <div className="flex justify-between items-center">
                  <div className="font-medium flex  gap-3 text-sm">
                    <span>Wed, Nov 29 at 9:30am</span>
                    <span>23 Elm street, Portland, Oregon GT351</span>
                    <span>Est. 6-8 hrs</span>
                    <span
                      onClick={() => setShowDetailModal(true)}
                      className="text-[#4188F9] font-bold cursor-pointer"
                    >
                      View
                    </span>
                  </div>
                  <span className="font-semibold">From $350</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-4">
              <div className="flex flex-col">
                <span className="font-bold text-[24px]">Upcoming bookings</span>
                <span>Due in 24 hours</span>
              </div>
              {/* <div className="flex flex-col gap-4">
              {Array(14)
                .fill(0)
                .map(() => (
                  <div className="flex flex-col gap-3 shadow-sm border border-[#E2E8F0] rounded-[16px] ">
                    <div className="w-full flex justify-between items-center px-4 pt-4 pb-1">
                      <span className="font-bold">Help in moving</span>
                      <span className="text-sm font-medium">
                        Wed, Nov 29 at 9:30am
                      </span>
                    </div>
                    <div className=" border-b border-[#E2E8F0]" />
                    <div className="flex flex-col md:flex-row md:justify-between md:items-center p-4">
                      <div className="flex gap-3 items-center">
                        <Avatar className="hidden" />
                        <div className=" flex flex-col md:justify-center">
                          <span className="font-semibold">
                            Anastasia Cooper
                          </span>
                          <div className="flex flex-col md:flex-row gap-2 md:items-center">
                            <span className="text-sm font-medium">
                              23 Elm street, Portland, Oregon GT351
                            </span>
                            <span className="hidden md:block"> •</span>
                            <span className="text-sm font-medium">
                              Est. 6-8 hrs
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-semibold">From $350</span>
                        <Link
                          to=""
                          className="underline text-[#4188F9] text-sm"
                        >
                          view details
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div> */}
            </div>
          </div>
          <div className="w-full md:w-5/12 h-fit flex flex-col gap-1 sticky left-0 right-0 top-0 z-10 bottom-0 overflow-y-scroll hideScrollBar px-[-10px]">
            <Card>
              <div className="flex flex-col justify-center">
                <div className="flex flex-col gap-2 items-center">
                  <Avatar
                    size={100}
                    className="bg-[#D9D9D9] w-[clamp(200px,_8vw,_200px)]  isolate"
                  />
                  <div className="flex flex-col items-center">
                    <span className="font-semibold">Shaheer Baber</span>
                    <span className="text-[#7A7B7F] text-xs">
                      Member since: 2023
                    </span>
                  </div>
                  <div>jjj</div>
                  <div className="bg-[#F6F6F6] w-[70%] p-2 rounded-full text-sm font-bold flex justify-center">
                    <span className="text-[#4188F9]">View profile</span>
                  </div>
                </div>
              </div>
            </Card>
            <Card>
              <div className="w-full flex justify-between py-2 ">
                <span>Inbox (295 unread)</span>
                <Link className="underline text-[#4188F9]" to="">
                  {" "}
                  view all
                </Link>
              </div>
              <div className=" border-b border-[#E2E8F0]" />

              <div className="h-[400px] overflow-y-scroll hideScrollBar">
                {Array(8)
                  .fill(0)
                  .map(() => (
                    <>
                      <div className=" flex justify-between items-center">
                        <div className="flex items-center gap-2 w-full">
                          <Avatar className="w-[clamp(20px_8vw_20px)]" />
                          <div className="flex flex-col">
                            <span>Joshua</span>
                            <span className=" line-clamp-1">
                              Hey, i have just finished th...
                            </span>
                          </div>
                        </div>
                        <div className="w-[clamp(40px_4vw_40px)] px-4 h-30px isolate text-xs  rounded-full bg-[#4188F9] text-white">
                          <span>2</span>
                        </div>
                      </div>
                      <div className=" border-b border-[#E2E8F0] my-3" />
                    </>
                  ))}
              </div>
            </Card>
          </div>
        </div>
      </div>

      {showDetailModal && (
        <div
          className="fixed inset-0 no-scrollbar z-50 flex items-center justify-center w-full bg-[#00000070] bg-opacity-50 backdrop-blur-sm overflow-hidden"
          // onClick={onClose}
        >
          <div
            className="bg-white no-scrollbar p-6 rounded-xl shadow-lg max-w-[390px] w-full h-fit max-h-[95vh] hideScrollBar overflow-auto "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-full flex flex-col gap-3 relative">
              <small className="mx-auto text-red text-sm font-bold text-[#007BD4]">
                Service Details
              </small>
              <span
                // onClick={onClose}
                onClick={() => setShowDetailModal(false)}
                className="absolute top-0 right-0 cursor-pointer"
              >
                <X />
              </span>
            </div>
            <div>
              <p>{userDetails?.data?.firstname + ' ' + userDetails?.data?.lastname}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
