import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function SelectOptionBtn({
  text,
  onClick,
  selectedItem,
  disabled,
  className,
  textColor,
}: {
  text: string;
  onClick?: (item: any) => void;
  selectedItem?: any[];
  disabled?: boolean;
  className?: string;
  textColor?: string;
}) {
  const [select, setSelect] = useState(false);
  const handleClick = () => {
    setSelect(!select);
  };
  // bg-[#E0E0E0]
  return (
    <button
      onClick={onClick || handleClick}
      disabled={disabled}
      className={classNames(
        `${textColor}  whitespace-nowrap text-[#E2E8F0]  rounded-full border border-[#334155] p-[8px_15px] text-[11px] outline-none transition-all ease-in-out remove-tap-highlight`,
        (selectedItem?.includes(text) && !disabled) || select
          ? "bg-primary-500 text-[#E2E8F0]"
          : disabled
            ? `${className} bg-[#e6e9ed]`
            : `${className} bg-transparent`
      )}
    >
      {text}
    </button>
  );
}
export function CategoriesSort({
  items,
  filters,
  setFilters,
  className,
  textColor,
  label,
}: {
  items: any[];
  filters: any[];
  setFilters: (selectedCategory: string) => void;
  className?: string;
  textColor?: string;
  label?: string;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const handleSearch = (term: string) => {
    const params = new URLSearchParams(searchParams);

    if (term) {
      params.set(label as string, term);
    } else {
      params.delete(label as string);
    }

    navigate(`${"/search"}?${params.toString()}`, {
      replace: true,
    });

    console.log("clickekkkek");
  };

  return (
    // ml-6
    <div className="xlg:ml-0">
      <div
        className={`${
          className
            ? className
            : "hideScrollBar w-full flex-col gap-4 overflow-x-auto "
        }`}
      >
        <div
          className={`${
            className ? className : " flex w-fit cursor-move gap-2 py-1 "
          }`}
        >
          {items?.map((item) => (
            <SelectOptionBtn
              text={item?.name}
              key={Math.random()}
              onClick={() => {
                setFilters(item);
                handleSearch(item?.id);
                // console.log(item, "itememm");
              }}
              selectedItem={filters}
              disabled={false}
              textColor={textColor}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
