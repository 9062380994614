import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Autocomplete, TextField, Box, Modal } from '@mui/material';
import { FormProvider, useForm } from "react-hook-form";
import { getAllSkill, getSkill } from "services";
import { notify } from "shared";
import { useFormStore } from "store";
import { Button } from "ui/button";
import CurrencyInput from 'react-currency-input-field'
import locationIcon from "../../../assets/svg/location.svg"
import { formatter } from "utils/formatter";
import { otherServices } from "component/oldcomponent/OtherServices/data";
import { debounce } from "lodash";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0.3px solid #24242480',
  boxShadow: "0px 4px 4px 0px #00000040",
  pb: 3,
  borderRadius: 2
};

type skillType = { name: string, id: string }

export const SkillsForm = ({
  onSubmit,
  onBack,
}: {
  onSubmit: () => void;
  onBack: () => void;
}) => {
  const methods = useForm();
  const [term, setTerm] = useState("");
  const [skills, setSkills] = useState<Array<skillType>>([])
  const [activeSkill, setActiveSkill] = useState<skillType>()
  const [amount, seAmount] = useState("");
  const [formData, setFormData] = useState<{ name: string, state: string, zipCode: string }>()
  const [price, setPrice] = useState("")
  const [modal, setModal] = useState(false)

  const handleClose = () => {
    setModal(false)
  }

  useEffect(() => {
    if (localStorage.getItem("form")) {
      setFormData(JSON.parse(localStorage.getItem("form")!))
    }
  }, [])

  const selected = useFormStore((state) => state.selected);
  const setSelected = useFormStore((state: any) => state.setSelected);

  const [selectedSkill, setSelectedSkill] = useState<
    {
      cost: number;
      time_frame: string;
      skills: string;
      skillName: string;
    }[]
  >(() => {
    const stored = JSON.parse(localStorage.getItem("skills") as string);
    return stored || [];
  });

  const { data, refetch } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(term),
    enabled: !!term,
  });

  useEffect(() => {
    const delayedSearch = debounce(() => { refetch() }, 600)
    delayedSearch()
    return () => delayedSearch.cancel()
  }, [term, refetch]);

  const handleSelectSkill = () => {
    setSelectedSkill((prev) => [
      ...prev,
      {
        cost: Number(price),
        time_frame: "FIXED",
        skills: activeSkill?.id!,
        skillName: activeSkill?.name!,
      },
    ]);
    setModal(false)
  };

  const onDeleteSkill = (id: string) => {
    setSelectedSkill((prev) => prev.filter((item) => item.skills !== id));
  };

  const onFormSubmit = (data: any) => {
    // Handle skill selection and validation

    setSelected({
      ...selected,
      data: { ...selected, skills: selectedSkill },
    });

    localStorage.setItem("skills", JSON.stringify(selectedSkill));
    onSubmit(); // Move to next step
  };

  const handleServiceSelect = (_: any, value: skillType | null) => {
    setActiveSkill(value!)
    if (value) {
      setModal(true)
    }
  }

  return (
    <>
      <div className="mb-8">
        <h2 className="text-[25px] font-semibold">{formData?.name}</h2>
        <p className="flex gap-2 mt-2" style={{ textTransform: "capitalize" }}><img src={locationIcon} alt="" /> {formData?.state} {formData?.zipCode}</p>
      </div>
      <FormProvider {...methods}>
        <form
          className="gap-6 pb-10 "
          onSubmit={methods.handleSubmit(onFormSubmit)}
        >
          <Autocomplete
            options={data?.results || []}
            getOptionLabel={(option) => option?.name as string}
            onChange={handleServiceSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search to add a skill..."
                variant="outlined"
                onChange={(e)=> setTerm(e.target.value)}
              />
            )}
            sx={{
              width: "60%",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "inherit"
                },
                "&.Mui-focused fieldset": {
                  borderColor: "inherit",
                },
              },
            }}
          />
          <div className="mt-4 w-[60%] flex flex-wrap gap-3">
            {
              selectedSkill?.map((skill, i) => (
                <p key={i} style={{ border: "1px solid grey" }} className="flex gap-4 items-center cursor-pointer w-fit py-[6px] px-[14px] text-[14px] font-semibold rounded-[31px]">{skill.skillName} - {formatter(skill?.cost)} <img onClick={() => onDeleteSkill(skill?.skills)} className="w-4 h-4 cursor-pointer" src="https://img.icons8.com/?size=100&id=3062&format=png&color=000000" alt="" /></p>
              ))
            }
          </div>

          <div className="flex fixed bottom-0 left-0 right-0 gap-4 h-[80px] bg-white justify-end border-t border-gray-200">
            <div className="container flex items-center justify-end w-full gap-4">
              <Button
                variant="light-blue"
                className="px-10 w-fit"
                onClick={onBack}
              >
                Back
              </Button>
              <Button
                variant="default"
                size="sm"
                className="w-fit px-14"
                type="submit"
                disabled={selectedSkill.length < 1}
              >
                Next
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>

      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
          <div className="px-5 pt-4 pb-3 text-[20px] font-semibold" style={{ borderBottom: "0.3px solid #24242480" }} id="child-modal-title">{activeSkill?.name}</div>
          <form onSubmit={handleSelectSkill} className="px-5 pt-6 pb-3">
            <p className="text-center">Pros with upfront pricing get hired more on Audtiklance.</p>
            <p className="my-2 text-center">Add a base price to help you get contacted and hired more, The price will include: Labor (excludes cost of parts).</p>
            <br />
            <label htmlFor="price">Enter your base price</label>
            <br />
            <CurrencyInput
              name='price'
              id='price'
              placeholder='$0.00'
              prefix="$"
              required
              className="w-full mt-1 rounded-sm"
              onValueChange={(value) => setPrice(value!)}
            />
            <div className="flex gap-2 mt-4">
              <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] h-[40px] rounded-sm text-gray-700" onClick={handleClose}>Cancel</button>
              <button className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Submit</button>
            </div>
          </form>
        </Box>
      </Modal>

      {/* {data && data?.results.length >= 1 ? (
        <section className="flex flex-col gap-4 md:flex-row">
          <div className="flex flex-col flex-1 gap-4">
            {data?.results?.map((item: any) => (
              <div
                key={item?.id}
                className="flex gap-2 justify-between items-center bg-[#ededed] px-4 py-2 rounded-lg"
              >
                <span className="">{item?.name}</span>

                <div className="flex items-center justify-end gap-2 basis-1/2">
                  <div className="flex items-center gap-1">
                    <span>$</span>
                    <input
                      placeholder="amount "
                      // onChange={(e) => setTerm(e.target.value)}
                      onChange={(e) => seAmount(e.target.value)}
                      className=" bg-gray-50 ring-1 h-[50px] border outline-none border-none   border-[#E2E8F0] placeholder:text-[#AAAAAA] placeholder:font-normal placeholder:text-xs ring-[#E2E8F0] transition-all duration-300 ease-in-out text-sm rounded-lg block w-full dark:bg-gray-700 px-2 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-w-[100px] "
                    />
                  </div>

                  <Button
                    className="min-w-[50px]"
                    onClick={() => {
                      handleSelectSkill(item.id, +amount, item?.name);

                      notify.success({
                        subtitle: "Successfully created",
                        message: "Item added",
                      });
                    }}
                  // disabled={!!amount}
                  >
                    Add
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </section>
      ) : null} */}
    </>
  );
};
