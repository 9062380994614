import { IJobResponseData } from "types/job";
import Api from "utils/auth/api";

export const getJobsFn = async () => {
  return await Api.get<IJobResponseData>("/job/").then((res) => res.data);
};
export const createJobFn = async (data: any) => {
  return await Api.post<any>("/job/", data).then((res) => res.data);
};
export const bidForJobFn = async (data: any) => {
  return await Api.post<any>(`/job/${data?.id}/bid/`, data).then(
    (res) => res.data,
  );
};
