import { Footer } from '@component'
import {PrivacyMain} from 'component/privacy/PrivacyMain'
import React from 'react'

export const Privacy: React.FC = () => {
  return (
    <div>
        <PrivacyMain />
        <Footer prop1={"string"} />
    </div>
  )
}
