import React, { useState } from "react";
import { ReactComponent as StarIcon } from "assets/svg/smallStarIcon.svg";

interface StarRatingProps {
  totalStars?: number;
  onRatingChange?: (rating: number) => void;
  initialRatings?: { [key: number]: number };
}

const StarRating: React.FC<StarRatingProps> = ({
  totalStars = 5,
  onRatingChange,
  initialRatings = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
}) => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [ratings, setRatings] = useState(initialRatings);

  const handleMouseEnter = (index: number) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index: number) => {
    setRating(index);
    const newRatings = { ...ratings, [index]: ratings[index] + 1 };
    setRatings(newRatings);
    if (onRatingChange) {
      onRatingChange(index);
    }
  };

  const totalRatings = Object.values(ratings).reduce(
    (acc, count) => acc + count,
    0,
  );

  const calculateProgressBarWidth = (count: number) => {
    return totalRatings ? `${(count / totalRatings) * 100}%` : "0%";
  };

  return (
    <div>
      <div className="flex flex-col justify-center gap-2">
        {Array.from({ length: totalStars }, (_, index) => {
          const starValue = totalStars - index;
          return (
            <div key={starValue} className="flex items-center gap-2">
              <span>{starValue}</span>
              <StarIcon
                fill={starValue <= (hoverRating || rating) ? "#FB923C" : ""}
                onClick={() => handleClick(starValue)}
                onMouseEnter={() => handleMouseEnter(starValue)}
                onMouseLeave={handleMouseLeave}
              />
              <div className="relative rounded-lg w-[100px] h-1.5 bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-[#FB923C] rounded-tl-lg rounded-bl-lg"
                  style={{
                    width: calculateProgressBarWidth(ratings[starValue]),
                  }}
                />
              </div>
              <span>{ratings[starValue]}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StarRating;
