import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

type CheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  labelText?: string;
  name: string;
};

export const Checkbox = ({ name, labelText, ...rest }: CheckboxProps) => {
  const { formState, register } = useFormContext();
  const { errors } = formState;
  const errorMessage = errors[name]?.message;

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <input
          type="checkbox"
          id={name}
          {...register(name)}
          {...rest}
          className={`form-checkbox h-4 w-  ring-1 text-gr rounded checked:text-blue-600 transition duration-150 ease-in-out checked:bg-blue-600 focus:ring-blue-500 dark:focus:ring-offset-gray-800 ${
            errors[name]
              ? "text-red-600 ring-red-500 focus-within:ring-red-500"
              : "border-gray-300 text-[#302F2C] focus-within:ring-blue-600 checked:bg-blue-600"
          }`}
        />
        {labelText && (
          <label
            htmlFor={name}
            className="ml-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {labelText}
          </label>
        )}
      </div>
      {errorMessage && typeof errorMessage === "string" && (
        <p className=" mt-1 text-xs text-red-500 transition-all duration-300 ease-in-out">
          {errorMessage}
        </p>
      )}
    </div>
  );
};
