export const themeColors = {
  navbarBtnBlue: "#4188F9",
  white: "#fff",
  grey: "#d8dde6",
  footerHeaderGrey: "#9E9E9F",
  footerIconGrey: "#27272A",
  footerBlue: "#17191A",
  heroSectionBlue: "#030817",
  heroSectionIndigo: "#312E81",
  gradientBlue: "#5f9bfc",
  gradientYellow: "#dba95d",
  heroSearchBlue: "#0f172a",
  heroLocationGrey: "#94a3b8",
  heroSearchBorderGrey: "#1e293b",
  heroSearchInputGrey: "#64748b",
  heroSearchBtnBorderBlue: "#2563EB",
  popularItemBorderGrey: "#334155",
};
