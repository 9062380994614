import { styled } from "styled-components";

export const Div = styled.div``;

export const Paragraph = styled.p``;

export const Span = styled.span``;

export const Image = styled.img``;

export const Input = styled.input``;

export const Label = styled.label``;
