import { useAuthenticatedUser } from 'hooks';
import { ArrowBigRight } from "lucide-react"
import { useGetServiceProfileById } from 'hooks/profile/useGetServiceProfileById';
import { Autocomplete, TextField, Box, Modal, CircularProgress } from '@mui/material';
import CurrencyInput from 'react-currency-input-field'
import locationIcon from "../../../assets/svg/location.svg"
import React, { useEffect, useState } from 'react'
import debounce from "lodash/debounce";
import { Button } from 'ui/button';
import { SUB_CATEGORY } from 'types';
import { useQuery } from '@tanstack/react-query';
import { getSkill } from 'services';
import { BoostModal, ListPayModal, ListServiceModal, NewServiceModal, SearchServiceModal } from './ServiceModals';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0.3px solid #24242480',
  boxShadow: "0px 4px 4px 0px #00000040",
  pb: 3,
  borderRadius: 2
};

type skillType = { name: string, id: string }

export const MyServices: React.FC = () => {
  const [listModal, setListModal] = useState(false)
  const [searchModal, setSearchModal] = useState(false)
  const [newModal, setNewModal] = useState(false)
  const [boostModal, setBoostModal] = useState(false)
  const [listPayModal, setListPayModal] = useState(false)
  const [price, setPrice] = useState("")
  const [activeSkill, setActiveSkill] = useState<SUB_CATEGORY>()
  const [term, setTerm] = useState("");
  const [newSkill, setNewSkill] = useState<skillType>()
  const { userDetails, isLoading } = useAuthenticatedUser();
  const { serviceProfileData, serviceProfileLoading } =
    useGetServiceProfileById(userDetails?.data?.service_profile as string);

  const handleListModalClose = () => {
    setListModal(false)
  }

  const handleSearchModalClose = () => {
    setSearchModal(false)
  }

  const handleNewModalClose = () => {
    setNewModal(false)
  }

  const handleListPayModalClose = () => {
    setListPayModal(false)
  }

  const handleBoostModalClose = () => {
    setBoostModal(false)
  }

  const handleServiceSelect = (_: any, value: skillType | null) => {
    setNewSkill(value!)
  }

  const handleAddNewSkill = () => {
    const obj = {
      cost: Number(price),
      time_frame: "FIXED",
      skills: newSkill?.id!,
      skillName: newSkill?.name!,
    }
  }

  const handleBoostSubmit = () => {

  }

  const handleListPaySubmit = () => {

  }

  const { data, refetch } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(term),
    enabled: !!term,
  });

  useEffect(() => {
    const delayedSearch = debounce(() => { refetch() }, 600)
    delayedSearch()
    return () => delayedSearch.cancel()
  }, [term, refetch]);


  if (serviceProfileLoading || isLoading) {
    return (
      <div className='w-full h-[90vh] flex justify-center items-center'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className='p-8'>
      <div className="flex items-center justify-between mb-8">
        <div className="mb-4">
          <h2 className="text-[25px] font-semibold">{serviceProfileData?.business_name}</h2>
          <p className="flex gap-2 mt-2" style={{ textTransform: "capitalize" }}><img src={locationIcon} alt="" /> Based In {serviceProfileData?.city} {serviceProfileData?.state}</p>
        </div>
        <div className="space-x-2">
          <Button onClick={() => setSearchModal(true)} variant="outline">Add New Service</Button>
        </div>
      </div>
      <h5 className='font-semibold'>Your Services</h5>
      <p className='my-2' style={{ color: "gray", fontSize: "14px" }}>Add Targeting Preferences To Get The Jobs You Want.</p>
      <a href="/" style={{ color: "blue", cursor: "pointer", textDecoration: "underline", fontSize: "14px" }}>Targeting Preferences Guide</a>
      <div className='w-full mt-8'>
        {
          serviceProfileData?.sub_category?.map((service, i) => (
            <div onClick={() => { setActiveSkill(service); setPrice(String(service?.cost)); setListModal(true) }} key={i} className='flex justify-between w-full py-3 cursor-pointer' style={{ borderTop: "0.4px solid grey", borderBottom: "0.4px solid grey" }}>{service?.sub_category} <ArrowBigRight className="w-6 h-6 text-muted-foreground" /></div>
          ))
        }
      </div>
        <ListServiceModal setBoostModal={setBoostModal} listModal={listModal} setPrice={setPrice} activeSkill={activeSkill} setListPayModal={setListPayModal} handleListModalClose={handleListModalClose} price={price} />
        <SearchServiceModal searchModal={searchModal} setNewModal={setNewModal} setTerm={setTerm} data={data} handleSearchModalClose={handleSearchModalClose} handleServiceSelect={handleServiceSelect} />
        <NewServiceModal handleAddNewSkill={handleAddNewSkill} handleNewModalClose={handleNewModalClose} setPrice={setPrice} newModal={newModal} newSkill={newSkill} />
        <ListPayModal handleListPayModalClose={handleListPayModalClose} handleListPaySubmit={handleListPaySubmit} listPayModal={listPayModal} />
        <BoostModal boostModal={boostModal} handleBoostModalClose={handleBoostModalClose} handleBoostSubmit={handleBoostSubmit} />
    </div>
  )
}