import { useMutation } from "@tanstack/react-query";
import { makeConversation } from "services";

export const useMakeConversation = () => {
  const { mutateAsync } = useMutation({
    mutationFn: makeConversation,
  });

  return { makeConvo: mutateAsync };
};
