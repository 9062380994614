import React from "react";

export const UsersLayout = ({
  children,
  sideChildren,
}: {
  children: React.ReactNode;
  sideChildren: React.ReactNode;
}) => {
  return (
    // <section className="md:border h-screen md:border-[#E2E8F0] overflow-y-hidden mt-6 flex container rounded-xl">
    //   <div className="border-r border-[#E2E8F0] sticky top-[80px] h-screen  w-[351px] hidden md:block">
    //     {/* <div className="border-b border-[#E2E8F0] py-4 flex items-center">
    //       <span className="px-4 font-bold text-sm">{title}</span>
    //     </div> */}
    //     <div className="  flex flex-col gap-1 hideScrollBar overflow-y-auto h-[calc(100vh_-_80px)]">
    //       {sideChildren}
    //     </div>
    //   </div>
    //   <div className="flex-1 overflow-y-auto hideScrollBar h-[calc(100vh_-_80px)]">
    //     <main className="w-full max-w-screen-xxlg px-4 ">{children}</main>
    //   </div>
    // </section>

    <section className="md:border md:border-[#E2E8F0]  mt-6  container rounded-xl grid md:grid-cols-[350px_1fr]">
      <div className="border-r border-[#E2E8F0]   md:block">
        <div className="  flex flex-col gap-1 hideScrollBar overflow-y-auto ">
          {sideChildren}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto hideScrollBar">
        <main className="w-full max-w-screen-xxlg px-4 ">{children}</main>
      </div>
    </section>
  );
};
