import React from "react";
import { Avatar } from "ui/avatar";
import { Button } from "ui/button";
import { Card } from "ui/card";
import GardenImg from "assets/images/gardenImg.png";
import HeartIcon from "assets/svg/heart.svg";
import { useGetFavoriteService } from "hooks/services";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { addFavouriteProviderFn, removeFavouriteProviderFn } from "services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "shared";
import { ErrorType } from "types";

export const Favourites = () => {
  const { isLoading, userFavoriteServiceData } = useGetFavoriteService();
  const queryClient = useQueryClient();


  const { mutate: likeMutate, isPending: isFetching } = useMutation({
    mutationFn: removeFavouriteProviderFn,
    onSuccess: async (data) => {
      if (data?.success === true) {
        queryClient.invalidateQueries({
          queryKey: ["favorite-service"],
        });
        notify.success({ message: data.message });
      }
    },
    onError: async (response: ErrorType) =>{
        notify.error({
          message: "Error ",
          subtitle: response?.data?.error,
        })
    },   
  });


  const removeFavouriteProvider = (id: string) => {
    likeMutate(id)
  }

  const averageCost = (costs: any[]) => {
    return costs.reduce((acc: any, item: { cost: any; }) => acc + item.cost, 0)/costs.length;
  }
  return (
    <div className="my-20 flex flex-col container">
      <div className="flex flex-col gap-3 ">
        <span className="text-sm">Profile</span>
        <span className="text-lg font-bold">Favorites & Saved</span>
      </div>
      <div className="mt-4 container ">
        <div className="w-full gap-8 mt-5 grid grid-cols-2">
          {userFavoriteServiceData?.results?.map((item) => (
            <div
              className="grid grid-cols-1 md:grid-cols-5 gap-4"
              key={item?.id}
            >
              <div className="col-span-2 relative">
                <Card className="max-w-[clamp(200px_8vw_200px)] w-full">
                  <img src={item?.profile_picture} alt="garden Img " />
                </Card>
                <div className="absolute top-2 right-7 lg:top-2 lg:right-2 p-3 rounded-[10px] w-[clamp(30px_8vw_80px)] bg-white">
                  {/* <img src={HeartIcon} alt="heart icon" /> */}
                  <button className="" onClick={()=> removeFavouriteProvider(item?.id)} type="button">
                  {item?.is_favorite ? <FaHeart className="text-red-600" /> :  <FaRegHeart />}

                  </button>
                  
                </div>
              </div>
              <div className="flex flex-col gap-4 col-span-3 ">
                <div className="flex items-center gap-2">
                  {/* <Avatar className="bg-[#D9D9D9] w-[clamp(50px,_8vw,_50px)]" /> */}
                  <div className="w-full">
                    <span className="font-bold">{item?.business_name}</span>
                    <div className="flex flex-col w-full justify-between">
                      <div>
                        <span>
                          {item?.city}, {item?.country} - 2.3 Km away
                        </span>
                      </div>
                      <div>
                        <span>5.0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <span>{item?.about_me}</span>
                <span>
                  From <span className="font-bold">${averageCost(item?.sub_category)}/hr</span>
                </span>
                <div>
                  <Button size="sm" className=" px-16">
                    Hire me
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


// import React from "react";
// import { Avatar } from "ui/avatar";
// import { Button } from "ui/button";
// import { Card } from "ui/card";
// import GardenImg from "assets/images/gardenImg.png";
// import HeartIcon from "assets/svg/heart.svg";
// export const Favourites = () => {
//   return (
//     <div className="mt-20 flex flex-col container">
//       <div className="flex flex-col gap-3 ">
//         <span className="text-sm">Profile</span>
//         <span className="text-lg font-bold">Favorites & Saved</span>
//       </div>
//       <div className="mt-4 container ">
//         <div className="w-full gap-8 mt-5 grid grid-cols-2">
//           {Array(12)
//             .fill(0)
//             .map(() => (
//               <div
//                 className="grid grid-cols-1 md:grid-cols-5 gap-4"
//                 key={Math.random()}
//               >
//                 <div className="col-span-2 relative">
//                   <Card className="max-w-[clamp(200px_8vw_200px)] w-full">
//                     <img src={GardenImg} alt="garden Img " />
//                   </Card>
//                   <div className="absolute top-2 right-7 lg:top-2 lg:right-2 p-3 rounded-[10px] w-[clamp(30px_8vw_80px)] bg-white">
//                     <img src={HeartIcon} alt="heart icon" />
//                   </div>
//                 </div>
//                 <div className="flex flex-col gap-4 col-span-3 ">
//                   <div className="flex items-center gap-2">
//                     <Avatar className="bg-[#D9D9D9] w-[clamp(50px,_8vw,_50px)]" />
//                     <div className="w-full">
//                       <span className="font-bold">Sarah Jones</span>
//                       <div className="flex w-full justify-between">
//                         <div>
//                           <span>Islamabad, Pakistan - 2.3 Km away</span>
//                         </div>
//                         <div>
//                           <span>5.0</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <span>
//                     Experienced gardener offering lawn care, weeding, planting,
//                     and garden maintenance
//                   </span>
//                   <span>
//                     From <span className="font-bold">$25/hr</span>
//                   </span>
//                   <div>
//                     <Button size="sm" className=" px-16">
//                       Hire me
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// };