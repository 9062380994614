import { useState } from "react";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { SkillsForm } from "./SkillsForm";
import { UploadMedia } from "./UploadMedia";
import { ProfileCreationProgressBar } from "./ProfileCreationProgressBar";

export const ProfileCreationContainer = () => {
  const [step, setStep] = useState(1); // Manage current step
  const totalSteps = 3; // Total number of steps

  const handleNextStep = () => {
    if (step < totalSteps) setStep(step + 1);
  };

  const handlePrevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  return (
    <div className="container my-[80px]">
      <div className="mb-8">
        <div className="mt-3 mb-4 text-[24px] font-bold">
          Service Provider Profile Creation
        </div>
        <ProfileCreationProgressBar stage={step} />
      </div>
      <div>
        {step === 1 && <PersonalInfoForm onSubmit={handleNextStep} />}
        {step === 2 && (
          <SkillsForm onSubmit={handleNextStep} onBack={handlePrevStep} />
        )}
        {step === 3 && (
          <UploadMedia onSubmit={handleNextStep} onBack={handlePrevStep} />
        )}
      </div>
    </div>
  );
};
