// import {ReactComponent as Logo} from 'assets/images/logo.png';
import { SelectIcon } from "assets/svg";
import { useContext, useEffect, useRef, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import { logOut } from "utils/auth/auth";
import { RxHamburgerMenu } from "react-icons/rx";
import { useQuery , useQueryClient } from "@tanstack/react-query";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import aLlogo, { ReactComponent as Logo } from "assets/svg/alLogo.svg";
import { Button } from "ui/button";
import { ReactComponent as HamburgerMenu } from "assets/svg/menu.svg";
import { Avatar } from "ui/avatar";
import { X } from "lucide-react";
import { DesktopFilter } from "./DesktopFilter";
import MobileFilter from "./MobileFilter";
import { ForgotPassword, LoginForm, SignUp } from "pages";
import { Drawer } from '@mui/material'
import { Input } from "ui/input";
import downIcon from "../../../../../../assets/svg/down-arrow-black.svg"
import downIconWhite from "../../../../../../assets/svg/down-arrow-white.svg"
import { serviveByCategory } from "component/oldcomponent/OtherServices/data";
import { FaRegBell } from "react-icons/fa";
import { MdLightbulbOutline } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";

// import { useLocation, useNavigate } from "react-router";
// import { Link, useSearchParams } from "react-router-dom";

export const Navbar = ({
  handleOpenSignin,
  lightVariant,
}: {
  handleOpenSignin: () => void;
  lightVariant?: string;
}) => {
  const ref = useRef(null);
  const currentPath = useLocation();
  const [scrolling, setScrolling] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const navigate = useNavigate();
  const { userDetails } = useAuthenticatedUser();

  // console.log(currentPath, "Currentpath");
  // console.log(userDetails, "userdetaialal");

  const queryClient = useQueryClient();
  const [modalState, setModalState] = useState<boolean>(false);
  const [notificationDropDown, setNotificationDropdown] = useState<boolean>(false)
  const [openForm, setOpenFom] = useState("");

  const { openModal, setOpenModal }: any = useContext(LayoutContext);

  const [searchParams] = useSearchParams();
  const [term, setTerm] = useState("");
  const [showDrop, setShowDrop] = useState(false)
  const [show, setShow] = useState(false)

  const handleSearch = () => {
    const params = new URLSearchParams(searchParams);

    if (term) {
      params.set("searchTerm", term);
    } else {
      params.delete("searchTerm");
    }

    navigate(`${"/search"}?${params.toString()}`, {
      replace: true,
    });

    setTerm("");
  };

  const handleOpenNotificationModal = () => {
    setNotificationDropdown(!notificationDropDown)
  }

  const handleChange = (term: string) => {
    setTerm(term);
  };

  const nameParts = userDetails?.data?.fullname?.split(" ");
  const fullname = userDetails?.data?.firstname + ' ' + userDetails?.data?.lastname
  const firstName = nameParts?.[0];

  const handleModalClick = (modalName: string): void => {
    openModal();
    setOpenModal(modalName);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 3);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogOut = () => {
    queryClient.clear();
    logOut(() => navigate(routes.HOME.path, { replace: true }));
    setModalState(false);
  };

  const isLinkActive = (href: string) => currentPath?.pathname === href;
  const textColor = lightVariant
    ? scrolling
      ? "text-bundo-black-a"
      : "text-bundo-white"
    : "";
  const logoColor =
    lightVariant || scrolling
      ? ""
      : lightVariant
        ? "[&>g>path]:fill-bundo-white"
        : "";

  const navItems: any = [
    {
      title: "My Profile",
      icon: "",
      path: "/profile",
    },
    {
      title: "Favourite",
      icon: "",
      path: "/favourite",
    },
    {
      title: "Messages",
      icon: "",
      path: "/chat",
    },
    {
      title: "Help",
      icon: "",
      path: "",
    },

    {
      title: "Settings",
      icon: "",
      path: "/profile",
    },
    // { title: "Get Help", icon: <SupportIcon />, path: "" },

    // {
    //   title: "App Information",
    //   icon: <ChatInfoIcon />,
    //   path: "",
    // },

    // { title: "Delete Account ", icon: <DeleteAccountIcon />, path: "" },
    {
      title: "LOG OUT",
      icon: "",
      path: routes.HOME.path,
      onClick: async () => {
        await logOut(() => navigate(routes.HOME.path, { replace: true }));
      },
    },
  ];

  return (
    <>
      {showDrop && <div onMouseLeave={() => setShowDrop(false)} className="w-full lg:p-16 p-4 py-16 overflow-scroll flex justify-center flex-wrap gap-4 mt-[60px] h-[100%] lg:h-[80%] fixed top-0 bg-white z-20">
        <X onClick={() => setShowDrop(false)} className="absolute lg:hidden top-9 right-5" />
        {
          Object.keys(serviveByCategory)?.map((service, i) => (
            <div className="lg:w-[24%] w-[45%]" key={i}>
              <h5 onClick={() => { setShowDrop(false); navigate(`/search?searchTerm=${service}`) }} className="mb-2 text-sm font-bold cursor-pointer lg:text-xl">{service}</h5>
              {
                serviveByCategory[service]?.map((childService, j) => (
                  <p onClick={() => { setShowDrop(false); navigate(`/search?searchTerm=${childService}`,) }} className="mb-1 text-xs cursor-pointer lg:text-sm" key={j}>{childService}</p>
                ))
              }
            </div>
          ))
        }
      </div>}
      <Drawer anchor='right' sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          backgroundColor: "white",
        },
      }} open={show} onClose={() => setShow(false)}>
        <div className='flex items-center justify-between p-4'>
          <img onClick={() => { setShow(false); navigate("/") }} src={aLlogo} alt="" />
          <X onClick={() => setShow(false)} />
        </div>
        <div className='p-4'>
          <Button
            size="sm"
            className="w-full px-8 mb-3 text-sm font-bold"
            onClick={() => { setShow(false); setShowDrop(true) }}
          >
            Explore
            <img src={downIconWhite} alt="" />
          </Button>
          {!userDetails?.data?.service_profile ? (
            <Button
              size="sm"
              className="w-full px-8 text-sm font-bold"
              onClick={() => { setShow(false); navigate("/provider") }}
            >
              Become a Provider
            </Button>
          ) : null}
          {!userDetails?.data?.firstname ? (
            <div className="flex flex-col gap-4 mt-3">
              <Button
                size="sm"
                className="px-8 text-sm font-bold"
                onClick={() => { setShow(false); setOpenFom("login") }}
              >
                Login
              </Button>
              <Button
                size="sm"
                className="px-8 text-sm font-bold"
                onClick={() => { setShow(false); setOpenFom("signup") }}
              >
                Sign up
              </Button>
            </div>
          ) : (
            <div className="flex flex-col gap-4 mt-3">
              <Button
                size="sm"
                className="px-8 text-sm font-bold"
                onClick={() => { setShow(false); navigate("/profile")}}
              >
                Profile
              </Button>
              <Button
                size="sm"
                className="px-8 text-sm font-bold"
                onClick={() => { setShow(false); navigate("/chat")}}
              >
                Messages
              </Button>
              <Button
                size="sm"
                className="px-8 text-sm font-bold"
                onClick={async ()=> {
                  await logOut(() =>
                    navigate(routes.HOME.path, { replace: true })
                  );
                  setShow(false)
                }}
              >
                Logout
              </Button>
            </div>
          )}
        </div>
      </Drawer>
      <header
        className={`fixed w-full bg-white ${scrolling ? "shadow-md" : ""
          } z-50 duration-300 ease-in-out px-6 xlg:px-0  w-full flex justify-center`}
      >
        {/* Desktop Navbar */}
        <div className="hidden w-full lg:block">
          <nav className="container hidden  py-3.5 lg:block">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-12">
                <Link to="/">
                  <Logo />
                </Link>
                {/* <div className="flex items-center gap-6">
                  <Link
                    to="/search"
                    className="flex items-center gap-2 text-sm"
                  >
                    <img src="/digital.png" alt="" />
                    Digital Services
                  </Link>
                  <Link
                    to="/search"
                    className="flex items-center gap-2 text-sm"
                  >
                    <img src="/home-service.png" alt="" />
                    Home Services
                  </Link>
                </div> */}
              </div>
              <div
                className="relative flex items-center gap-10 font-light"
                ref={ref}
              >
                <p
                  onClick={() => setShowDrop(!showDrop)}
                  className={`text-sm hover:text-primary-500 cursor-pointer flex gap-x-1`}
                >
                  Explore
                  <img src={downIcon} alt="" />
                </p>
                {/* {["/"].map((path) => (
                ))} */}
                {/* <div className="flex items-center gap-4 ">
              cart
              <Link to={""}>
            
              </Link>
            </div> */}

                {!userDetails?.data?.service_profile ? (
                  <Link
                    to={"/provider"}
                    className={`text-sm hover:text-primary-500
                 ${isLinkActive("/provider")
                        ? "font-bold text-primary-500"
                        : textColor
                      }`}
                  >
                    Become a Provider
                  </Link>
                ) : null}
               
               <Link to={"/pricing"}  className={`text-sm hover:text-primary-500
                 ${isLinkActive("/provider")
                        ? "font-bold text-primary-500"
                        : textColor
                      }`}>Pricing</Link>

                  
                {userDetails?.data?.firstname && <Link
                    to={"/chat"}
                    className={`text-sm hover:text-primary-500
                    ${isLinkActive("/chat") || isLinkActive("/chat/:id") 
                            ? "font-bold text-primary-500"
                            : textColor
                          }`}
                  >
                    Messages
                  </Link>}
                  {userDetails?.data?.firstname || userDetails?.data?.service_profile ? <span
                   
                    className={`text-sm cursor-pointer relative hover:text-primary-500
                    `}
                  >
                    
                    <FaRegBell  onClick={()=>handleOpenNotificationModal()} size={"16"}/>
                   {notificationDropDown && <div className="top-12 right-0	 z-10 absolute   w-[500px] bg-[#ffffff] shadow-lg">
    
    <div className="flex flex-col ">
    <ul className="flex flex-col divide-y divide">
        <li className="flex flex-row">
            <div className="flex items-start flex-1 p-4 cursor-pointer select-none">
                <div className="flex flex-col items-center justify-center mr-4">
                    <span className="relative block">
                    <MdLightbulbOutline className="text-yellow-300 size-6" />
                        {/* <img alt="profil" src="/images/person/6.jpg" className="mx-auto object-cover rounded-full h-10 w-10 "/> */}
                    </span>
                </div>
                <div className="flex-1 pl-1 mr-16">
                    <div className="font-medium dark:text-white">
                       Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa perferendis 
                    </div>
                    <div className="text-sm font-medium text-gray-600 dark:text-gray-200">
                    6:00 AM
                    </div>
                </div>
                
            </div>
        </li>
        <li className="flex flex-row">
            <div className="flex items-start flex-1 p-4 cursor-pointer select-none">
                <div className="flex flex-col items-center justify-center mr-4">
                    <span className="relative block">
                    <AiOutlineInfoCircle className="text-teal-500 size-6"/>
                    </span>
                </div>
                <div className="flex-1 pl-1 mr-16">
                    <div className="font-medium dark:text-white">
                       Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa perferendis 
                    </div>
                    <div className="text-sm font-medium text-gray-600 dark:text-gray-200">
                    6:00 AM
                    </div>
                </div>
                
            </div>
        </li>
        </ul>
    </div>
</div> }
                  </span>  
                  : null
                  }
                 
                 {userDetails?.data && fullname ||
                  userDetails?.data?.service_profile ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        border: "1px solid #94A3B8",
                        padding: "3px",
                        borderRadius: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => setModalState(!modalState)}
                    >
                      <Avatar
                        src={userDetails?.data?.profile_picture || ""}
                        fallbackText={fullname}
                        className="font-medium text-black"
                        size={40}
                      />
                      <span style={{ fontWeight: 600 }}>{firstName || ""}</span>{" "}
                      <img
                        src={SelectIcon}
                        alt="select icon"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                      />
                    </div>
                    <div
                      // onClick={() => {
                      //   removeToken();
                      //   queryClient.clear();
                      // }}
                      id="dropdown"
                      className={`z-10 ${modalState ? "block" : "hidden"
                        }  bg-white divide-y divide-gray-100 right-0 absolute rounded-lg shadow-lg w-44 dark:bg-gray-700`}
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        {navItems?.map((v: any) => (
                          <Link to={v.path} key={v.title}>
                            <li
                              onClick={() => {
                                setModalState(false);
                                v.onClick && v.onClick();
                              }}
                            >
                              <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                {v.title}
                              </span>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-4">
                    <Button
                      size="sm"
                      className="px-8 text-sm font-bold"
                      onClick={() => setOpenFom("login")}
                    >
                      Login
                    </Button>
                    <Button
                      size="sm"
                      className="px-8 text-sm font-bold"
                      onClick={() => setOpenFom("signup")}
                    >
                      Signup
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </nav>
          <div className="w-full relative inset-0 border shadow-sm px-[-60px] hidden lg:block" />

          {/* <nav className="container hidden py-2 lg:block">
            <div className="flex items-center w-full ">
              <div
                className="flex items-center justify-between w-full font-light "
                ref={ref}
              >
                {["/"].map((path) => (
                  <div
                    key={path}
                    onClick={() => navigate("/search")}
                    // size="sm"
                    className={`text-sm w-fit cursor-pointer  hover:text-primary-500 py-2 px-2 rounede-lg

                 ${isLinkActive(path)
                        ? "font-bold text-primary-500 bg-blue-600 text-white rounded-md"
                        : textColor || "mx-0 px-0 text-[#262626]"
                      }`}
                  // variant={isLinkActive(path) ? "default" : "plain"}
                  >
                    {path === "/" ? (
                      <div className="flex items-center gap-2">
                        <img src="/hambuger.png" />
                        <span>Browse Categories</span>
                      </div>
                    ) : (
                      path
                        .substring(1)
                        .replace("content-writing", "Content Writing")
                        .replace("graphics-design", "Graphics & Design")
                        .replace("programming", "Programming")
                        .replace("digital-marketing", "Digital Marketing")
                        .replace("digital-marketing", "Digital Marketing")
                        .replace("home-improvement", "Home Improvement")
                        .replace("event-planning", "Event Planning")
                        .replace(
                          "cleaning-housekeeping",
                          "Cleaning & Housekeeping"
                        )
                        .replace("personal-care", "Personal Care")
                    )}
                  </div>
                ))}
              </div>
              {currentPath?.pathname === "/search" ? (
                <div className="group mt-2 flex h-[53px]  items-center gap-2 rounded-[5px] border border-[#1E293B]   pl-[10px] lg:w-full xlg:w-full">
                  <Input
                    // onChange={handleSearch}
                    // onBlur={() => navigate("/search")}
                    placeholder="What service are you looking for today?."
                    className="h-full text-xs font-normal bg-transparent border-none outline-none"
                    onChange={(e) => handleChange(e.target.value)}
                  />
                  <span
                    onClick={handleSearch}
                    className="flex items-center p-4 bg-blue-600 rounded-lg justify-self-end"
                  >
                    <img src="/search-normal.png" alt="" />
                  </span>
                </div>
              ) : null}
            </div>
          </nav> */}
          <div className="w-full relative inset-0 border shadow-sm px-[-60px] hidden lg:block" />
        </div>

        {/* Mobile Navbar */}
        <nav
          className={`container  relative isolate   py-5 lg:hidden ${showMobileNav
            ? "bg-tertiary-pale-100"
            : scrolling
              ? "bg-tertiary-white-100 "
              : "bg-transparent"
            }`}
        >
          <div className="flex items-center justify-between w-full z-1">
            <Link to="/">
              <img src={aLlogo} alt="contain" />
            </Link>
            <div className="flex gap-4 flex-row-revers">
              <div className="p-2 bg-blue-500 bg-opacity-20 rounded-full">

              <RxHamburgerMenu onClick={() => setShow(true)} size={16}/>
                {/* <img onClick={() => setShow(true)} src="/hambuger.png" /> */}
              </div>
              {/* {!userDetails?.data?.fullname && (
                <div className="flex gap-4">
                  <Button
                    size="sm"
                    className="px-8 text-sm font-bold"
                    onClick={() => setOpenFom("login")}
                  >
                    Login
                  </Button>
                  <Button
                    size="sm"
                    className="px-8 text-sm font-bold"
                    onClick={() => setOpenFom("signup")}
                  >
                    Sign up
                  </Button>
                </div>
              )} */}

              {/* {userDetails?.data?.fullname && (
                <button onClick={() => setShowMobileNav(!showMobileNav)}>
                  {showMobileNav ? (
                    <div>
                      <X />
                    </div>
                  ) : (
                    <div>
                      <HamburgerMenu />
                    </div>
                  )}
                </button>
              )} */}
            </div>
          </div>
          <div
            className={`fixed left-0 grid w-full overflow-hidden bg-gray-300 px-6 text-center transition-all duration-300 ease-in-out ${showMobileNav
              ? "top-[72px] h-screen grid-rows-[1fr] py-10 opacity-100"
              : "top-[-60px] h-0 grid-rows-[0fr] opacity-0"
              }`}
          >
            <div className="overflow-scroll hideScrollBar">
              {userDetails?.data &&fullname ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      border: "1px solid #94A3B8",
                      padding: "3px",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalState(!modalState)}
                  >
                    <Avatar
                      src={userDetails?.data?.profile_picture || ""}
                      fallbackText={fullname}
                      className="font-medium text-black"
                      size={40}
                    />
                    <span style={{ fontWeight: 600 }}>{firstName || ""}</span>{" "}
                    {/* <img
                      src={SelectIcon}
                      alt="select icon"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                    /> */}
                  </div>
                  <div
                    // onClick={() => {
                    //   removeToken();
                    //   queryClient.clear();
                    // }}
                    id="dropdown"
                    className={`z-10 ${modalState ? "block" : "hidden"
                      }  bg-white divide-y divide-gray-100 right-0 absolute rounded-lg shadow-lg w-44 dark:bg-gray-700`}
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      {navItems?.map((v: any) => (
                        <Link to={v.path} key={v.title}>
                          <li
                            onClick={() => {
                              setModalState(false);
                              v.onClick && v.onClick();
                            }}
                          >
                            <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                              {v.title}
                            </span>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-4">
                  <Button
                    size="sm"
                    className="px-8 text-sm font-bold"
                    onClick={() => setOpenFom("login")}
                  >
                    Log in to your account
                  </Button>
                </div>
              )}

              {/* {userDetails?.data?.fullname && <MobileFilter />} */}
              <div className="flex flex-col gap-y-10">
                {userDetails?.data && fullname ? (
                  <div className="flex flex-col items-center w-full gap-4">
                    {/* <Link to="" className="flex items-center gap-1">
                      <div className="isolate ml-1 flex aspect-square w-[clamp(50px,_8vw,_50px)] items-center justify-center rounded-full bg-[#34A853]">
                        <span className="font-bold text-[#fff]">
                          {userDetails?.data?.fullname[0]}
                        </span>
                      </div>
                      <span className="text-[12px] font-bold">
                        {userDetails?.data?.fullname}
                      </span>
                    </Link> */}

                    <Link
                      to="/search"
                      className="mt-4"
                      onClick={() => setShowMobileNav(false)}
                    >
                      Browse Categories
                    </Link>
                    <Button
                      className="w-full"
                      onClick={async () => {
                        // logOut();

                        await logOut(() =>
                          navigate(routes.HOME.path, { replace: true })
                        );

                        setShowMobileNav(false);
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </nav>
      </header>

      <LoginForm openLogin={openForm} setOpenLogin={setOpenFom} />

      <SignUp
        openSignupForm={openForm}
        setOpenSignUp={setOpenFom}
      // handleOpenLoginForm={handleOpenLogin}
      // setOpenConfirmPassword={setOpenConfirmPassword}
      // handleOpenLogin={setOpenLogin}
      />

      <ForgotPassword
        openForgetPassword={openForm}
        setOpenForgetPassword={setOpenFom}
      // handleOpenLogin={handleOpenLogin}
      />
    </>
  );
};
