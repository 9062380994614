import { SettingsLayout } from "context/settings/layout/SettingsLayout";
import { Profile } from "context/settings/profile/Profile";
import React from "react";
import { Link } from "react-router-dom";

export const PaymentMethod = () => {
  return (
    <SettingsLayout>
        <div className="h-screen flex flex-col pt-10 w-1/2 mx-auto gap-y-10">
          <h1 className="text-2xl font-bold">Payment Methods</h1>
          <div className="flex justify-between ">
            <p className="font-bold text-base">Saved Cards</p>
            <Link className="text-[#4d8bf0]" to="/add_card">Add a new card</Link>
          </div>
          <div className="">
            <p className="text-red-500">No cards on file</p>
          </div>
        </div>
    </SettingsLayout>
  );
};
