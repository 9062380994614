import { Footer } from '@component'
import { TermsMain } from 'component/terms/TermsMain'
import React from 'react'

export const Terms: React.FC = () => {
  return (
    <div>
        <TermsMain />
        <Footer prop1={"string"} />
    </div>
  )
}