import { useMutation } from "@tanstack/react-query";
import { getConversation } from "services";

export const useGetConversation = () => {
  const { mutateAsync } = useMutation({
    mutationFn: getConversation,
  });

  return { initiateConvo: mutateAsync };
};
