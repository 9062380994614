import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getCategories } from "services";

export const useGetCategory = () => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("searchTerm") || "";
  const category = searchParams.get("category") || "";

  const { isLoading, data } = useQuery({
    queryKey: [
      "favorite-service",
      {
        search: searchTerm,
        category,
      },
    ],
    queryFn: getCategories,
  });

  return { isLoading, categoryData: data };
};
