import "./App.css";
import { BrowserRouter } from "react-router-dom";

import { NotificationContainer, QueryClientProviderWrapper } from "shared";
import { RoutesWrapper } from "pages";
import { ToastContainer } from "react-toastify";
// import { store } from "redux";

export const App = () => {
 

  return (
    <QueryClientProviderWrapper>
      <BrowserRouter>
        <RoutesWrapper />

        {/* <ToastContainer /> */}
        <NotificationContainer />
      </BrowserRouter>
    </QueryClientProviderWrapper>

    // </Provider>
  );
};

// export default App;
