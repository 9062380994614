import React from 'react'

export const TermsMain: React.FC = () => {
  return (
    <div className="px-6 py-8 mt-8 bg-gray-50">
      <div className="max-w-4xl p-8 mx-auto bg-white rounded-lg shadow-md">
        <h1 className="mb-4 text-2xl font-bold text-gray-800">Terms and Conditions</h1>
        <p className="mb-6 text-sm text-gray-500">Last Updated: November 26 2024</p>

        <section className="mb-6">
          <p className="mb-4">
            Welcome to <span className="font-semibold text-gray-800">Audtiklance</span>! These Terms and
            Conditions ("Terms") govern your use of the Audtiklance platform, accessible at{" "}
            <a href="/" className="text-blue-600 underline">
              Audtiklance.com
            </a>{" "}
            (the "Platform"). By accessing or using our services, you agree to be bound by these Terms. If
            you do not agree to these Terms, please do not use the Platform.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">1. Definitions</h2>
          <ul className="space-y-2 list-disc list-inside">
            <li>
              <strong>Platform:</strong> Refers to Audtiklance, including its website, mobile application, and
              related services.
            </li>
            <li>
              <strong>User:</strong> Refers to anyone who accesses or uses the Platform, including Freelancers
              and Clients.
            </li>
            <li>
              <strong>Freelancer:</strong> A User who offers services through the Platform.
            </li>
            <li>
              <strong>Client:</strong> A User who purchases services through the Platform.
            </li>
            <li>
              <strong>Service:</strong> Any task, project, or work provided by a Freelancer to a Client via the
              Platform.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">2. Eligibility</h2>
          <ul className="space-y-2 list-disc list-inside">
            <li>You must be at least 18 years old or legally able to form binding contracts in your jurisdiction.</li>
            <li>Provide accurate and truthful information during registration.</li>
            <li>Comply with all applicable laws and these Terms.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">3. Account Registration</h2>
          <ul className="space-y-2 list-disc list-inside">
            <li>
              Users must create an account to access certain features of the Platform.
            </li>
            <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
            <li>Notify us immediately of any unauthorized use of your account.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">4. User Responsibilities</h2>
          <ul className="space-y-2 list-disc list-inside">
            <li>
              <strong>Freelancers:</strong> Must provide accurate descriptions of services and deliver projects
              professionally.
            </li>
            <li>
              <strong>Clients:</strong> Must provide clear instructions and make payments as agreed.
            </li>
            <li>All Users must avoid harmful activities, including fraud or harassment.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">5. Payments</h2>
          <ul className="space-y-2 list-disc list-inside">
            <li>Payments are securely processed through third-party payment gateways.</li>
            <li>Audtiklance may withhold funds temporarily to resolve disputes or ensure compliance.</li>
            <li>Freelancers will receive payments after applicable fees are deducted.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">6. Service Fees</h2>
          <p>
            Audtiklance charges a service fee for transactions between Freelancers and Clients. Fees are
            disclosed upfront and are non-refundable.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">7. Dispute Resolution</h2>
          <ul className="space-y-2 list-disc list-inside">
            <li>Users should attempt to resolve disputes amicably using the Platform’s tools.</li>
            <li>
              If unresolved, disputes may be escalated to Audtiklance’s support team for mediation.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">8. Prohibited Activities</h2>
          <ul className="space-y-2 list-disc list-inside">
            <li>Using the Platform for illegal activities.</li>
            <li>Circumventing or bypassing Audtiklance's payment system.</li>
            <li>Posting offensive, defamatory, or infringing content.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">9. Limitation of Liability</h2>
          <p>
            Audtiklance is not liable for the actions or omissions of any User, damages resulting from Platform
            use, or disputes between Users.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="mb-3 text-xl font-semibold text-gray-800">10. Changes to Terms</h2>
          <p>
            Audtiklance may update these Terms at any time. Updated Terms will be posted, and your continued
            use signifies acceptance.
          </p>
        </section>

        <section>
          <h2 className="mb-3 text-xl font-semibold text-gray-800">11. Contact Us</h2>
          <p>
            For questions, contact us at{" "}
            <a href="mailto:support@audtiklance.com" className="text-blue-600 underline">
              support@audtiklance.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  )
}