import { QueryClient, useMutation } from "@tanstack/react-query";
import { bidForJobFn } from "services/job.service";
import { notify } from "shared";

const useJobBid = () => {
  const queryClient = new QueryClient();
  const { mutateAsync: bidJobMutation, isPending: isBiddingJob } = useMutation({
    mutationFn: bidForJobFn,
    onSuccess: async (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["job"],
        });
        // notify.success({ message: "user updated successfully" });
      }
      notify.success({ message: "job created successfully" });
    },
    onError: async (err) =>
      notify.error({
        message: "Error while updating user information",
        subtitle: err?.message,
      }),
  });

  return {
    bidJobMutation,
    isBiddingJob,
  };
};

export default useJobBid;
