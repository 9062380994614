import { useState } from "react";
import { CustomPopover } from "./CustomPopover";

export const DesktopFilter = () => {
  const [openInteraction, setOpenInteaction] = useState(false);

  const handleOpen = () => setOpenInteaction(true);
  //   const { handleClose, handleOpen, openInteraction } = useOpenAndClose();
  return (
    <CustomPopover
      isOpen={openInteraction}
      onOpen={handleOpen}
      classname="w-[576px]"
    >
      filter
      {/* <FilterProductCard onClose={handleClose} /> */}
    </CustomPopover>
  );
};
