import React, { useState } from "react";
import { LoginForm } from "../login";
import { SignUp } from "../signup";
import { ForgotPassword } from "../forgot-password";
import { IAuthContainer } from "./type";
import { AddNewPassword } from "../addPassword";
import { ConfirmPassword } from "../confirmPassword";

export const AuthContainer = ({ openLogin, setOpenLogin }: IAuthContainer) => {
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const [confirmPassword, setOpenConfirmPassword] = useState(false);

  const handleOpenForgetPassword = () => {
    setOpenSignUp(false);
    setOpenLogin(false);
    setOpenForgetPassword(!openForgetPassword);
  };
  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenLogin(false);
  };

  const handleOpenLogin = () => {
    setOpenSignUp(false);
    setOpenLogin(true);
  };

  return (
    <div className="w-full h-full z-50 relative">
      {/* <LoginForm
        handleOpenForgetPassword={handleOpenForgetPassword}
        handleOpenSignUp={handleOpenSignUp}
        openLogin={openLogin}
        setOpenLogin={setOpenLogin}
      /> */}

      {/* <SignUp
        openSignupForm={openSignUp}
        setOpenSignUp={setOpenSignUp}
        handleOpenLoginForm={handleOpenLogin}
        setOpenConfirmPassword={setOpenConfirmPassword}
        handleOpenLogin={setOpenLogin}
      /> */}
      {/* <ForgotPassword
        openForgetPassword={openForgetPassword}
        setOpenForgetPassword={setOpenForgetPassword}
        handleOpenLogin={handleOpenLogin}
      /> */}
    </div>
  );
};
