import { Routes } from "react-router-dom";
import { Router } from "./Router";
import React, { useEffect } from "react";
import { pagesData } from "PagesData";
import { useSocket } from "hooks/profile/socket";

export const RoutesWrapper = () => {

  const {socket } = useSocket();

  useEffect(() => {
   if (socket) {
     socket.onopen = () => {
       console.log("WebSocket connection established new");
     };
   }
 }, [socket]);


  return <Routes>{pagesData.map((route) => Router(route))}</Routes>;
};
