import React, { useRef, useState } from "react";
import { ReactComponent as AddIcon } from "assets/svg/add.svg";
import { ReactComponent as MinusIcon } from "assets/svg/minus.svg";
import { faqs } from "./data";

export const FAQ = () => {
  const [openResponse, setOpenResponse] = useState(Array(4).fill(false));
  const contentRefs = useRef([]);

  const toggleResponse = (index: number) => {
    setOpenResponse((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className="container px-6 pb-8 xlg:px-0">
      <div className="flex flex-wrap justify-between w-full">
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-3">
            <div className="w-6 border-2 border-b border-black" />{" "}
            <span className="text-sm font-semibold uppercase">
              Frequently Asked Question
            </span>
          </div>
          <span className="md:text-[42px] flex text-[22px] capitalize font-bold leading-[30px] md:leading-[60px]">
            Got questions? <br className="flex lg:hidden" />
            We've <br className="hidden lg:block" /> got answers!
          </span>
        </div>
        <div className="flex flex-col mt-8 lg:mt-0  w-full max-w-[600px]">
          {faqs.map((faq, index) => (
            <div key={index}>
              <div className="flex items-center justify-between w-full">
                <span className="text-[#4188F9] max-w-[500px] font-bold">
                  {faq?.question}
                </span>
                <button
                  className="w-10 text-base text-bold"
                  onClick={() => toggleResponse(index)}
                >
                  {openResponse[index] ? (
                    <span className="w-10 text-base text-bold">
                      <MinusIcon />
                    </span>
                  ) : (
                    <span className="w-10 text-base text-bold">
                      <AddIcon className="w-8" />
                    </span>
                  )}
                </button>
              </div>

              <div
                //@ts-expect-error
                ref={(el) => (contentRefs.current[index] = el)}
                className={`transition-all duration-300 ease-in-out overflow-hidden max-w-[500px]`}
                style={{
                  height: openResponse[index]
                    ? //@ts-expect-error
                      `${contentRefs.current[index]?.scrollHeight}px`
                    : "0",
                }}
              >
                <span>{faq?.answer}</span>
              </div>

              {index + 1 !== faqs?.length && (
                <div className="w-full border border-[#D4D4D4] my-6" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
