export const stringToColor = (inputString: string) => {
  let hash = 0;
  const defaultString = "fmx";
  const string = inputString || defaultString; // Use default string if input is null or empty

  /* eslint-disable no-bitwise */
  // Compute the hash value from the input string
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Construct the color code
  const colorArray = [];
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colorArray.push(`00${value.toString(16)}`.slice(-2));
  }
  /* eslint-enable no-bitwise */

  return `#${colorArray.join("")}`;
};
