import { SettingsLayout } from "context/settings/layout/SettingsLayout";
import { Profile } from "context/settings/profile/Profile";
import React from "react";
import { CiLock } from "react-icons/ci";
import { Link } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

export const AddCard = () => {
  return (
    <SettingsLayout>
      
        <div className="min-h-screen flex flex-col space-y-6 items-center justify-center bg-gray-100">
        <div className="w-full max-w-2xl">
       
        <Link to="/payment_methods" className="inline-flex text-[#4d8bf0] gap-x-3 text-base items-center"><IoChevronBackOutline size={20} />Payment Methods</Link>
        </div>
        
        <div className="w-full max-w-2xl p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-4">Add a new card</h2>
          <p className="text-base text-gray-600 flex items-center mb-6">
            <span className="mr-2">
            <CiLock size={20} />

            </span>
            All payment info is stored securely.
          </p>

          <form>
          

            <div className="grid grid-cols-6 gap-4 mb-4">
            <div className="mb-4 col-span-3">
              <label
                htmlFor="card-number"
                className="block text-sm font-medium text-gray-700"
              >
                Card Number
              </label>
              <input
                type="text"
                id="card-number"
                placeholder="1234 1234 1234 1234"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
              <div>
                <label
                  htmlFor="expiration"
                  className="block text-sm font-medium text-gray-700"
                >
                  Expiration
                </label>
                <input
                  type="text"
                  id="expiration"
                  placeholder="MM / YY"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="cvc"
                  className="block text-sm font-medium text-gray-700"
                >
                  CVC
                </label>
                <input
                  type="text"
                  id="cvc"
                  placeholder="CVC"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
              <label
                htmlFor="zipcode"
                className="block text-sm font-medium text-gray-700"
              >
                Zipcode
              </label>
              <input
                type="text"
                id="zipcode"
                placeholder="90210"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            </div>

          
            <div className="flex justify-end">
            <button
              type="submit"
              className=" bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Save card
            </button>
            </div>
            
          </form>
        </div>
    </div>
    </SettingsLayout>
  );
};
