import dayjs from "dayjs";
export function checkIfImagesAreCorrectType(files: File[]): boolean {
  let valid = true;
  if (files[0]) {
    const file = files[0];
    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      valid = false;
    }
  }
  return valid;
}
export function checkIfFilesAreTooBig(files: File[], limit: number): boolean {
  let valid = true;
  if (files[0]) {
    const file = files[0];
    const sizeInMB = file.size / 1024 / 1024; // Convert size to megabytes
    if (sizeInMB > limit) {
      valid = false;
    }
  }
  return valid;
}

export const formatTime = (date: string) => {
  return dayjs(date).format("h:mm:ss A");
};

export const formatDate = (date: string) => {
  return dayjs(date).format('MMM DD, YYYY, h:mm A');
}