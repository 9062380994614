import { useGetJobs } from "hooks/jobs";
import React from "react";
import { Button } from "ui/button";
import { Card } from "ui/card";
import { useDialogStore } from "../../store/dialogStore";
import { useNavigate } from "react-router-dom";

export const SideNavRecentJobs = () => {
  const { isLoading, jobsData } = useGetJobs();
  const setSelected = useDialogStore((state) => state.setSelected);
  const selected = useDialogStore((state) => state.selected);

  const navigate = useNavigate();

  const handleSelected = (id: string) => {
    const selectedItem = jobsData?.results?.find((item) => item?.id === id);
    
    setSelected({
      ...selected,
      data: selectedItem ?? {},
      open: "",
    });
  };

  return (
    <div className="relative w-full pt-3">
      <div className="px-3 sticky bg-white w-full left-0 right-0 top-0 z-10 bottom-0 border-b border-[#E2E8F0] pb-3 pt-2 flex justify-between items-center">
        <span className="text-sm font-bold">Recent Jobs</span>
        <Button size="sm" onClick={() => navigate("/task-details")}>
          Create Job
        </Button>
      </div>
      <div className="px-3 mt-4 sticky left-0 right-0 overflow-y-auto">
        {jobsData?.results?.map((job) => (
          <Card key={job.id}>
            <div
              className="flex flex-col gap-4"
              onClick={handleSelected.bind(this, job?.id)}
            >
              <div className="flex w-full items-center justify-between">
                <span className="font-bold text-md">
                  I am looking for a {job?.title}
                </span>
                <div className="text-[#DC2626] bg-[#FEE2E2] p-4 rounded-[115px] w-[67px] h-[29px] flex items-center justify-center">
                  <span className="text-sm font-bold"> {job?.status}</span>
                </div>
              </div>
              <div>
                <span className="text-sm">{job.description}</span>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <span className="text-sm font-medium">This Week</span>
                </div>
                <div className="text-[#1E293B]"> •</div>
                <div>
                  <span className="text-sm font-medium">{job?.category}</span>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
