import { useQuery } from "@tanstack/react-query";
import { getFavoriteServiceFn, getPreviousConversation } from "services";

export const useGetPreviousConvo = (id: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ["messages"],
    queryFn: () => getPreviousConversation(id),
  });

  return { isLoading, prevConvo: data };
};
