import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { isAuthenticated } from "utils/auth/auth";
import { routes } from "routes";

export const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  if (isAuthenticated()) {
    return <>{children}</>;
  }
  return <Navigate to={routes.HOME.path} state={{ from: location }} replace />;
};
